.insight-details .card-body {
	.expert {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		.dih-avatar {
			margin-right: 5px;
		}

		.h5 {
			margin: 0;
		}
	}

	.dates {
		font-style: italic;
		margin-bottom: 10px;
	}

	> img {
		max-width: 100%;
		max-height: 500px;
		display: block;
		margin: 20px auto;
	}

	.channels-tags .tag {
		margin-right: 5px;
	}
}