.dih-image-gallery {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.thumbnail {
		height: 200px;
		// max-width: 100%;
		margin: 5px;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 4px;
		padding: 4px;
		position: relative;
		cursor: pointer;

		> img {
			height: 100%;
			// max-width: 100%;
		}
	}
}