.resource-card {
	height: 100%;
	margin: 0;

	.card-body {
		height: 100%;
		display: flex;
		flex-direction: column;

		.name {
			display: flex;
			align-items: center;

			svg {
				margin-right: 5px;
			}
		}

		.academic {
			display: flex;
			align-items: center;
	
			svg {
				margin-right: 5px;
			}
		}

		.label {
			align-self: flex-start;
			margin-bottom: 5px;
		}

		.body {
			flex-grow: 1;

			margin-bottom: 10px;
		}

		.stats {
			margin-bottom: 2px;

			.label {
				margin-right: 5px;
			}

			.attachments {
				background-color: #27a9e1;
			}

			.links {
				background-color: #1b75bb;
			}

			.videos {
				background-color: #bb1b75;
			}
		}
	}

} 