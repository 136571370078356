.article .card-body {
	> img {
		max-width: 100%;
		display: block;
		margin: 20px auto;
	}

	.h4 {
		margin-top: 20px
	}
}