
.academic-container {
	.contact-info {
		width: 288px;

		.card-body {
			padding: 0;

			img.logo {
				display: block;
				margin: auto;
				height: 32px;
				width: 32px;
			}
		}
	}
}

@media (max-width: 1009px) {
	.academic-container {
		flex-direction: column;
		align-items: center;
	}
}