.community-card {
	height: 100%;

	.card-body {
		height: 100%;
		display: flex;
		flex-direction: column;

		.description {
			flex-grow: 1;
		}

		.counters a, button {
			width: 100%;
		}
	}
}