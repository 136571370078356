.video-gallery {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.thumbnail {
		margin: 5px;
		background-color: #fff;
		border-radius: 4px;
		padding: 4px;
		position: relative;
		cursor: pointer;

		> img {
			height: 100%;
			// max-width: 100%;
		}
	}
}