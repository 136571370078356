.competencies {
	.competency-progress {
		background-color: #B7F779;
		height: 15px;

		&.warning {
			background-color: #FFE081;
		}

		&.error {
			background-color: #FFB2AD;
		}
	}
	
	.marker {
		position: relative;
		top: -15px;

		> div {
			position: absolute;
			color: white;
			width: 115px;
			height: 15px;
			line-height: 15px;
			font-size: 12px;
			font-weight: bold;
			text-align: right;
			transition: left .4s linear;
			padding-right: 5px;
		}
	}

	.data-table {
		a {
			cursor: pointer;
		}
	}
}

@media print {
	.competencies {
		.competencies-options {
			display: none !important;
		}
		.competency-filter {
			display: none !important;
		}

		.scrollbar-container {
			overflow: visible !important;
			max-height: none !important;

			.scrollbar-view {
				overflow: visible !important;
				max-height: none !important;
				margin-bottom: 5px !important;
				margin-right: 0px !important;
			}
		}
	}
}