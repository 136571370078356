
.problem-container {
	.user-details {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		.author-avatar {
			padding-right: 1rem;
		}

		.details-box {
			span {
				display: block;
				color: #555;
				font-size: 80%;
				font-weight: 400;
			}

			.author-name {
				color: #007C9A;
				font-size: 100%;
				font-weight: 300;

				svg {
					cursor: pointer;
					font-size: 20px;
					vertical-align: middle;
				}
			}
		}

		.votes {
			margin-left: auto;
			display: flex;
			align-items: center;

			.vote-count {
				margin-left: 10px;
				float: right;

				h3, h5 {
					margin: 0;
				}

				h5 {
					color: #555
				}
			}
		}
	}
}