.card.problem-stats .toggle-panel {
	padding: 20px 10px 0;
	display: flex;

	button:first-of-type {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		align-self: center;
	}

	button:last-of-type {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		align-self: center;
	}

	.checkbox-control {
		width: initial;
		margin-left: 20px;
	}
}