.centre-card {
	height: 100%;

	.pcard-img-top {
		max-height: 200px;
		padding: 30px 30px 0;
		height: auto
	}

	.pcard-body {
		text-align: center;
		display: flex;
		flex-direction: column;

		.body {
			flex-grow: 1;
			padding-bottom: 10px;
		}
	}
}

.centre-container {
	.contact-info .card-body {
		padding: 0;

		img {
			max-width: 200px;
			max-height: 200px;
		}

		section:not(:first-child) {
			margin-top: 10px;
		}
	}
}