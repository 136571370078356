

.wizard {
	.wizard-steps {
		display: table;
		width: 100%;
		table-layout: fixed;
		margin: 0;
		padding: 0;
		list-style: none;

		.divider {
			width: 100%;
			border-bottom: 2px solid #aaa;
			min-width: 10px;
		}

		.step {
			display: table-cell;
			width: auto;
			vertical-align: top;
			text-align: center;
			position: relative;

			&.disabled a, 
			&.disabled a:focus, 
			&.disabled a:hover {
				color: #999;
				cursor: default;
			}

			a {
				position: relative;
				padding-top: 52px;
				margin-top: 20px;
				margin-bottom: 20px;
				display: block;
				color: #999;
			}

			span {
				background-color: #fff;
				display: inline-block;
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -24px;
				z-index: 10;
				text-align: center;
				width: 50px;
				height: 50px;
				line-height: 45px;
				border: 3px solid #F3F3F3;
				font-size: 1.3rem;
				border-radius: 50%;
			}

			&:after, &:before {
				content: '';
				z-index: 9;
				display: block;
				position: absolute;
				top: 45px;
				width: 50%;
				height: 3px;
				background-color: #F3F3F3;
			}

			&:after {
				right: 0;
			}

			&:first-child:before {
				content: ''
			}
			&:first-child:before,
			&:last-child:after {
				content: none
			}

			&.complete {
				&:before, &:after {
					background-color: #009efb;
				}

				span {
					background-color: #009efb;
					border-color: #009efb;
					color: #fff;
				}
			}
			&.current {
				&:before, &:after {
					background-color: #009efb;
				}

				span {
					background-color: #fff;
					border-color: #009efb;
					color: #009efb;
				}
			}
			&.is-last-step-done {
				&:before {
					background-color: #009efb;
				}

				&:after {
					background-color: #F3F3F3;
				}
			}

			&:disabled {
				&:before, &:after {
					background-color: #F3F3F3;
				}
			}

			&.error {
				span {
					background-color: #fff;
					border-color: #f62d51;
					color: #f62d51;
				}
			}
		}
	}

	.wizard-buttons {
		margin-top: 10px;
		float: right;
	}
}