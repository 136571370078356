.drag-list > table {
	width: 100%;

	.auto-width {
		width: 40px;
		white-space: nowrap;
		padding: 5px;
	}

	.drag-handle {
		vertical-align: middle;
		// padding-right: 5px;
	}

	.drag-wrapper {
		// display: flex;
		// align-items: center;
		// padding: 5px 10px;

		> svg {
			margin-right: 5px;
		}
	}
}

@media (max-width: 600px) {
	.drag-list .auto-width {
		white-space: normal;
		text-align: center;
	}
}