.view-content {
	.tags {
		margin-bottom: 5px;
	}

	.content-link {
		border: 1px solid $linkTextColour;
		border-radius: 5px;
		display: inline-flex;
		align-items: center;
		padding: 5px;
		margin: 0;

		svg {
			margin-right: 5px;
		}
	}

	.content-attachment {
		height: 100%;
		text-align: center;
		cursor: pointer;
		
		svg {
			color: $linkTextColour;
			width: 50px;
			height: 50px;
		}
	}
}