
#footer {
    padding: 1em;
    background: #2C3E50;
    text-align: center;
    font-style: normal;
	font-weight: normal;
	width: 100%;
	flex-shrink: 0;

	h2 {
		left: -9999px;
		position: absolute;
	}

	.tagline {
		max-width: 100%;
		min-width: 140px;
	}
	
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		flex-wrap: wrap;
	}

	.max-width {
		font-size: 75%;
	}

	li {
		display: inline-block;
		padding: 0.2em 1em;      
	}

	a, a:link, a:visited, p {
		color: #ffffff;
	}
}
