.event-card {
	height: 100%;
	margin: 0;

	.card-body {
		height: 100%;
		display: flex;
		flex-direction: column;

		> .h1 {
			display: flex;
			align-items: center;

			svg {
				fill: #74bf44;
				height: 40px;
				width: 40px;
			}
		}

		svg {
			margin-right: 5px;
			vertical-align: middle;
		}

		.content {
			flex-grow: 1;
			margin-bottom: 10px;
		}

		> button {
			align-self: flex-start;
		}
	}
}