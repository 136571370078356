
.page-banner {
    height: 60px;
    background: #FCF8E3;
    position: fixed;
    width: 100%;
    top: 122px;
	z-index: 10;
	color: #998661;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	line-height: 16px;
	
	p {
		text-align: center;
		margin: 0;
		padding: 0;
	}
}

.main-layout.show-banner .page-layout {
	padding-top: 125px;
}

.login-layout.show-banner {
	.page-banner {
		top: 60px;
	}

	.login-container {
		padding-top: 60px;
	}
}

@media (max-width: 767px) {
	.page-banner {
		position: absolute;
		top: 60px;
	}

	.main-layout.show-banner .page-layout {
		padding-top: 60px;
	}
}
