.card-picker {
	min-width: 250px;

	svg {
		color: rgb(204, 204, 204);
		margin: 0 8px;
	}

	&:not(.disabled) {
		input {
			color: initial;
		}

		svg {
			cursor: pointer;

			&:hover {
				color: rgb(153, 153, 153);
			}
		}
	}
}

.picker-card {
	cursor: pointer;
	
	&.selected, &:hover {
		border-color: $selectionColour;
	}

	img {
		object-fit: contain;
		height: 170px;
	}
}