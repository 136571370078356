
.navbar {
	position: fixed;
    width: 100%;
    top: 0px;
    z-index: 20;
    // background: $navbarBackgroundColour;
    -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
	box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
	padding-top: 60px;

	.nav-wrapper {
		max-width: 1280px;
		margin: 0 auto;
		display: flex;

		.navbar-scroll .track-horizontal {
			background-color: white;
			border-radius: inherit;
			opacity: 0.9;
			cursor: pointer;
		}

		svg {
			color: #fff;
		}

		.navbar-nav {
			padding: 6px 0;
			height: 62px;
			overflow: hidden;
		}

		.nav-link {
			text-transform: none;
			font-size: 14px;
			font-weight: 300;
			color: #fff;
			box-shadow: none;
			padding: 13px;
			display: inline-flex;

			&:last-child {
				margin-right: 0;
			}

			svg {
				margin-right: 10px;
			}
		}
	
		.appbar-nav {
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			display: -ms-flexbox;
			display: -webkit-box;
			margin: 0;
			padding: 6px 10px;
			list-style: none;
	
			.nav-link {
				height: 50px;
	
				&:hover {
					svg {
					}
				}
	
				svg {
					margin: 0;
				}
			}
	
			#nav-notifications {
				position: relative;
	
				.notifications-panel {
					position: absolute;
					right: 0;
					width: 300px;
	
					> a {
						border-radius: 0 0 4px 4px;
					}
				}
			}
	
			#nav-profile {
				position: relative;
	
				button {
					padding-top: 10px;
				}
	
				.profile-panel {
					position: absolute;
					right: 0;
					width: 300px;
			
					.user-box {
						padding: 15px 15px 0;
						text-align: center;
			
						.user-details {
							padding: 8px 0;
						}
					}

					svg {
						color: inherit;
					}
				}
			}
		}
	}
}

#nav-dropdown {
	z-index: 20;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);

	.menu-item {
		font-weight: 300;
		color: $linkTextColour;
	}

	.sub-header {
		color: $navbarBackgroundColour;
		line-height: initial;
		position: initial;
		padding-top: 13px;
		font-weight: 600;
	}
}

@media (max-width: 767px) { 
	.navbar { 
		display: none;
	}
}