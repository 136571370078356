.student-details {
	.contact-info {
		display: flex;
		margin-bottom: 10px;

		> .dih-avatar {
			margin-right: 5px;
		}

		.h3 {
			margin: 0;
		}
	}
}