
@media print {
	.navbar {
		display: none;
	}

	.page-banner {
		display: none;
	}

	.content-wrapper {
		background: #fff;
		margin-top: 0;
	}

	.page-layout {
		padding-top: 0px !important;
	}

	a[role=button] { 
		display: none;
	}

	button { 
		display: none !important;
	}

	.page-header {
		display: none !important;
	}

	.tag {
		color: black !important;
		border: solid 1px black !important;
	}

	.print-show {
		display: initial !important;
	}

	.print-hide {
		display: none !important;
	}

	.print-table-colours {
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
	}
}

.print-show {
	display: none;
}