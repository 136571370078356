.discussion {
	.discussion-row {
		display: flex;
		padding: 10px 0;

		> .avatar {
			margin-right: 10px;
		}
	
		.h3 {
			margin-bottom: 0;
		}
	
		.label {
			margin-bottom: 10px;
			margin-right: 5px;
		}

		.stats > svg {
			color: #555;
			vertical-align: middle;
		}
	}
}