.student-project-card {
	height: 100%;

	.pcard-body {
		display: flex;
		flex-direction: column;
	
		.body {
			margin-bottom: 10px;
			flex-grow: 1;
		}
	
		.dates {
			font-style: italic;
			margin-bottom: 5px;
		}
	
		.tag {
			align-self: flex-start;
		}
	}
}