
.card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
	margin-bottom: 30px;
}

.card {
	position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
	padding: 0;
	margin-bottom: 30px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
	
	.card-header {
		@extend .d-flex;
		padding: 0.75rem 1.25rem;
		margin-bottom: 0;
		background-color: rgba(0, 0, 0, 0.03);
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	}

	.card-title {
		display: flex;
		align-items: flex-start;
		// overflow: hidden;

		.title {
			flex-grow: 1;

			h2 {
				margin-bottom: 0.45rem;
				overflow: hidden;
				text-overflow: ellipsis;
			}
	
			.subtitle {
				font-weight: 300;
				margin-bottom: 20px;
			}
		}

	}	

	.card-body {
		padding: 20px;
		flex-shrink: 0; // fix for blank space under cards in IE11
	}
}

@media (min-width: 576px) {
	.card-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px;

		.card {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-flex: 1;
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-right: 15px;
			margin-bottom: 0;
			margin-left: 15px;
		}
	}
}

.accordion-panel {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
}

@media print {
	.card-hide-borders-on-print {
		box-shadow: none !important;
		border: none !important;
	}
}