.student-card {
	height: 100%;

	> img {
		height: 200px;
	}

	.pcard-body {
		display: flex;
		flex-direction: column;

		.h3 {
			text-align: center;
		}

		.institution > img {
			height: 32px;
			margin-right: 5px;
		}

		.body {
			margin: 10px 0;
			flex-grow: 1;
		}

		.registration-date {
			font-style: italic;
		}

		.tag {
			align-self: flex-start;
		}
	}
} 