
.date-field {
	.time-input {
		vertical-align: top;
		height: 56px;
		margin-left: 5px;
		font-weight: 400;
		font-family: arial;
		font-size: 19px;
		line-height: 24px;
		padding-left: 10px;
		padding-right: 10px;
		border: 1px solid #dbdbdb;

		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			display: none;
		}

		&::-webkit-clear-button {
			display: none; /* Hide the button */
			-webkit-appearance: none; /* turn off default browser styling */
		}
	}
}

.SingleDatePicker_picker__portal {
	z-index: 1300 !important;
}
