.activity-card {
	height: 100%;
	margin: 0;
	max-height: 300px;

	.card-body {
		height: 100%;
		display: flex;
		flex-direction: column;

		.name {
			display: flex;
			align-items: center;
			color: $linkBackgroundColour;

			svg {
				margin-right: 5px;
			}
		}

		.summary {
			flex-grow: 1;
			text-overflow: hidden;
		}

		.tags {
			margin-top: 10px;
		}
	}
} 