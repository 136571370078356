
.main-layout {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;

	.page-layout {
		width: 100%;
		margin: 0 auto 0 270px;
		padding: 0 15px;

		.page-header {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			margin: 0 -15px;
			padding: 15px 0 20px;

			.page-title {
				padding: 0 15px;
				flex-shrink: 0;

				h1 {
					margin-bottom: 0px;
					margin-top: 0px;
				}
			}

			.breadcrumb {
				padding: 0px;
				background: transparent;
				font-size: 14px;
				margin-bottom: 0px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				list-style: none;
				border-radius: 0.25rem;

				li {
					margin-top: 0px;
					margin-bottom: 0px;
				}

				.breadcrumb-item {
					a {
						color: #007C9A;
					}

					&.active {
						color: #555;
					}

					.breadcrumb-divider {
						padding: 0 0.5rem;
					}
				}
			}

			.widgets {
				justify-content: flex-end;
				display: flex;
				flex: 1;
				flex-wrap: wrap;

				
				@media (max-width: 768px) {
					display: none;
				}

				.stat {
					display: flex;
					margin: 0 10px 10px;

					.stat-icon {
						@extend .round;
						padding-top: 6px;
						min-width: 45px;
						cursor: pointer;
					}

					.stat-text {
						@extend .align-self-center;
						margin-left: 10px;

						span {
							margin: 0;
						}
					}
				}
			}
		}

		.page-content {
			margin-bottom: 20px;
			font-size: medium;	
		}

		&.side-menu-collapsed {
			margin: 0 auto 0 90px;
		}
	}

}

@media (min-width: 768px) {
	.main-layout .page-layout:not(.login-view) {
		padding-top: 10px;
	}
}



@media (max-width: 767px) { 
	.main-layout {
		.page-layout {
			margin: 0;
		}
	}
}

.main-layout {
	&.is-public-link {
		.page-layout {
			padding-top: 0px;
		}
	}
}