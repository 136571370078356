
.event {
	.event-details-overlay {
		text-align: center;
		background: rgba(7, 10, 43, 0.8);

		> div {
			margin-left: auto;
			margin-right: auto;
		}
	}
}