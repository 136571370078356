.academic-card {
	height: 100%;

	> img {
		height: 200px;
	}

	.pcard-body {
		text-align: center;
		display: flex;
		flex-direction: column;

		.organisation > img {
			height: 32px;
			margin-right: 5px;
		}

		.body {
			flex-grow: 1;
		}

		.action-icons {
			margin-top: 10px;
			
			a {
				margin: 0 10px;
			}
		}
	}
} 