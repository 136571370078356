.collaboration-details .card-body {
	.dates {
		font-style: italic;
	}

	> img {
		max-width: 100%;
		display: block;
		margin: 20px auto;
	}
}