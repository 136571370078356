.subscription-page {
	&__header {
		text-align: center;
	}

	.subscription-level {
		margin: 20px 0;
		z-index: 10;
		min-height: 400px;
		text-align: center;
		color: #fff;
		background: #70AD47;
		height: calc(100% - 24px);;

		.card-body {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: 0;
		}
		&__name {
			font-weight: bold;
			font-size: 25px;
		}
		&__price {
			font-weight: bold;
			font-size: 24px;

			&__dollar-sign {
				font-size: 24px;
			}
		}
		&__duration {
			font-size: 18px;
		}
		&__description {
			margin: 0;
			text-align: left;
			line-height: 20px;
			flex-grow: 1;

			p {
				margin: 0;
			}
		}
		&__select-button {
			text-align: center;
			
			&__wrapper {
			}
		}
	}

	
	&__thank-you-text {
		text-align: center;
	}

	&__disclaimer {
		text-align: center;
		color: #aaa;
	}
	
}