.tool-row {
	margin-top: 10px;
			
	&:nth-child(2) {
		margin-top: 0;
	}

	.header {
		display: flex; 
		align-items: center;

		.h3 {
			margin-bottom: 0;
		}
	}

	.label {
		margin-bottom: 5px;
	}

	.academic {
		display: flex;
		align-items: center;

		svg {
			margin-right: 5px;
		}
	}
} 