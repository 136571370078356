.ip-row {
	padding: 20px 30px;
	width: 100%;
	overflow: hidden;
	border-left: 3px solid #ffffff;

	&:hover, &.active {

		.row-footer .action-icons {
			margin-left: 5px;
			visibility: visible;
		}
	}

	.row-footer {
		svg {
			vertical-align: text-top;
		}

		.action-icons {
			visibility: hidden;
		}
	}
}