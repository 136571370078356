.notifications .notification-row {
	display: flex;
	width: 100%;
	padding: 10px 15px;
	
	&:not(:first-child) {
		border-top: 1px solid rgba(120, 130, 140, 0.13);
	}
	
	&:not(.extended) {
		cursor: pointer;

		&:hover {
			background-color: #f2f4f8;
		}
	}

	button {
		flex-shrink: 0;

		&.cyan {
			background: #26c6da;
			border: 1px solid #26c6da;
		}
		
		&.green {
			background: #00b499;
			border: 1px solid #00b499;
		}

		&.blue {
			background: #007C9A;
			border: 1px solid #007C9A;
		}

		&.red {
			background: #fc4b6c;
			border: 1px solid #fc4b6c;
		}

		&.purple {
			background: #7460ee;
			border: 1px solid #7460ee;
		}

		&.lime {
			background: #74bf44;
			border: 1px solid #74bf44;
		}

		svg {
			color: white;
		}
	}

	.content {
		padding-left: 10px;
		flex-grow: 1;

		.description, .time {
			font-size: 12px;
			display: block;
			margin: 1px 0;
			text-overflow: ellipsis;
			overflow: hidden;
			color: #67757c;
			white-space: nowrap;
		}
	}
}