.organisation-card {
	margin: 0;

	.header {
		text-align: center;
		margin-bottom: 10px;

		> img {
			max-height: 150px;
			max-width: 100%;
			margin-bottom: 10px;
		}
	}
} 