
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before
{
  content: 'Default';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Arial']::before
{
  content: 'Arial';
  font-family: 'Arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Courier']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Courier']::before
{
  content: 'Courier';
  font-family: 'Courier';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Garamond']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Garamond']::before
{
  content: 'Garamond';
  font-family: 'Garamond';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Tahoma']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Tahoma']::before
{
  content: 'Tahoma';
  font-family: 'Tahoma';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Verdana']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Verdana']::before
{
  content: 'Verdana';
  font-family: 'Verdana';
}


/****************************************************
Set the font-family content used for the HTML content.
*****************************************************/
.ql-font-Arial {
	font-family: 'Arial';
}

.ql-font-Courier {
	font-family: 'Courier';
}

.ql-font-Garamond {
	font-family: 'Garamond';
}

  
.ql-font-Verdana {
	font-family: 'Verdana';
}
  
.ql-font-Tahoma {
	font-family: 'Tahoma';
}
  
  .quill-display-block {

  }
  .quill-display-block > * {
	cursor: text;
  }
  .quill-display-block p,
  .quill-display-block ol,
  .quill-display-block ul,
  .quill-display-block pre,
  .quill-display-block blockquote,
  .quill-display-block h1,
  .quill-display-block h2,
  .quill-display-block h3,
  .quill-display-block h4,
  .quill-display-block h5,
  .quill-display-block h6 {
	margin: 0;
	padding: 0;
	counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  .quill-display-block ol,
  .quill-display-block ul {
	padding-left: 1.5em;
  }
  .quill-display-block ol > li,
  .quill-display-block ul > li {
	list-style-type: none;
  }
  .quill-display-block ul > li::before {
	content: '\2022';
  }
  .quill-display-block ul[data-checked=true],
  .quill-display-block ul[data-checked=false] {
	pointer-events: none;
  }
  .quill-display-block ul[data-checked=true] > li *,
  .quill-display-block ul[data-checked=false] > li * {
	pointer-events: all;
  }
  .quill-display-block ul[data-checked=true] > li::before,
  .quill-display-block ul[data-checked=false] > li::before {
	color: #777;
	cursor: pointer;
	pointer-events: all;
  }
  .quill-display-block ul[data-checked=true] > li::before {
	content: '\2611';
  }
  .quill-display-block ul[data-checked=false] > li::before {
	content: '\2610';
  }
  .quill-display-block li::before {
	display: inline-block;
	white-space: nowrap;
	width: 1.2em;
  }
  .quill-display-block li:not(.ql-direction-rtl)::before {
	margin-left: -1.5em;
	margin-right: 0.3em;
	text-align: right;
  }
  .quill-display-block li.ql-direction-rtl::before {
	margin-left: 0.3em;
	margin-right: -1.5em;
  }
  .quill-display-block ol li:not(.ql-direction-rtl),
  .quill-display-block ul li:not(.ql-direction-rtl) {
	padding-left: 1.5em;
  }
  .quill-display-block ol li.ql-direction-rtl,
  .quill-display-block ul li.ql-direction-rtl {
	padding-right: 1.5em;
  }
  .quill-display-block ol li {
	counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	counter-increment: list-0;
  }
  .quill-display-block ol li:before {
	content: counter(list-0, decimal) '. ';
  }
  .quill-display-block ol li.ql-indent-1 {
	counter-increment: list-1;
  }
  .quill-display-block ol li.ql-indent-1:before {
	content: counter(list-1, lower-alpha) '. ';
  }
  .quill-display-block ol li.ql-indent-1 {
	counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  .quill-display-block ol li.ql-indent-2 {
	counter-increment: list-2;
  }
  .quill-display-block ol li.ql-indent-2:before {
	content: counter(list-2, lower-roman) '. ';
  }
  .quill-display-block ol li.ql-indent-2 {
	counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  .quill-display-block ol li.ql-indent-3 {
	counter-increment: list-3;
  }
  .quill-display-block ol li.ql-indent-3:before {
	content: counter(list-3, decimal) '. ';
  }
  .quill-display-block ol li.ql-indent-3 {
	counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }
  .quill-display-block ol li.ql-indent-4 {
	counter-increment: list-4;
  }
  .quill-display-block ol li.ql-indent-4:before {
	content: counter(list-4, lower-alpha) '. ';
  }
  .quill-display-block ol li.ql-indent-4 {
	counter-reset: list-5 list-6 list-7 list-8 list-9;
  }
  .quill-display-block ol li.ql-indent-5 {
	counter-increment: list-5;
  }
  .quill-display-block ol li.ql-indent-5:before {
	content: counter(list-5, lower-roman) '. ';
  }
  .quill-display-block ol li.ql-indent-5 {
	counter-reset: list-6 list-7 list-8 list-9;
  }
  .quill-display-block ol li.ql-indent-6 {
	counter-increment: list-6;
  }
  .quill-display-block ol li.ql-indent-6:before {
	content: counter(list-6, decimal) '. ';
  }
  .quill-display-block ol li.ql-indent-6 {
	counter-reset: list-7 list-8 list-9;
  }
  .quill-display-block ol li.ql-indent-7 {
	counter-increment: list-7;
  }
  .quill-display-block ol li.ql-indent-7:before {
	content: counter(list-7, lower-alpha) '. ';
  }
  .quill-display-block ol li.ql-indent-7 {
	counter-reset: list-8 list-9;
  }
  .quill-display-block ol li.ql-indent-8 {
	counter-increment: list-8;
  }
  .quill-display-block ol li.ql-indent-8:before {
	content: counter(list-8, lower-roman) '. ';
  }
  .quill-display-block ol li.ql-indent-8 {
	counter-reset: list-9;
  }
  .quill-display-block ol li.ql-indent-9 {
	counter-increment: list-9;
  }
  .quill-display-block ol li.ql-indent-9:before {
	content: counter(list-9, decimal) '. ';
  }
  .quill-display-block .ql-indent-1:not(.ql-direction-rtl) {
	padding-left: 3em;
  }
  .quill-display-block li.ql-indent-1:not(.ql-direction-rtl) {
	padding-left: 4.5em;
  }
  .quill-display-block .ql-indent-1.ql-direction-rtl.ql-align-right {
	padding-right: 3em;
  }
  .quill-display-block li.ql-indent-1.ql-direction-rtl.ql-align-right {
	padding-right: 4.5em;
  }
  .quill-display-block .ql-indent-2:not(.ql-direction-rtl) {
	padding-left: 6em;
  }
  .quill-display-block li.ql-indent-2:not(.ql-direction-rtl) {
	padding-left: 7.5em;
  }
  .quill-display-block .ql-indent-2.ql-direction-rtl.ql-align-right {
	padding-right: 6em;
  }
  .quill-display-block li.ql-indent-2.ql-direction-rtl.ql-align-right {
	padding-right: 7.5em;
  }
  .quill-display-block .ql-indent-3:not(.ql-direction-rtl) {
	padding-left: 9em;
  }
  .quill-display-block li.ql-indent-3:not(.ql-direction-rtl) {
	padding-left: 10.5em;
  }
  .quill-display-block .ql-indent-3.ql-direction-rtl.ql-align-right {
	padding-right: 9em;
  }
  .quill-display-block li.ql-indent-3.ql-direction-rtl.ql-align-right {
	padding-right: 10.5em;
  }
  .quill-display-block .ql-indent-4:not(.ql-direction-rtl) {
	padding-left: 12em;
  }
  .quill-display-block li.ql-indent-4:not(.ql-direction-rtl) {
	padding-left: 13.5em;
  }
  .quill-display-block .ql-indent-4.ql-direction-rtl.ql-align-right {
	padding-right: 12em;
  }
  .quill-display-block li.ql-indent-4.ql-direction-rtl.ql-align-right {
	padding-right: 13.5em;
  }
  .quill-display-block .ql-indent-5:not(.ql-direction-rtl) {
	padding-left: 15em;
  }
  .quill-display-block li.ql-indent-5:not(.ql-direction-rtl) {
	padding-left: 16.5em;
  }
  .quill-display-block .ql-indent-5.ql-direction-rtl.ql-align-right {
	padding-right: 15em;
  }
  .quill-display-block li.ql-indent-5.ql-direction-rtl.ql-align-right {
	padding-right: 16.5em;
  }
  .quill-display-block .ql-indent-6:not(.ql-direction-rtl) {
	padding-left: 18em;
  }
  .quill-display-block li.ql-indent-6:not(.ql-direction-rtl) {
	padding-left: 19.5em;
  }
  .quill-display-block .ql-indent-6.ql-direction-rtl.ql-align-right {
	padding-right: 18em;
  }
  .quill-display-block li.ql-indent-6.ql-direction-rtl.ql-align-right {
	padding-right: 19.5em;
  }
  .quill-display-block .ql-indent-7:not(.ql-direction-rtl) {
	padding-left: 21em;
  }
  .quill-display-block li.ql-indent-7:not(.ql-direction-rtl) {
	padding-left: 22.5em;
  }
  .quill-display-block .ql-indent-7.ql-direction-rtl.ql-align-right {
	padding-right: 21em;
  }
  .quill-display-block li.ql-indent-7.ql-direction-rtl.ql-align-right {
	padding-right: 22.5em;
  }
  .quill-display-block .ql-indent-8:not(.ql-direction-rtl) {
	padding-left: 24em;
  }
  .quill-display-block li.ql-indent-8:not(.ql-direction-rtl) {
	padding-left: 25.5em;
  }
  .quill-display-block .ql-indent-8.ql-direction-rtl.ql-align-right {
	padding-right: 24em;
  }
  .quill-display-block li.ql-indent-8.ql-direction-rtl.ql-align-right {
	padding-right: 25.5em;
  }
  .quill-display-block .ql-indent-9:not(.ql-direction-rtl) {
	padding-left: 27em;
  }
  .quill-display-block li.ql-indent-9:not(.ql-direction-rtl) {
	padding-left: 28.5em;
  }
  .quill-display-block .ql-indent-9.ql-direction-rtl.ql-align-right {
	padding-right: 27em;
  }
  .quill-display-block li.ql-indent-9.ql-direction-rtl.ql-align-right {
	padding-right: 28.5em;
  }
  .quill-display-block .ql-video {
	display: block;
	max-width: 100%;
  }
  .quill-display-block .ql-video.ql-align-center {
	margin: 0 auto;
  }
  .quill-display-block .ql-video.ql-align-right {
	margin: 0 0 0 auto;
  }
  .quill-display-block .ql-bg-black {
	background-color: #000;
  }
  .quill-display-block .ql-bg-red {
	background-color: #e60000;
  }
  .quill-display-block .ql-bg-orange {
	background-color: #f90;
  }
  .quill-display-block .ql-bg-yellow {
	background-color: #ff0;
  }
  .quill-display-block .ql-bg-green {
	background-color: #008a00;
  }
  .quill-display-block .ql-bg-blue {
	background-color: #06c;
  }
  .quill-display-block .ql-bg-purple {
	background-color: #93f;
  }
  .quill-display-block .ql-color-white {
	color: #fff;
  }
  .quill-display-block .ql-color-red {
	color: #e60000;
  }
  .quill-display-block .ql-color-orange {
	color: #f90;
  }
  .quill-display-block .ql-color-yellow {
	color: #ff0;
  }
  .quill-display-block .ql-color-green {
	color: #008a00;
  }
  .quill-display-block .ql-color-blue {
	color: #06c;
  }
  .quill-display-block .ql-color-purple {
	color: #93f;
  }
  .quill-display-block .ql-font-serif {
	font-family: Georgia, Times New Roman, serif;
  }
  .quill-display-block .ql-font-monospace {
	font-family: Monaco, Courier New, monospace;
  }
  .quill-display-block .ql-size-small {
	font-size: 0.75em;
  }
  .quill-display-block .ql-size-large {
	font-size: 1.5em;
	line-height: initial;
  }
  .quill-display-block .ql-size-huge {
	font-size: 2.5em;
	line-height: initial;
  }
  .quill-display-block .ql-direction-rtl {
	direction: rtl;
	text-align: inherit;
  }
  .quill-display-block .ql-align-center {
	text-align: center;
  }
  .quill-display-block .ql-align-justify {
	text-align: justify;
  }
  .quill-display-block .ql-align-right {
	text-align: right;
  }
  .quill-display-block.ql-blank::before {
	color: rgba(0,0,0,0.6);
	content: attr(data-placeholder);
	font-style: italic;
	left: 15px;
	pointer-events: none;
	position: absolute;
	right: 15px;
  }
  .ql-snow.ql-toolbar:after,
  .ql-snow .ql-toolbar:after {
	clear: both;
	content: '';
	display: table;
  }
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
	background: none;
	border: none;
	cursor: pointer;
	display: inline-block;
	float: left;
	height: 24px;
	padding: 3px 5px;
	width: 28px;
  }
  