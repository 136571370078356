.tool-card {
	height: 100%;

	.card-body {
		height: 100%;
		display: flex;
		flex-direction: column;

		.header {
			display: flex;
			align-items: center;
		}

		.academic {
			display: flex;
			align-items: center;
	
			svg {
				margin-right: 5px;
			}
		}

		.label {
			align-self: flex-start;
			margin-bottom: 5px;
		}

		.description {
			flex-grow: 1;
		}

		.tags {
			margin-top: 10px;
		}
	}
} 