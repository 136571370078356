.tool-details .card-body {
	h2 {
		margin: 0;
		display: flex;
		align-items: center;
	}

	.academic {
		display: flex;
		align-items: center;

		svg {
			margin-right: 5px;
		}
	}
}