.student-project-row {
	padding: 20px 30px;
	width: 100%;
	overflow: hidden;
	border-left: 3px solid #ffffff;
	display: flex;

	&:hover, &.active {
		.row-footer .action-icons {
			margin-left: 5px;
			visibility: visible;
		}
	}

	.content {
		flex: 1;

		.dates {
			font-style: italic;
		}
	
		.tags {
			margin: 5px 0;
	
			.tag {
				margin-right: 2px;
			}
		}
	
		.row-footer {
			svg {
				vertical-align: text-top;
			}
	
			.action-icons {
				visibility: hidden;
			}
		}
	}

	> img {
		max-width: 200px;
		margin: 5px 0 5px 30px;
		object-fit: contain;
	}
}