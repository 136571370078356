.products-page {

	.product {
		margin: 20px 0;
		z-index: 10;
		min-height: 400px;
		text-align: center;

		&__name {
			font-weight: bold;
			font-size: 30px;
		}
		&__price {
			font-weight: bold;
			font-size: 60px;

			&__dollar-sign {
				font-size: 40px;
			}
		}
		&__duration {
			color: #aaa;
			font-size: 18px;
		}
		&__description {
			color: #aaa;
			margin: 0;
			text-align: left;
    		line-height: 20px;
		}
	}
}