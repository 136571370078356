
.quill.terms-dialog .ql-container {
	height: 100%;
	border: none;

	.ql-editor {
		padding: 0;

		// First-level list items
		li:not([class^="ql-indent"]) {
			font-weight: 400;
			line-height: 30px;
			font-size: 21px;
			padding-top: 1rem;
		}

		p[class^="ql-indent"] {
			padding-bottom: 1rem;
		}

		li > strong:after {
			content: '\A';
			white-space: pre;
		}
	}
}