
html {
	position: relative;
    height: 100%;
    background: #fff;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    overflow-x: hidden;
    color: #555;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.6;
	text-align: left;
	height: 100%;
}

#root {
	height: 100%;
}

.content-wrapper {
	display: flex;
    flex-grow: 1;
	margin-top: 60px;
	flex: 1 0 auto;
	width: 100%;
	background: #FBFBFB;
	-webkit-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	min-height: 1px; // Fix IE bug https://github.com/philipwalton/flexbugs/issues/75
}

img {
	vertical-align: middle;
    border-style: none;
}

a {
    color: #006fe6;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

strong {
	font-weight: bold;
}

// Styling from: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/kbd
kbd {
	background-color: #eee;
	border-radius: 3px;
	border: 1px solid #b4b4b4;
	box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset;
	color: #333;
	display: inline-block;
	// font-size: .85em;
	// font-weight: 700;
	line-height: 1;
	padding: 2px 4px;
	white-space: nowrap;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	display: block;
	margin-top: 0;
	// margin-bottom: 0.5rem;
	margin-bottom: 0;
    color: #455a64;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

h1, .h1 {
	line-height: 40px;
	font-size: 36px;
}

h2, .h2 {
	line-height: 36px;
	font-size: 24px;
}

h3, .h3 {
	line-height: 30px;
	font-size: 21px;
}

h4, .h4 {
	line-height: 22px;
	font-size: 18px;
}

h5, .h5 {
	line-height: 18px;
	font-size: 16px;
	font-weight: 400;
}

h6, .h6 {
	line-height: 16px;
	font-size: 14px;
	font-weight: 400;
}

hr {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

p {
	margin-top: 0;
	// margin-bottom: 1rem;
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

* {
    outline: none;
}

.dih-input textarea {
	min-height: 21px;
}

table {
	border-collapse: collapse;
}

.quill .ql-container {
	font-family: "Poppins", sans-serif;
	font-size: 16px;

	&:not(.ql-disabled) > .ql-editor {
		min-height: 135px;
		max-height: 300px;

		&::before {
			font-style: normal;
			padding-right: 12px;
			max-height: 110px;
			overflow: hidden;
		}
	}
}

section {
	margin-bottom: 20px;
}

/* Truncate text solution found: http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/ */
p.truncate-text {
	/* hide text if it more than N lines  */
	overflow: hidden;
	/* for set '...' in absolute position */
	position: relative; 
	/* use this value to count block height */
	line-height: 1.6em;
	/* max-height = line-height (1.6) * lines max number (2) */
	max-height: 3.2em !important; 
	/* fix problem when last visible word doesn't adjoin right side  */
	text-align: justify;  
	/* place for '...' */
	margin-right: -1em;
	padding-right: 1em;

	&:before {
		/* points in the end */
		content: "...";
		/* absolute position */
		position: absolute;
		/* set position to right bottom corner of block */
		right: 0;
		bottom: 0;
	}

	&:after {
		/* points in the end */
		content: "";
		/* absolute position */
		position: absolute;
		/* set position to right bottom corner of text */
		right: 0;
		/* set width and height */
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		/* bg color = bg color under block */
		background: white;
	}
}