.youtube-embed {
	max-width: 800px;
	margin: auto;
	padding: 10px;

	.frame-wrapper {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
	
		a {
			display: block;
		}
	
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}