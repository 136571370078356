
.view-page-container {
	.view-page-title-container {
		display: flex;
		align-items: flex-start;
		margin-bottom: 5px;

		.view-page-title {
			flex-grow: 1;

			h2 {
				margin-bottom: 0;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

	}	
}
