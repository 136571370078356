
.classification-picker {
	user-select: none;
	display: inline-block;
	cursor: pointer;

	&:hover {
		background: $selectionColour;	
		color: $selectionAltColour;
	}

	&__name {
		font-weight: bold;
		font-size: 20px;
	}
}