.pull-right {
    float: right;
}


/*******************
Paddings
*******************/

html body .p-0 {
  padding: 0px;
}

html body .p-10 {
  padding: 10px;
}

html body .p-20 {
  padding: 20px;
}

html body .p-30 {
  padding: 30px;
}

html body .p-l-0 {
  padding-left: 0px;
}

html body .p-l-10 {
  padding-left: 10px;
}

html body .p-l-20 {
  padding-left: 20px;
}

html body .p-r-0 {
  padding-right: 0px;
}

html body .p-r-10 {
  padding-right: 10px;
}

html body .p-r-20 {
  padding-right: 20px;
}

html body .p-r-30 {
  padding-right: 30px;
}

html body .p-r-40 {
  padding-right: 40px;
}

html body .p-t-0 {
  padding-top: 0px;
}

html body .p-t-10 {
  padding-top: 10px;
}

html body .p-t-20 {
  padding-top: 20px;
}

html body .p-t-30 {
  padding-top: 30px;
}

html body .p-b-0 {
  padding-bottom: 0px;
}

html body .p-b-5 {
  padding-bottom: 5px;
}

html body .p-b-10 {
  padding-bottom: 10px;
}

html body .p-b-20 {
  padding-bottom: 20px;
}

html body .p-b-30 {
  padding-bottom: 30px;
}

html body .p-b-40 {
  padding-bottom: 40px;
}

/*******************
Margin
*******************/

html body .m-0 {
  margin: 0px;
}

html body .m-l-5 {
  margin-left: 5px;
}

html body .m-l-10 {
  margin-left: 10px;
}

html body .m-l-15 {
  margin-left: 15px;
}

html body .m-l-20 {
  margin-left: 20px;
}

html body .m-l-30 {
  margin-left: 30px;
}

html body .m-l-40 {
  margin-left: 40px;
}

html body .m-r-5 {
  margin-right: 5px;
}

html body .m-r-10 {
  margin-right: 10px;
}

html body .m-r-15 {
  margin-right: 15px;
}

html body .m-r-20 {
  margin-right: 20px;
}

html body .m-r-30 {
  margin-right: 30px;
}

html body .m-r-40 {
  margin-right: 40px;
}

html body .m-t-0 {
  margin-top: 0px;
}

html body .m-t-5 {
  margin-top: 5px;
}

html body .m-t-10 {
  margin-top: 10px;
}

html body .m-t-15 {
  margin-top: 15px;
}

html body .m-t-20 {
  margin-top: 20px;
}

html body .m-t-30 {
  margin-top: 30px;
}

html body .m-t-40 {
  margin-top: 40px;
}

html body .m-b-0 {
  margin-bottom: 0px;
}

html body .m-b-5 {
  margin-bottom: 5px;
}

html body .m-b-10 {
  margin-bottom: 10px;
}

html body .m-b-15 {
  margin-bottom: 15px;
}

html body .m-b-20 {
  margin-bottom: 20px;
}

html body .m-b-30 {
  margin-bottom: 30px;
}

html body .m-b-40 {
  margin-bottom: 40px;
}

/*******************
vertical alignment
*******************/

html body .vt {
  vertical-align: top;
}

html body .vm {
  vertical-align: middle;
}

html body .vb {
  vertical-align: bottom;
}

/*******************
Opacity
*******************/

.op-5 {
  opacity: 0.5;
}

/*******************
font weight
*******************/

html body .font-bold {
  font-weight: 700;
}

html body .font-normal {
  font-weight: normal;
}

html body .font-light {
  font-weight: 300;
}

html body .font-medium {
  font-weight: 500;
}

html body .font-16 {
  font-size: 16px;
}

html body .font-14 {
  font-size: 14px;
}

html body .font-10 {
  font-size: 10px;
}

html body .font-18 {
  font-size: 18px;
}

html body .font-20 {
  font-size: 20px;
}

/*******************
Border
*******************/

html body .b-0 {
  border: none;
}

html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

/*******************
Text Colors
*******************/

.text-white {
	color: #ffffff !important;
  }
  
  .text-danger {
	color: #fc4b6c !important;
  }
  
  .text-muted {
	color: hsl(200, 15%, 65%) !important;
  }
  
  .text-warning {
	color: #ffb22b !important;
  }
  
  .text-success {
	color: #26c6da !important;
  }
  
  .text-info {
	color: #0091b4 !important;
  }
  
  .text-inverse {
	color: #2f3d4a !important;
  }
  
  html body .text-blue {
	color: #02bec9;
  }
  
  html body .text-purple {
	color: #7460ee;
  }
  
  html body .text-primary {
	color: #7460ee;
  }
  
  html body .text-megna {
	color: #00897b;
  }
  
  html body .text-dark {
	color: #67757c;
  }
  
  html body .text-themecolor {
	color: #009efb;
  }
  

.text-themecolor {
	color: #007C9A !important;
  }

.text-muted {
    color: #555 !important;
}

.text-link {
	text-decoration: underline;
  }


/*******************
Rounds
*******************/

.round {
	line-height: 48px;
	color: #ffffff;
	width: 45px;
	height: 45px;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	border-radius: 100%;
	background: #0091b4;
  }
  
  .round img {
	border-radius: 100%;
  }
  
  .round-lg {
	line-height: 65px;
	width: 60px;
	height: 60px;
	font-size: 30px;
  }
  
  .round.round-info {
	background: #0091b4;
  }
  
  .round.round-warning {
	background: #ffb22b;
  }
  
  .round.round-danger {
	background: #fc4b6c;
  }
  
  .round.round-success {
	background: #26c6da;
  }
  
  .round.round-primary {
	background: #7460ee;
  }

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

ul.list-inline {
	padding-left: 0;
	list-style: none;

	li {
		display: inline-block;
		padding: 0 8px;
	}
  }


/*******************
Labels
*******************/

.label {
	padding: 3px 10px;
	line-height: 13px;
	color: #ffffff;
	font-weight: 400;
	border-radius: 4px;
	font-size: 75%;
  }
  
  .label-rounded {
	border-radius: 60px;
  }
  
  .label-custom {
	background-color: #00897b;
  }
  
  .label-success {
	background-color: #26c6da;
  }
  
  .label-info {
	background-color: #0091b4;
  }
  
  .label-warning {
	background-color: #ffb22b;
  }
  
  .label-danger {
	background-color: #fc4b6c;
  }
  
  .label-megna {
	background-color: #00897b;
  }
  
  .label-primary {
	background-color: #7460ee;
  }
  
  .label-purple {
	background-color: #7460ee;
  }
  
  .label-red {
	background-color: #fb3a3a;
  }
  
  .label-inverse {
	background-color: #2f3d4a;
  }
  
  .label-default {
	background-color: #f2f4f8;
  }
  
  .label-white {
	background-color: #ffffff;
  }
  
  .label-light-success {
	background-color: #e8fdeb;
	color: #26c6da;
  }
  
  .label-light-info {
	background-color: #cfecfe;
	color: #006680;
  }
  
  .label-light-warning {
	background-color: #fff8ec;
	color: #ffb22b;
  }
  
  .label-light-danger {
	background-color: #f9e7eb;
	color: #fc4b6c;
  }
  
  .label-light-megna {
	background-color: #e0f2f4;
	color: #00897b;
  }
  
  .label-light-primary {
	background-color: #f1effd;
	color: #7460ee;
  }
  
  .label-light-inverse {
	background-color: #f6f6f6;
	color: #2f3d4a;
  }

.label {
	display: inline-block;
  }

/******************
Ribbons
******************/

.ribbon-wrapper,
.ribbon-wrapper-reverse,
.ribbon-wrapper-bottom,
.ribbon-wrapper-right-bottom {
  position: relative;
  padding: 50px 15px 15px 15px;
}

.ribbon-vwrapper {
  padding: 15px 15px 15px 50px;
  position: relative;
}

.ribbon-overflow {
  overflow: hidden;
}

.ribbon-vwrapper-reverse {
  padding: 15px 50px 15px 15px;
}

.ribbon-wrapper-bottom {
  padding: 15px 15px 50px 50px;
}

.ribbon-wrapper-right-bottom {
  padding: 15px 50px 50px 15px;
}

.ribbon-content {
  margin-bottom: 0px;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: -2px;
  color: #ffffff;
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border: 15px solid #263238;
  border-right: 10px solid transparent;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #263238;
  border-left: 10px solid transparent;
}

.ribbon-vertical-l,
.ribbon-vertical-r {
  clear: none;
  padding: 0 5px;
  height: 60px;
  width: 30px;
  line-height: 60px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-r {
  left: auto;
  right: 12px;
}

.ribbon-bookmark.ribbon-vertical-l:before,
.ribbon-bookmark.ribbon-vertical-r:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #263238;
  border-bottom: 10px solid transparent;
}

.ribbon-badge {
  top: 15px;
  overflow: hidden;
  left: -90px;
  width: 100%;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-badge.ribbon-right {
  left: auto;
  right: -90px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: 15px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-right.ribbon-bottom {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-corner {
  top: 0;
  left: 0;
  background-color: transparent !important;
  padding: 6px 0 0 10px;
}

.ribbon-corner i {
  position: relative;
}

.ribbon-corner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 30px solid transparent;
  border-top-color: #009efb;
  border-left-color: #009efb;
}

.ribbon-corner.ribbon-right:before {
  right: 0;
  left: auto;
  border-right-color: #526069;
  border-left-color: transparent;
}

.ribbon-corner.ribbon-right {
  right: 0;
  left: auto;
  padding: 6px 10px 0 0;
}

.ribbon-corner.ribbon-bottom:before {
  top: auto;
  bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #526069;
}

.ribbon-corner.ribbon-bottom {
  bottom: 0;
  top: auto;
  padding: 0 10px 6px 10px;
}

.ribbon-custom {
  background: #009efb;
}

.ribbon-bookmark.ribbon-right.ribbon-custom:before {
  border-right-color: #009efb;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-custom:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-custom:before {
  border-right-color: #009efb;
  border-bottom-color: transparent;
}

.ribbon-primary {
  background: #7460ee;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #7460ee;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #7460ee;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-primary:before {
  border-right-color: #7460ee;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #7460ee;
  border-left-color: #7460ee;
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #7460ee;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #7460ee;
}

.ribbon-success {
  background: #74bf44;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #74bf44;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #74bf44;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-success:before {
  border-right-color: #74bf44;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #74bf44;
  border-left-color: #74bf44;
}

.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #74bf44;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #74bf44;
}

.ribbon-info {
  background: #0091b4;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #0091b4;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #0091b4;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-info:before {
  border-right-color: #0091b4;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #0091b4;
  border-left-color: #0091b4;
}

.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #0091b4;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #0091b4;
}

.ribbon-warning {
  background: #ffb22b;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #ffb22b;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #ffb22b;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-warning:before {
  border-right-color: #ffb22b;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #ffb22b;
  border-left-color: #ffb22b;
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #ffb22b;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ffb22b;
}

.ribbon-danger {
  background: #fc4b6c;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #fc4b6c;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #fc4b6c;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-danger:before {
  border-right-color: #fc4b6c;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #fc4b6c;
  border-left-color: #fc4b6c;
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #fc4b6c;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #fc4b6c;
}

.ribbon-default {
  background: #263238;
}

.ribbon-bookmark.ribbon-default:before {
  border-color: #263238;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-default:before {
  border-right-color: #263238;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-default:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-default:before {
  border-right-color: #263238;
  border-bottom-color: transparent;
}

.ribbon-default.ribbon-corner:before {
  border-top-color: #263238;
  border-left-color: #263238;
}

.ribbon-default.ribbon-corner.ribbon-right:before {
  border-right-color: #263238;
  border-left-color: transparent;
}

.ribbon-default.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #263238;
}

/******************
Badges
******************/

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #27a9e1;
}

.badge-info {
  background-color: #0091b4;
}

.badge-primary {
  background-color: #7460ee;
}

.badge-warning {
  background-color: #ffb22b;
  color: #ffffff;
}

.badge-danger {
  background-color: #fc4b6c;
}

.badge-purple {
  background-color: #7460ee;
}

.badge-red {
  background-color: #fb3a3a;
}

.badge-inverse {
  background-color: #2f3d4a;
}

/******************
	Comment
******************/

.comment-widgets {
	position: relative;
	margin-bottom: 10px;
  }
  
  .comment-widgets .comment-row {
	border-left: 3px solid #ffffff;
	padding: 15px;
  }
  
  .comment-widgets .comment-row:hover,
  .comment-widgets .comment-row.active {
	border-color: #009efb;
  }
  
  .comment-text {
	  overflow: hidden;
		padding: 15px 15px 15px 20px;
  }
  
  .comment-text:hover .comment-footer .action-icons,
  .comment-text.active .comment-footer .action-icons {
	visibility: visible;
  }
  
  .comment-text p {
	max-height: 50px;
	overflow: hidden;
  }
  
  .comment-footer .action-icons {
	visibility: hidden;
  }
  
  .action-icons a {
	margin: 0 7px;
	vertical-align: middle;
	color: #555;
	cursor: pointer;
  }

  .action-icons svg {
	vertical-align: text-top;
	font-size: 17px;
  }
  
  .action-icons a:hover,
  .action-icons a.active {
	color: #0091b4;
  }

/*******************
	Table Cell
*******************/

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
  }
  
  .table th,
  .table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
	border-top: 2px solid #dee2e6;
  }
  
  .table .table {
	background-color: #f5f8fa;
  }
  
  .table-sm th,
  .table-sm td {
	padding: 0.3rem;
  }
  
  .table-bordered {
	border: 1px solid #dee2e6;
  }
  
  .table-bordered th,
  .table-bordered td {
	border: 1px solid #dee2e6;
  }
  
  .table-bordered thead th,
  .table-bordered thead td {
	border-bottom-width: 2px;
  }
  
  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
	border: 0;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
	background-color: #b8daff;
  }
  
  .table-hover .table-primary:hover {
	background-color: #9fcdff;
  }
  
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
	background-color: #9fcdff;
  }
  
  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
	background-color: #d6d8db;
  }
  
  .table-hover .table-secondary:hover {
	background-color: #c8cbcf;
  }
  
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
	background-color: #c8cbcf;
  }
  
  .table-success,
  .table-success > th,
  .table-success > td {
	background-color: #c3e6cb;
  }
  
  .table-hover .table-success:hover {
	background-color: #b1dfbb;
  }
  
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
	background-color: #b1dfbb;
  }
  
  .table-info,
  .table-info > th,
  .table-info > td {
	background-color: #bee5eb;
  }
  
  .table-hover .table-info:hover {
	background-color: #abdde5;
  }
  
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
	background-color: #abdde5;
  }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
	background-color: #ffeeba;
  }
  
  .table-hover .table-warning:hover {
	background-color: #ffe8a1;
  }
  
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
	background-color: #ffe8a1;
  }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
	background-color: #f5c6cb;
  }
  
  .table-hover .table-danger:hover {
	background-color: #f1b0b7;
  }
  
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
	background-color: #f1b0b7;
  }
  
  .table-light,
  .table-light > th,
  .table-light > td {
	background-color: #fdfdfe;
  }
  
  .table-hover .table-light:hover {
	background-color: #ececf6;
  }
  
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
	background-color: #ececf6;
  }
  
  .table-dark,
  .table-dark > th,
  .table-dark > td {
	background-color: #c6c8ca;
  }
  
  .table-hover .table-dark:hover {
	background-color: #b9bbbe;
  }
  
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
	background-color: #b9bbbe;
  }
  
  .table-active,
  .table-active > th,
  .table-active > td {
	background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
	background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table .thead-dark th {
	color: #f5f8fa;
	background-color: #212529;
	border-color: #32383e;
  }
  
  .table .thead-light th {
	color: #495057;
	background-color: #e9ecef;
	border-color: #dee2e6;
  }
  
  .table-dark {
	color: #f5f8fa;
	background-color: #212529;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
	border-color: #32383e;
  }
  
  .table-dark.table-bordered {
	border: 0;
  }
  
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(255, 255, 255, 0.05);
  }
  
  .table-dark.table-hover tbody tr:hover {
	background-color: rgba(255, 255, 255, 0.075);
  }
  
  @media (max-width: 575.98px) {
	.table-responsive-sm {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
  
	.table-responsive-sm > .table-bordered {
	  border: 0;
	}
  }
  
  @media (max-width: 767.98px) {
	.table-responsive-md {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
  
	.table-responsive-md > .table-bordered {
	  border: 0;
	}
  }
  
  @media (max-width: 991.98px) {
	.table-responsive-lg {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
  
	.table-responsive-lg > .table-bordered {
	  border: 0;
	}
  }
  
  @media (max-width: 1199.98px) {
	.table-responsive-xl {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
  
	.table-responsive-xl > .table-bordered {
	  border: 0;
	}
  }
  
  .table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
  }
  
  .table-responsive > .table-bordered {
	border: 0;
  }

  
.table-box {
	display: table;
	width: 100%;
  }
  
  .table.no-border tbody td {
	border: 0px;
  }
  
  .cell {
	display: table-cell;
	vertical-align: middle;
  }

  
  .table td,
  .table th {
	border-color: #f3f1f1;
  }
  
  .table thead th,
  .table th {
	font-weight: 500;
  }
  
  .table-hover tbody tr:hover {
	background: #f2f4f8;
  }
  
  .v-middle td,
  .v-middle th {
	vertical-align: middle;
  }
  
  .table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .stylish-table thead th, 
  .stylish-table tr.header-row td {
	font-weight: 400;
	color: #555;
	border: 0px;
	border-bottom: 1px;
  }
  
  .stylish-table tbody tr {
	border-left: 4px solid #ffffff;
  }
  
  .stylish-table tbody tr:hover,
  .stylish-table tbody tr.active {
	border-left: 4px solid #009efb;
  }
  
  .stylish-table tbody td {
	vertical-align: middle;
  }
  
  .stylish-table tbody td h6,
  .stylish-table tbody td .h6 {
	font-weight: 500;
	margin-bottom: 0px;
	white-space: nowrap;
  }
  
  .stylish-table tbody td small {
	line-height: 12px;
	white-space: nowrap;
  }
  
.vote-actions {
  padding: 0.5rem 0.5rem 0;
	
  i {
	color: #0091b4 !important;
  }
  
  div.vote-count {
	font-size: 1.5em;
  }
}

.media {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
		-ms-flex-align: start;
			align-items: flex-start;
	border: 1px solid rgba(120, 130, 140, 0.13);
	margin-bottom: 10px;
	padding: 15px;
}
  
  .media-body {
	-webkit-box-flex: 1;
		-ms-flex: 1;
			flex: 1;
  }
  
  .list-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
  }
  
  .list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit;
  }
  
  .list-group-item-action:hover,
  .list-group-item-action:focus {
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa;
  }
  
  .list-group-item-action:active {
	color: #212529;
	background-color: #e9ecef;
  }
  
  .list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .list-group-item:first-child {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
  }
  
  .list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
  }
  
  .list-group-item:hover,
  .list-group-item:focus {
	z-index: 1;
	text-decoration: none;
  }
  
  .list-group-item.disabled,
  .list-group-item:disabled {
	color: #6c757d;
	background-color: #fff;
  }
  
  .list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
  }
  
  .list-group-flush .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
  }
  
  .list-group-flush:first-child .list-group-item:first-child {
	border-top: 0;
  }
  
  .list-group-flush:last-child .list-group-item:last-child {
	border-bottom: 0;
  }
  
  .list-group-item-primary {
	color: #004085;
	background-color: #b8daff;
  }
  
  .list-group-item-primary.list-group-item-action:hover,
  .list-group-item-primary.list-group-item-action:focus {
	color: #004085;
	background-color: #9fcdff;
  }
  
  .list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #004085;
	border-color: #004085;
  }
  
  .list-group-item-secondary {
	color: #383d41;
	background-color: #d6d8db;
  }
  
  .list-group-item-secondary.list-group-item-action:hover,
  .list-group-item-secondary.list-group-item-action:focus {
	color: #383d41;
	background-color: #c8cbcf;
  }
  
  .list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #383d41;
	border-color: #383d41;
  }
  
  .list-group-item-success {
	color: #155724;
	background-color: #c3e6cb;
  }
  
  .list-group-item-success.list-group-item-action:hover,
  .list-group-item-success.list-group-item-action:focus {
	color: #155724;
	background-color: #b1dfbb;
  }
  
  .list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #155724;
	border-color: #155724;
  }
  
  .list-group-item-info {
	color: #0c5460;
	background-color: #bee5eb;
  }
  
  .list-group-item-info.list-group-item-action:hover,
  .list-group-item-info.list-group-item-action:focus {
	color: #0c5460;
	background-color: #abdde5;
  }
  
  .list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #0c5460;
	border-color: #0c5460;
  }
  
  .list-group-item-warning {
	color: #856404;
	background-color: #ffeeba;
  }
  
  .list-group-item-warning.list-group-item-action:hover,
  .list-group-item-warning.list-group-item-action:focus {
	color: #856404;
	background-color: #ffe8a1;
  }
  
  .list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #856404;
	border-color: #856404;
  }
  
  .list-group-item-danger {
	color: #721c24;
	background-color: #f5c6cb;
  }
  
  .list-group-item-danger.list-group-item-action:hover,
  .list-group-item-danger.list-group-item-action:focus {
	color: #721c24;
	background-color: #f1b0b7;
  }
  
  .list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #721c24;
	border-color: #721c24;
  }
  
  .list-group-item-light {
	color: #818182;
	background-color: #fdfdfe;
  }
  
  .list-group-item-light.list-group-item-action:hover,
  .list-group-item-light.list-group-item-action:focus {
	color: #818182;
	background-color: #ececf6;
  }
  
  .list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #818182;
	border-color: #818182;
  }
  
  .list-group-item-dark {
	color: #1b1e21;
	background-color: #c6c8ca;
  }
  
  .list-group-item-dark.list-group-item-action:hover,
  .list-group-item-dark.list-group-item-action:focus {
	color: #1b1e21;
	background-color: #b9bbbe;
  }
  
  .list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #1b1e21;
	border-color: #1b1e21;
  }
  
  
/*******************
List-style-none
******************/

ul.list-style-none {
	margin: 0px;
	padding: 0px;
  }
  
  ul.list-style-none li {
	list-style: none;
  }
  
  ul.list-style-none li a {
	color: #67757c;
	padding: 8px 0px;
	display: block;
	text-decoration: none;
  }
  
  ul.list-style-none li a:hover {
	color: #009efb;
  }

  .close {
	float: right;
	font-size: 1.35rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
  }
  
  .close:hover,
  .close:focus {
	color: #000;
	text-decoration: none;
	opacity: .75;
  }
  
  .close:not(:disabled):not(.disabled) {
	cursor: pointer;
  }
  
.border-0 {
	border: 0 !important;
  }
  
  .border-top-0 {
	border-top: 0 !important;
  }
  
  .border-right-0 {
	border-right: 0 !important;
  }
  
  .border-bottom-0 {
	border-bottom: 0 !important;
  }
  
  .border-left-0 {
	border-left: 0 !important;
  }
  
  .border-primary {
	border-color: #007bff !important;
  }
  
  .border-secondary {
	border-color: #6c757d !important;
  }
  
  .border-success {
	border-color: #28a745 !important;
  }
  
  .border-info {
	border-color: #17a2b8 !important;
  }
  
  .border-warning {
	border-color: #ffc107 !important;
  }
  
  .border-danger {
	border-color: #dc3545 !important;
  }
  
  .border-light {
	border-color: #f8f9fa !important;
  }
  
  .border-dark {
	border-color: #343a40 !important;
  }
  
  .border-white {
	border-color: #fff !important;
  }
  
  .rounded {
	border-radius: 0.25rem !important;
  }
  
  .rounded-top {
	border-top-left-radius: 0.25rem !important;
	border-top-right-radius: 0.25rem !important;
  }
  
  .rounded-right {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
  }
  
  .rounded-bottom {
	border-bottom-right-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-left {
	border-top-left-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-circle {
	border-radius: 50% !important;
  }
  
  .rounded-0 {
	border-radius: 0 !important;
  }
  
  .clearfix::after {
	display: block;
	clear: both;
	content: "";
  }
  
  
@media (min-width: 1600px) {
	.col-xlg-1,
	.col-xlg-10,
	.col-xlg-11,
	.col-xlg-12,
	.col-xlg-2,
	.col-xlg-3,
	.col-xlg-4,
	.col-xlg-5,
	.col-xlg-6,
	.col-xlg-7,
	.col-xlg-8,
	.col-xlg-9 {
	  float: left;
	}
  
	.col-xlg-12 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 100%;
	  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.col-xlg-11 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 91.66666667%;
	  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-xlg-10 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 83.33333333%;
	  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-xlg-9 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 75%;
	  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-xlg-8 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 66.66666667%;
	  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-xlg-7 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 58.33333333%;
	  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-xlg-6 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 50%;
	  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-xlg-5 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 41.66666667%;
	  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-xlg-4 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 33.33333333%;
	  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-xlg-3 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 25%;
	  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-xlg-2 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 16.66666667%;
	  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-xlg-1 {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 8.33333333%;
	  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-xlg-pull-12 {
	  right: 100%;
	}
  
	.col-xlg-pull-11 {
	  right: 91.66666667%;
	}
  
	.col-xlg-pull-10 {
	  right: 83.33333333%;
	}
  
	.col-xlg-pull-9 {
	  right: 75%;
	}
  
	.col-xlg-pull-8 {
	  right: 66.66666667%;
	}
  
	.col-xlg-pull-7 {
	  right: 58.33333333%;
	}
  
	.col-xlg-pull-6 {
	  right: 50%;
	}
  
	.col-xlg-pull-5 {
	  right: 41.66666667%;
	}
  
	.col-xlg-pull-4 {
	  right: 33.33333333%;
	}
  
	.col-xlg-pull-3 {
	  right: 25%;
	}
  
	.col-xlg-pull-2 {
	  right: 16.66666667%;
	}
  
	.col-xlg-pull-1 {
	  right: 8.33333333%;
	}
  
	.col-xlg-pull-0 {
	  right: auto;
	}
  
	.col-xlg-push-12 {
	  left: 100%;
	}
  
	.col-xlg-push-11 {
	  left: 91.66666667%;
	}
  
	.col-xlg-push-10 {
	  left: 83.33333333%;
	}
  
	.col-xlg-push-9 {
	  left: 75%;
	}
  
	.col-xlg-push-8 {
	  left: 66.66666667%;
	}
  
	.col-xlg-push-7 {
	  left: 58.33333333%;
	}
  
	.col-xlg-push-6 {
	  left: 50%;
	}
  
	.col-xlg-push-5 {
	  left: 41.66666667%;
	}
  
	.col-xlg-push-4 {
	  left: 33.33333333%;
	}
  
	.col-xlg-push-3 {
	  left: 25%;
	}
  
	.col-xlg-push-2 {
	  left: 16.66666667%;
	}
  
	.col-xlg-push-1 {
	  left: 8.33333333%;
	}
  
	.col-xlg-push-0 {
	  left: auto;
	}
  
	.offset-xlg-12 {
	  margin-left: 100%;
	}
  
	.offset-xlg-11 {
	  margin-left: 91.66666667%;
	}
  
	.offset-xlg-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-xlg-9 {
	  margin-left: 75%;
	}
  
	.offset-xlg-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-xlg-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-xlg-6 {
	  margin-left: 50%;
	}
  
	.offset-xlg-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-xlg-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-xlg-3 {
	  margin-left: 25%;
	}
  
	.offset-xlg-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-xlg-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-xlg-0 {
	  margin-left: 0;
	}
  }
  
  .col-xlg-1,
  .col-xlg-10,
  .col-xlg-11,
  .col-xlg-12,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
  }
  
  
.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
  }
  
  @media (min-width: 576px) {
	.container {
	  max-width: 540px;
	}
  }
  
  @media (min-width: 768px) {
	.container {
	  max-width: 720px;
	}
  }
  
  @media (min-width: 992px) {
	.container {
	  max-width: 960px;
	}
  }
  
  @media (min-width: 1200px) {
	.container {
	  max-width: 1140px;
	}
  }
  
  .container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
  }
  
  .no-gutters {
	margin-right: 0;
	margin-left: 0;
  }
  
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
  }
  
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
  }
  
  .col {
	-ms-flex-preferred-size: 0;
		flex-basis: 0;
	-webkit-box-flex: 1;
		-ms-flex-positive: 1;
			flex-grow: 1;
	max-width: 100%;
  }
  
  .col-auto {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
			flex: 0 0 auto;
	width: auto;
	max-width: none;
  }
  
  .col-1 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 8.33333333%;
			flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
  }
  
  .col-2 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66666667%;
			flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
  }
  
  .col-3 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
			flex: 0 0 25%;
	max-width: 25%;
  }
  
  .col-4 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333333%;
			flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
  }
  
  .col-5 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 41.66666667%;
			flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
  }
  
  .col-6 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
			flex: 0 0 50%;
	max-width: 50%;
  }
  
  .col-7 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 58.33333333%;
			flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
  }
  
  .col-8 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 66.66666667%;
			flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
  }
  
  .col-9 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
			flex: 0 0 75%;
	max-width: 75%;
  }
  
  .col-10 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 83.33333333%;
			flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
  }
  
  .col-11 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 91.66666667%;
			flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
  }
  
  .col-12 {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
			flex: 0 0 100%;
	max-width: 100%;
  }
  
  .order-first {
	-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
			order: -1;
  }
  
  .order-last {
	-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
			order: 13;
  }
  
  .order-0 {
	-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
			order: 0;
  }
  
  .order-1 {
	-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
			order: 1;
  }
  
  .order-2 {
	-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
			order: 2;
  }
  
  .order-3 {
	-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
			order: 3;
  }
  
  .order-4 {
	-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
			order: 4;
  }
  
  .order-5 {
	-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
			order: 5;
  }
  
  .order-6 {
	-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
			order: 6;
  }
  
  .order-7 {
	-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
			order: 7;
  }
  
  .order-8 {
	-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
			order: 8;
  }
  
  .order-9 {
	-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
			order: 9;
  }
  
  .order-10 {
	-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
			order: 10;
  }
  
  .order-11 {
	-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
			order: 11;
  }
  
  .order-12 {
	-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
			order: 12;
  }
  
  .offset-1 {
	margin-left: 8.33333333%;
  }
  
  .offset-2 {
	margin-left: 16.66666667%;
  }
  
  .offset-3 {
	margin-left: 25%;
  }
  
  .offset-4 {
	margin-left: 33.33333333%;
  }
  
  .offset-5 {
	margin-left: 41.66666667%;
  }
  
  .offset-6 {
	margin-left: 50%;
  }
  
  .offset-7 {
	margin-left: 58.33333333%;
  }
  
  .offset-8 {
	margin-left: 66.66666667%;
  }
  
  .offset-9 {
	margin-left: 75%;
  }
  
  .offset-10 {
	margin-left: 83.33333333%;
  }
  
  .offset-11 {
	margin-left: 91.66666667%;
  }
  
  @media (min-width: 576px) {
	.col-sm {
	  -ms-flex-preferred-size: 0;
		  flex-basis: 0;
	  -webkit-box-flex: 1;
		  -ms-flex-positive: 1;
			  flex-grow: 1;
	  max-width: 100%;
	}
  
	.col-sm-auto {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 auto;
			  flex: 0 0 auto;
	  width: auto;
	  max-width: none;
	}
  
	.col-sm-1 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 8.33333333%;
			  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-sm-2 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 16.66666667%;
			  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-sm-3 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 25%;
			  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-sm-4 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 33.33333333%;
			  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-sm-5 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 41.66666667%;
			  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-sm-6 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 50%;
			  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-sm-7 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 58.33333333%;
			  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-sm-8 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 66.66666667%;
			  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-sm-9 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 75%;
			  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-sm-10 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 83.33333333%;
			  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-sm-11 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 91.66666667%;
			  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-sm-12 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 100%;
			  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.order-sm-first {
	  -webkit-box-ordinal-group: 0;
		  -ms-flex-order: -1;
			  order: -1;
	}
  
	.order-sm-last {
	  -webkit-box-ordinal-group: 14;
		  -ms-flex-order: 13;
			  order: 13;
	}
  
	.order-sm-0 {
	  -webkit-box-ordinal-group: 1;
		  -ms-flex-order: 0;
			  order: 0;
	}
  
	.order-sm-1 {
	  -webkit-box-ordinal-group: 2;
		  -ms-flex-order: 1;
			  order: 1;
	}
  
	.order-sm-2 {
	  -webkit-box-ordinal-group: 3;
		  -ms-flex-order: 2;
			  order: 2;
	}
  
	.order-sm-3 {
	  -webkit-box-ordinal-group: 4;
		  -ms-flex-order: 3;
			  order: 3;
	}
  
	.order-sm-4 {
	  -webkit-box-ordinal-group: 5;
		  -ms-flex-order: 4;
			  order: 4;
	}
  
	.order-sm-5 {
	  -webkit-box-ordinal-group: 6;
		  -ms-flex-order: 5;
			  order: 5;
	}
  
	.order-sm-6 {
	  -webkit-box-ordinal-group: 7;
		  -ms-flex-order: 6;
			  order: 6;
	}
  
	.order-sm-7 {
	  -webkit-box-ordinal-group: 8;
		  -ms-flex-order: 7;
			  order: 7;
	}
  
	.order-sm-8 {
	  -webkit-box-ordinal-group: 9;
		  -ms-flex-order: 8;
			  order: 8;
	}
  
	.order-sm-9 {
	  -webkit-box-ordinal-group: 10;
		  -ms-flex-order: 9;
			  order: 9;
	}
  
	.order-sm-10 {
	  -webkit-box-ordinal-group: 11;
		  -ms-flex-order: 10;
			  order: 10;
	}
  
	.order-sm-11 {
	  -webkit-box-ordinal-group: 12;
		  -ms-flex-order: 11;
			  order: 11;
	}
  
	.order-sm-12 {
	  -webkit-box-ordinal-group: 13;
		  -ms-flex-order: 12;
			  order: 12;
	}
  
	.offset-sm-0 {
	  margin-left: 0;
	}
  
	.offset-sm-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-sm-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-sm-3 {
	  margin-left: 25%;
	}
  
	.offset-sm-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-sm-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-sm-6 {
	  margin-left: 50%;
	}
  
	.offset-sm-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-sm-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-sm-9 {
	  margin-left: 75%;
	}
  
	.offset-sm-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-sm-11 {
	  margin-left: 91.66666667%;
	}
  }
  
  @media (min-width: 768px) {
	.col-md {
	  -ms-flex-preferred-size: 0;
		  flex-basis: 0;
	  -webkit-box-flex: 1;
		  -ms-flex-positive: 1;
			  flex-grow: 1;
	  max-width: 100%;
	}
  
	.col-md-auto {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 auto;
			  flex: 0 0 auto;
	  width: auto;
	  max-width: none;
	}
  
	.col-md-1 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 8.33333333%;
			  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-md-2 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 16.66666667%;
			  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-md-3 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 25%;
			  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-md-4 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 33.33333333%;
			  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-md-5 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 41.66666667%;
			  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-md-6 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 50%;
			  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-md-7 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 58.33333333%;
			  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-md-8 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 66.66666667%;
			  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-md-9 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 75%;
			  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-md-10 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 83.33333333%;
			  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-md-11 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 91.66666667%;
			  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-md-12 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 100%;
			  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.order-md-first {
	  -webkit-box-ordinal-group: 0;
		  -ms-flex-order: -1;
			  order: -1;
	}
  
	.order-md-last {
	  -webkit-box-ordinal-group: 14;
		  -ms-flex-order: 13;
			  order: 13;
	}
  
	.order-md-0 {
	  -webkit-box-ordinal-group: 1;
		  -ms-flex-order: 0;
			  order: 0;
	}
  
	.order-md-1 {
	  -webkit-box-ordinal-group: 2;
		  -ms-flex-order: 1;
			  order: 1;
	}
  
	.order-md-2 {
	  -webkit-box-ordinal-group: 3;
		  -ms-flex-order: 2;
			  order: 2;
	}
  
	.order-md-3 {
	  -webkit-box-ordinal-group: 4;
		  -ms-flex-order: 3;
			  order: 3;
	}
  
	.order-md-4 {
	  -webkit-box-ordinal-group: 5;
		  -ms-flex-order: 4;
			  order: 4;
	}
  
	.order-md-5 {
	  -webkit-box-ordinal-group: 6;
		  -ms-flex-order: 5;
			  order: 5;
	}
  
	.order-md-6 {
	  -webkit-box-ordinal-group: 7;
		  -ms-flex-order: 6;
			  order: 6;
	}
  
	.order-md-7 {
	  -webkit-box-ordinal-group: 8;
		  -ms-flex-order: 7;
			  order: 7;
	}
  
	.order-md-8 {
	  -webkit-box-ordinal-group: 9;
		  -ms-flex-order: 8;
			  order: 8;
	}
  
	.order-md-9 {
	  -webkit-box-ordinal-group: 10;
		  -ms-flex-order: 9;
			  order: 9;
	}
  
	.order-md-10 {
	  -webkit-box-ordinal-group: 11;
		  -ms-flex-order: 10;
			  order: 10;
	}
  
	.order-md-11 {
	  -webkit-box-ordinal-group: 12;
		  -ms-flex-order: 11;
			  order: 11;
	}
  
	.order-md-12 {
	  -webkit-box-ordinal-group: 13;
		  -ms-flex-order: 12;
			  order: 12;
	}
  
	.offset-md-0 {
	  margin-left: 0;
	}
  
	.offset-md-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-md-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-md-3 {
	  margin-left: 25%;
	}
  
	.offset-md-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-md-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-md-6 {
	  margin-left: 50%;
	}
  
	.offset-md-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-md-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-md-9 {
	  margin-left: 75%;
	}
  
	.offset-md-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-md-11 {
	  margin-left: 91.66666667%;
	}
  }
  
  @media (min-width: 992px) {
	.col-lg {
	  -ms-flex-preferred-size: 0;
		  flex-basis: 0;
	  -webkit-box-flex: 1;
		  -ms-flex-positive: 1;
			  flex-grow: 1;
	  max-width: 100%;
	}
  
	.col-lg-auto {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 auto;
			  flex: 0 0 auto;
	  width: auto;
	  max-width: none;
	}
  
	.col-lg-1 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 8.33333333%;
			  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-lg-2 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 16.66666667%;
			  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-lg-3 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 25%;
			  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-lg-4 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 33.33333333%;
			  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-lg-5 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 41.66666667%;
			  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-lg-6 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 50%;
			  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-lg-7 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 58.33333333%;
			  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-lg-8 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 66.66666667%;
			  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-lg-9 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 75%;
			  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-lg-10 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 83.33333333%;
			  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-lg-11 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 91.66666667%;
			  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-lg-12 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 100%;
			  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.order-lg-first {
	  -webkit-box-ordinal-group: 0;
		  -ms-flex-order: -1;
			  order: -1;
	}
  
	.order-lg-last {
	  -webkit-box-ordinal-group: 14;
		  -ms-flex-order: 13;
			  order: 13;
	}
  
	.order-lg-0 {
	  -webkit-box-ordinal-group: 1;
		  -ms-flex-order: 0;
			  order: 0;
	}
  
	.order-lg-1 {
	  -webkit-box-ordinal-group: 2;
		  -ms-flex-order: 1;
			  order: 1;
	}
  
	.order-lg-2 {
	  -webkit-box-ordinal-group: 3;
		  -ms-flex-order: 2;
			  order: 2;
	}
  
	.order-lg-3 {
	  -webkit-box-ordinal-group: 4;
		  -ms-flex-order: 3;
			  order: 3;
	}
  
	.order-lg-4 {
	  -webkit-box-ordinal-group: 5;
		  -ms-flex-order: 4;
			  order: 4;
	}
  
	.order-lg-5 {
	  -webkit-box-ordinal-group: 6;
		  -ms-flex-order: 5;
			  order: 5;
	}
  
	.order-lg-6 {
	  -webkit-box-ordinal-group: 7;
		  -ms-flex-order: 6;
			  order: 6;
	}
  
	.order-lg-7 {
	  -webkit-box-ordinal-group: 8;
		  -ms-flex-order: 7;
			  order: 7;
	}
  
	.order-lg-8 {
	  -webkit-box-ordinal-group: 9;
		  -ms-flex-order: 8;
			  order: 8;
	}
  
	.order-lg-9 {
	  -webkit-box-ordinal-group: 10;
		  -ms-flex-order: 9;
			  order: 9;
	}
  
	.order-lg-10 {
	  -webkit-box-ordinal-group: 11;
		  -ms-flex-order: 10;
			  order: 10;
	}
  
	.order-lg-11 {
	  -webkit-box-ordinal-group: 12;
		  -ms-flex-order: 11;
			  order: 11;
	}
  
	.order-lg-12 {
	  -webkit-box-ordinal-group: 13;
		  -ms-flex-order: 12;
			  order: 12;
	}
  
	.offset-lg-0 {
	  margin-left: 0;
	}
  
	.offset-lg-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-lg-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-lg-3 {
	  margin-left: 25%;
	}
  
	.offset-lg-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-lg-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-lg-6 {
	  margin-left: 50%;
	}
  
	.offset-lg-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-lg-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-lg-9 {
	  margin-left: 75%;
	}
  
	.offset-lg-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-lg-11 {
	  margin-left: 91.66666667%;
	}
  }
  
  @media (min-width: 1200px) {
	.col-xl {
	  -ms-flex-preferred-size: 0;
		  flex-basis: 0;
	  -webkit-box-flex: 1;
		  -ms-flex-positive: 1;
			  flex-grow: 1;
	  max-width: 100%;
	}
  
	.col-xl-auto {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 auto;
			  flex: 0 0 auto;
	  width: auto;
	  max-width: none;
	}
  
	.col-xl-1 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 8.33333333%;
			  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-xl-2 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 16.66666667%;
			  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-xl-3 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 25%;
			  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-xl-4 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 33.33333333%;
			  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-xl-5 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 41.66666667%;
			  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-xl-6 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 50%;
			  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-xl-7 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 58.33333333%;
			  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-xl-8 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 66.66666667%;
			  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-xl-9 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 75%;
			  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-xl-10 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 83.33333333%;
			  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-xl-11 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 91.66666667%;
			  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-xl-12 {
	  -webkit-box-flex: 0;
		  -ms-flex: 0 0 100%;
			  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.order-xl-first {
	  -webkit-box-ordinal-group: 0;
		  -ms-flex-order: -1;
			  order: -1;
	}
  
	.order-xl-last {
	  -webkit-box-ordinal-group: 14;
		  -ms-flex-order: 13;
			  order: 13;
	}
  
	.order-xl-0 {
	  -webkit-box-ordinal-group: 1;
		  -ms-flex-order: 0;
			  order: 0;
	}
  
	.order-xl-1 {
	  -webkit-box-ordinal-group: 2;
		  -ms-flex-order: 1;
			  order: 1;
	}
  
	.order-xl-2 {
	  -webkit-box-ordinal-group: 3;
		  -ms-flex-order: 2;
			  order: 2;
	}
  
	.order-xl-3 {
	  -webkit-box-ordinal-group: 4;
		  -ms-flex-order: 3;
			  order: 3;
	}
  
	.order-xl-4 {
	  -webkit-box-ordinal-group: 5;
		  -ms-flex-order: 4;
			  order: 4;
	}
  
	.order-xl-5 {
	  -webkit-box-ordinal-group: 6;
		  -ms-flex-order: 5;
			  order: 5;
	}
  
	.order-xl-6 {
	  -webkit-box-ordinal-group: 7;
		  -ms-flex-order: 6;
			  order: 6;
	}
  
	.order-xl-7 {
	  -webkit-box-ordinal-group: 8;
		  -ms-flex-order: 7;
			  order: 7;
	}
  
	.order-xl-8 {
	  -webkit-box-ordinal-group: 9;
		  -ms-flex-order: 8;
			  order: 8;
	}
  
	.order-xl-9 {
	  -webkit-box-ordinal-group: 10;
		  -ms-flex-order: 9;
			  order: 9;
	}
  
	.order-xl-10 {
	  -webkit-box-ordinal-group: 11;
		  -ms-flex-order: 10;
			  order: 10;
	}
  
	.order-xl-11 {
	  -webkit-box-ordinal-group: 12;
		  -ms-flex-order: 11;
			  order: 11;
	}
  
	.order-xl-12 {
	  -webkit-box-ordinal-group: 13;
		  -ms-flex-order: 12;
			  order: 12;
	}
  
	.offset-xl-0 {
	  margin-left: 0;
	}
  
	.offset-xl-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-xl-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-xl-3 {
	  margin-left: 25%;
	}
  
	.offset-xl-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-xl-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-xl-6 {
	  margin-left: 50%;
	}
  
	.offset-xl-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-xl-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-xl-9 {
	  margin-left: 75%;
	}
  
	.offset-xl-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-xl-11 {
	  margin-left: 91.66666667%;
	}
  }

  .d-none {
	display: none !important;
  }
  
  .d-inline {
	display: inline !important;
  }
  
  .d-inline-block {
	display: inline-block !important;
  }
  
  .d-block {
	display: block !important;
  }
  
  .d-table {
	display: table !important;
  }
  
  .d-table-row {
	display: table-row !important;
  }
  
  .d-table-cell {
	display: table-cell !important;
  }
  
  .d-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
  }
  
  .d-inline-flex {
	display: -webkit-inline-box !important;
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
  }
  
  .row-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

  @media (min-width: 576px) {
	.d-sm-none {
	  display: none !important;
	}
  
	.d-sm-inline {
	  display: inline !important;
	}
  
	.d-sm-inline-block {
	  display: inline-block !important;
	}
  
	.d-sm-block {
	  display: block !important;
	}
  
	.d-sm-table {
	  display: table !important;
	}
  
	.d-sm-table-row {
	  display: table-row !important;
	}
  
	.d-sm-table-cell {
	  display: table-cell !important;
	}
  
	.d-sm-flex {
	  display: -webkit-box !important;
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-sm-inline-flex {
	  display: -webkit-inline-box !important;
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  @media (min-width: 768px) {
	.d-md-none {
	  display: none !important;
	}
  
	.d-md-inline {
	  display: inline !important;
	}
  
	.d-md-inline-block {
	  display: inline-block !important;
	}
  
	.d-md-block {
	  display: block !important;
	}
  
	.d-md-table {
	  display: table !important;
	}
  
	.d-md-table-row {
	  display: table-row !important;
	}
  
	.d-md-table-cell {
	  display: table-cell !important;
	}
  
	.d-md-flex {
	  display: -webkit-box !important;
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-md-inline-flex {
	  display: -webkit-inline-box !important;
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  @media (min-width: 992px) {
	.d-lg-none {
	  display: none !important;
	}
  
	.d-lg-inline {
	  display: inline !important;
	}
  
	.d-lg-inline-block {
	  display: inline-block !important;
	}
  
	.d-lg-block {
	  display: block !important;
	}
  
	.d-lg-table {
	  display: table !important;
	}
  
	.d-lg-table-row {
	  display: table-row !important;
	}
  
	.d-lg-table-cell {
	  display: table-cell !important;
	}
  
	.d-lg-flex {
	  display: -webkit-box !important;
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-lg-inline-flex {
	  display: -webkit-inline-box !important;
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  @media (min-width: 1200px) {
	.d-xl-none {
	  display: none !important;
	}
  
	.d-xl-inline {
	  display: inline !important;
	}
  
	.d-xl-inline-block {
	  display: inline-block !important;
	}
  
	.d-xl-block {
	  display: block !important;
	}
  
	.d-xl-table {
	  display: table !important;
	}
  
	.d-xl-table-row {
	  display: table-row !important;
	}
  
	.d-xl-table-cell {
	  display: table-cell !important;
	}
  
	.d-xl-flex {
	  display: -webkit-box !important;
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-xl-inline-flex {
	  display: -webkit-inline-box !important;
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  @media print {
	.d-print-none {
	  display: none !important;
	}
  
	.d-print-inline {
	  display: inline !important;
	}
  
	.d-print-inline-block {
	  display: inline-block !important;
	}
  
	.d-print-block {
	  display: block !important;
	}
  
	.d-print-table {
	  display: table !important;
	}
  
	.d-print-table-row {
	  display: table-row !important;
	}
  
	.d-print-table-cell {
	  display: table-cell !important;
	}
  
	.d-print-flex {
	  display: -webkit-box !important;
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-print-inline-flex {
	  display: -webkit-inline-box !important;
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  .embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
  }
  
  .embed-responsive::before {
	display: block;
	content: "";
  }
  
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
  }
  
  .embed-responsive-21by9::before {
	padding-top: 42.85714286%;
  }
  
  .embed-responsive-16by9::before {
	padding-top: 56.25%;
  }
  
  .embed-responsive-4by3::before {
	padding-top: 75%;
  }
  
  .embed-responsive-1by1::before {
	padding-top: 100%;
  }
  
  .flex-row {
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: normal !important;
		-ms-flex-direction: row !important;
			flex-direction: row !important;
  }
  
  .flex-column {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: normal !important;
		-ms-flex-direction: column !important;
			flex-direction: column !important;
  }
  
  .flex-row-reverse {
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: reverse !important;
		-ms-flex-direction: row-reverse !important;
			flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: reverse !important;
		-ms-flex-direction: column-reverse !important;
			flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
	-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
	-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
  }
  
  .flex-fill {
	-webkit-box-flex: 1 !important;
		-ms-flex: 1 1 auto !important;
			flex: 1 1 auto !important;
  }
  
  .flex-grow-0 {
	-webkit-box-flex: 0 !important;
		-ms-flex-positive: 0 !important;
			flex-grow: 0 !important;
  }
  
  .flex-grow-1 {
	-webkit-box-flex: 1 !important;
		-ms-flex-positive: 1 !important;
			flex-grow: 1 !important;
  }
  
  .flex-shrink-0 {
	-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
  }
  
  .flex-shrink-1 {
	-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
  }
  
  .justify-content-start {
	-webkit-box-pack: start !important;
		-ms-flex-pack: start !important;
			justify-content: flex-start !important;
  }
  
  .justify-content-end {
	-webkit-box-pack: end !important;
		-ms-flex-pack: end !important;
			justify-content: flex-end !important;
  }
  
  .justify-content-center {
	-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
			justify-content: center !important;
  }
  
  .justify-content-between {
	-webkit-box-pack: justify !important;
		-ms-flex-pack: justify !important;
			justify-content: space-between !important;
  }
  
  .justify-content-around {
	-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
  }
  
  .align-items-start {
	-webkit-box-align: start !important;
		-ms-flex-align: start !important;
			align-items: flex-start !important;
  }
  
  .align-items-end {
	-webkit-box-align: end !important;
		-ms-flex-align: end !important;
			align-items: flex-end !important;
  }
  
  .align-items-center {
	-webkit-box-align: center !important;
		-ms-flex-align: center !important;
			align-items: center !important;
  }
  
  .align-items-baseline {
	-webkit-box-align: baseline !important;
		-ms-flex-align: baseline !important;
			align-items: baseline !important;
  }
  
  .align-items-stretch {
	-webkit-box-align: stretch !important;
		-ms-flex-align: stretch !important;
			align-items: stretch !important;
  }
  
  .align-content-start {
	-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
  }
  
  .align-content-end {
	-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
  }
  
  .align-content-center {
	-ms-flex-line-pack: center !important;
		align-content: center !important;
  }
  
  .align-content-between {
	-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
  }
  
  .align-content-around {
	-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
  }
  
  .align-content-stretch {
	-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
  }
  
  .align-self-auto {
	-ms-flex-item-align: auto !important;
		align-self: auto !important;
  }
  
  .align-self-start {
	-ms-flex-item-align: start !important;
		align-self: flex-start !important;
  }
  
  .align-self-end {
	-ms-flex-item-align: end !important;
		align-self: flex-end !important;
  }
  
  .align-self-center {
	-ms-flex-item-align: center !important;
		align-self: center !important;
  }
  
  .align-self-baseline {
	-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
  }
  
  .align-self-stretch {
	-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
	.flex-sm-row {
	  -webkit-box-orient: horizontal !important;
	  -webkit-box-direction: normal !important;
		  -ms-flex-direction: row !important;
			  flex-direction: row !important;
	}
  
	.flex-sm-column {
	  -webkit-box-orient: vertical !important;
	  -webkit-box-direction: normal !important;
		  -ms-flex-direction: column !important;
			  flex-direction: column !important;
	}
  
	.flex-sm-row-reverse {
	  -webkit-box-orient: horizontal !important;
	  -webkit-box-direction: reverse !important;
		  -ms-flex-direction: row-reverse !important;
			  flex-direction: row-reverse !important;
	}
  
	.flex-sm-column-reverse {
	  -webkit-box-orient: vertical !important;
	  -webkit-box-direction: reverse !important;
		  -ms-flex-direction: column-reverse !important;
			  flex-direction: column-reverse !important;
	}
  
	.flex-sm-wrap {
	  -ms-flex-wrap: wrap !important;
		  flex-wrap: wrap !important;
	}
  
	.flex-sm-nowrap {
	  -ms-flex-wrap: nowrap !important;
		  flex-wrap: nowrap !important;
	}
  
	.flex-sm-wrap-reverse {
	  -ms-flex-wrap: wrap-reverse !important;
		  flex-wrap: wrap-reverse !important;
	}
  
	.flex-sm-fill {
	  -webkit-box-flex: 1 !important;
		  -ms-flex: 1 1 auto !important;
			  flex: 1 1 auto !important;
	}
  
	.flex-sm-grow-0 {
	  -webkit-box-flex: 0 !important;
		  -ms-flex-positive: 0 !important;
			  flex-grow: 0 !important;
	}
  
	.flex-sm-grow-1 {
	  -webkit-box-flex: 1 !important;
		  -ms-flex-positive: 1 !important;
			  flex-grow: 1 !important;
	}
  
	.flex-sm-shrink-0 {
	  -ms-flex-negative: 0 !important;
		  flex-shrink: 0 !important;
	}
  
	.flex-sm-shrink-1 {
	  -ms-flex-negative: 1 !important;
		  flex-shrink: 1 !important;
	}
  
	.justify-content-sm-start {
	  -webkit-box-pack: start !important;
		  -ms-flex-pack: start !important;
			  justify-content: flex-start !important;
	}
  
	.justify-content-sm-end {
	  -webkit-box-pack: end !important;
		  -ms-flex-pack: end !important;
			  justify-content: flex-end !important;
	}
  
	.justify-content-sm-center {
	  -webkit-box-pack: center !important;
		  -ms-flex-pack: center !important;
			  justify-content: center !important;
	}
  
	.justify-content-sm-between {
	  -webkit-box-pack: justify !important;
		  -ms-flex-pack: justify !important;
			  justify-content: space-between !important;
	}
  
	.justify-content-sm-around {
	  -ms-flex-pack: distribute !important;
		  justify-content: space-around !important;
	}
  
	.align-items-sm-start {
	  -webkit-box-align: start !important;
		  -ms-flex-align: start !important;
			  align-items: flex-start !important;
	}
  
	.align-items-sm-end {
	  -webkit-box-align: end !important;
		  -ms-flex-align: end !important;
			  align-items: flex-end !important;
	}
  
	.align-items-sm-center {
	  -webkit-box-align: center !important;
		  -ms-flex-align: center !important;
			  align-items: center !important;
	}
  
	.align-items-sm-baseline {
	  -webkit-box-align: baseline !important;
		  -ms-flex-align: baseline !important;
			  align-items: baseline !important;
	}
  
	.align-items-sm-stretch {
	  -webkit-box-align: stretch !important;
		  -ms-flex-align: stretch !important;
			  align-items: stretch !important;
	}
  
	.align-content-sm-start {
	  -ms-flex-line-pack: start !important;
		  align-content: flex-start !important;
	}
  
	.align-content-sm-end {
	  -ms-flex-line-pack: end !important;
		  align-content: flex-end !important;
	}
  
	.align-content-sm-center {
	  -ms-flex-line-pack: center !important;
		  align-content: center !important;
	}
  
	.align-content-sm-between {
	  -ms-flex-line-pack: justify !important;
		  align-content: space-between !important;
	}
  
	.align-content-sm-around {
	  -ms-flex-line-pack: distribute !important;
		  align-content: space-around !important;
	}
  
	.align-content-sm-stretch {
	  -ms-flex-line-pack: stretch !important;
		  align-content: stretch !important;
	}
  
	.align-self-sm-auto {
	  -ms-flex-item-align: auto !important;
		  align-self: auto !important;
	}
  
	.align-self-sm-start {
	  -ms-flex-item-align: start !important;
		  align-self: flex-start !important;
	}
  
	.align-self-sm-end {
	  -ms-flex-item-align: end !important;
		  align-self: flex-end !important;
	}
  
	.align-self-sm-center {
	  -ms-flex-item-align: center !important;
		  align-self: center !important;
	}
  
	.align-self-sm-baseline {
	  -ms-flex-item-align: baseline !important;
		  align-self: baseline !important;
	}
  
	.align-self-sm-stretch {
	  -ms-flex-item-align: stretch !important;
		  align-self: stretch !important;
	}
  }
  
  @media (min-width: 768px) {
	.flex-md-row {
	  -webkit-box-orient: horizontal !important;
	  -webkit-box-direction: normal !important;
		  -ms-flex-direction: row !important;
			  flex-direction: row !important;
	}
  
	.flex-md-column {
	  -webkit-box-orient: vertical !important;
	  -webkit-box-direction: normal !important;
		  -ms-flex-direction: column !important;
			  flex-direction: column !important;
	}
  
	.flex-md-row-reverse {
	  -webkit-box-orient: horizontal !important;
	  -webkit-box-direction: reverse !important;
		  -ms-flex-direction: row-reverse !important;
			  flex-direction: row-reverse !important;
	}
  
	.flex-md-column-reverse {
	  -webkit-box-orient: vertical !important;
	  -webkit-box-direction: reverse !important;
		  -ms-flex-direction: column-reverse !important;
			  flex-direction: column-reverse !important;
	}
  
	.flex-md-wrap {
	  -ms-flex-wrap: wrap !important;
		  flex-wrap: wrap !important;
	}
  
	.flex-md-nowrap {
	  -ms-flex-wrap: nowrap !important;
		  flex-wrap: nowrap !important;
	}
  
	.flex-md-wrap-reverse {
	  -ms-flex-wrap: wrap-reverse !important;
		  flex-wrap: wrap-reverse !important;
	}
  
	.flex-md-fill {
	  -webkit-box-flex: 1 !important;
		  -ms-flex: 1 1 auto !important;
			  flex: 1 1 auto !important;
	}
  
	.flex-md-grow-0 {
	  -webkit-box-flex: 0 !important;
		  -ms-flex-positive: 0 !important;
			  flex-grow: 0 !important;
	}
  
	.flex-md-grow-1 {
	  -webkit-box-flex: 1 !important;
		  -ms-flex-positive: 1 !important;
			  flex-grow: 1 !important;
	}
  
	.flex-md-shrink-0 {
	  -ms-flex-negative: 0 !important;
		  flex-shrink: 0 !important;
	}
  
	.flex-md-shrink-1 {
	  -ms-flex-negative: 1 !important;
		  flex-shrink: 1 !important;
	}
  
	.justify-content-md-start {
	  -webkit-box-pack: start !important;
		  -ms-flex-pack: start !important;
			  justify-content: flex-start !important;
	}
  
	.justify-content-md-end {
	  -webkit-box-pack: end !important;
		  -ms-flex-pack: end !important;
			  justify-content: flex-end !important;
	}
  
	.justify-content-md-center {
	  -webkit-box-pack: center !important;
		  -ms-flex-pack: center !important;
			  justify-content: center !important;
	}
  
	.justify-content-md-between {
	  -webkit-box-pack: justify !important;
		  -ms-flex-pack: justify !important;
			  justify-content: space-between !important;
	}
  
	.justify-content-md-around {
	  -ms-flex-pack: distribute !important;
		  justify-content: space-around !important;
	}
  
	.align-items-md-start {
	  -webkit-box-align: start !important;
		  -ms-flex-align: start !important;
			  align-items: flex-start !important;
	}
  
	.align-items-md-end {
	  -webkit-box-align: end !important;
		  -ms-flex-align: end !important;
			  align-items: flex-end !important;
	}
  
	.align-items-md-center {
	  -webkit-box-align: center !important;
		  -ms-flex-align: center !important;
			  align-items: center !important;
	}
  
	.align-items-md-baseline {
	  -webkit-box-align: baseline !important;
		  -ms-flex-align: baseline !important;
			  align-items: baseline !important;
	}
  
	.align-items-md-stretch {
	  -webkit-box-align: stretch !important;
		  -ms-flex-align: stretch !important;
			  align-items: stretch !important;
	}
  
	.align-content-md-start {
	  -ms-flex-line-pack: start !important;
		  align-content: flex-start !important;
	}
  
	.align-content-md-end {
	  -ms-flex-line-pack: end !important;
		  align-content: flex-end !important;
	}
  
	.align-content-md-center {
	  -ms-flex-line-pack: center !important;
		  align-content: center !important;
	}
  
	.align-content-md-between {
	  -ms-flex-line-pack: justify !important;
		  align-content: space-between !important;
	}
  
	.align-content-md-around {
	  -ms-flex-line-pack: distribute !important;
		  align-content: space-around !important;
	}
  
	.align-content-md-stretch {
	  -ms-flex-line-pack: stretch !important;
		  align-content: stretch !important;
	}
  
	.align-self-md-auto {
	  -ms-flex-item-align: auto !important;
		  align-self: auto !important;
	}
  
	.align-self-md-start {
	  -ms-flex-item-align: start !important;
		  align-self: flex-start !important;
	}
  
	.align-self-md-end {
	  -ms-flex-item-align: end !important;
		  align-self: flex-end !important;
	}
  
	.align-self-md-center {
	  -ms-flex-item-align: center !important;
		  align-self: center !important;
	}
  
	.align-self-md-baseline {
	  -ms-flex-item-align: baseline !important;
		  align-self: baseline !important;
	}
  
	.align-self-md-stretch {
	  -ms-flex-item-align: stretch !important;
		  align-self: stretch !important;
	}
  }
  
  @media (min-width: 992px) {
	.flex-lg-row {
	  -webkit-box-orient: horizontal !important;
	  -webkit-box-direction: normal !important;
		  -ms-flex-direction: row !important;
			  flex-direction: row !important;
	}
  
	.flex-lg-column {
	  -webkit-box-orient: vertical !important;
	  -webkit-box-direction: normal !important;
		  -ms-flex-direction: column !important;
			  flex-direction: column !important;
	}
  
	.flex-lg-row-reverse {
	  -webkit-box-orient: horizontal !important;
	  -webkit-box-direction: reverse !important;
		  -ms-flex-direction: row-reverse !important;
			  flex-direction: row-reverse !important;
	}
  
	.flex-lg-column-reverse {
	  -webkit-box-orient: vertical !important;
	  -webkit-box-direction: reverse !important;
		  -ms-flex-direction: column-reverse !important;
			  flex-direction: column-reverse !important;
	}
  
	.flex-lg-wrap {
	  -ms-flex-wrap: wrap !important;
		  flex-wrap: wrap !important;
	}
  
	.flex-lg-nowrap {
	  -ms-flex-wrap: nowrap !important;
		  flex-wrap: nowrap !important;
	}
  
	.flex-lg-wrap-reverse {
	  -ms-flex-wrap: wrap-reverse !important;
		  flex-wrap: wrap-reverse !important;
	}
  
	.flex-lg-fill {
	  -webkit-box-flex: 1 !important;
		  -ms-flex: 1 1 auto !important;
			  flex: 1 1 auto !important;
	}
  
	.flex-lg-grow-0 {
	  -webkit-box-flex: 0 !important;
		  -ms-flex-positive: 0 !important;
			  flex-grow: 0 !important;
	}
  
	.flex-lg-grow-1 {
	  -webkit-box-flex: 1 !important;
		  -ms-flex-positive: 1 !important;
			  flex-grow: 1 !important;
	}
  
	.flex-lg-shrink-0 {
	  -ms-flex-negative: 0 !important;
		  flex-shrink: 0 !important;
	}
  
	.flex-lg-shrink-1 {
	  -ms-flex-negative: 1 !important;
		  flex-shrink: 1 !important;
	}
  
	.justify-content-lg-start {
	  -webkit-box-pack: start !important;
		  -ms-flex-pack: start !important;
			  justify-content: flex-start !important;
	}
  
	.justify-content-lg-end {
	  -webkit-box-pack: end !important;
		  -ms-flex-pack: end !important;
			  justify-content: flex-end !important;
	}
  
	.justify-content-lg-center {
	  -webkit-box-pack: center !important;
		  -ms-flex-pack: center !important;
			  justify-content: center !important;
	}
  
	.justify-content-lg-between {
	  -webkit-box-pack: justify !important;
		  -ms-flex-pack: justify !important;
			  justify-content: space-between !important;
	}
  
	.justify-content-lg-around {
	  -ms-flex-pack: distribute !important;
		  justify-content: space-around !important;
	}
  
	.align-items-lg-start {
	  -webkit-box-align: start !important;
		  -ms-flex-align: start !important;
			  align-items: flex-start !important;
	}
  
	.align-items-lg-end {
	  -webkit-box-align: end !important;
		  -ms-flex-align: end !important;
			  align-items: flex-end !important;
	}
  
	.align-items-lg-center {
	  -webkit-box-align: center !important;
		  -ms-flex-align: center !important;
			  align-items: center !important;
	}
  
	.align-items-lg-baseline {
	  -webkit-box-align: baseline !important;
		  -ms-flex-align: baseline !important;
			  align-items: baseline !important;
	}
  
	.align-items-lg-stretch {
	  -webkit-box-align: stretch !important;
		  -ms-flex-align: stretch !important;
			  align-items: stretch !important;
	}
  
	.align-content-lg-start {
	  -ms-flex-line-pack: start !important;
		  align-content: flex-start !important;
	}
  
	.align-content-lg-end {
	  -ms-flex-line-pack: end !important;
		  align-content: flex-end !important;
	}
  
	.align-content-lg-center {
	  -ms-flex-line-pack: center !important;
		  align-content: center !important;
	}
  
	.align-content-lg-between {
	  -ms-flex-line-pack: justify !important;
		  align-content: space-between !important;
	}
  
	.align-content-lg-around {
	  -ms-flex-line-pack: distribute !important;
		  align-content: space-around !important;
	}
  
	.align-content-lg-stretch {
	  -ms-flex-line-pack: stretch !important;
		  align-content: stretch !important;
	}
  
	.align-self-lg-auto {
	  -ms-flex-item-align: auto !important;
		  align-self: auto !important;
	}
  
	.align-self-lg-start {
	  -ms-flex-item-align: start !important;
		  align-self: flex-start !important;
	}
  
	.align-self-lg-end {
	  -ms-flex-item-align: end !important;
		  align-self: flex-end !important;
	}
  
	.align-self-lg-center {
	  -ms-flex-item-align: center !important;
		  align-self: center !important;
	}
  
	.align-self-lg-baseline {
	  -ms-flex-item-align: baseline !important;
		  align-self: baseline !important;
	}
  
	.align-self-lg-stretch {
	  -ms-flex-item-align: stretch !important;
		  align-self: stretch !important;
	}
  }
  
  @media (min-width: 1200px) {
	.flex-xl-row {
	  -webkit-box-orient: horizontal !important;
	  -webkit-box-direction: normal !important;
		  -ms-flex-direction: row !important;
			  flex-direction: row !important;
	}
  
	.flex-xl-column {
	  -webkit-box-orient: vertical !important;
	  -webkit-box-direction: normal !important;
		  -ms-flex-direction: column !important;
			  flex-direction: column !important;
	}
  
	.flex-xl-row-reverse {
	  -webkit-box-orient: horizontal !important;
	  -webkit-box-direction: reverse !important;
		  -ms-flex-direction: row-reverse !important;
			  flex-direction: row-reverse !important;
	}
  
	.flex-xl-column-reverse {
	  -webkit-box-orient: vertical !important;
	  -webkit-box-direction: reverse !important;
		  -ms-flex-direction: column-reverse !important;
			  flex-direction: column-reverse !important;
	}
  
	.flex-xl-wrap {
	  -ms-flex-wrap: wrap !important;
		  flex-wrap: wrap !important;
	}
  
	.flex-xl-nowrap {
	  -ms-flex-wrap: nowrap !important;
		  flex-wrap: nowrap !important;
	}
  
	.flex-xl-wrap-reverse {
	  -ms-flex-wrap: wrap-reverse !important;
		  flex-wrap: wrap-reverse !important;
	}
  
	.flex-xl-fill {
	  -webkit-box-flex: 1 !important;
		  -ms-flex: 1 1 auto !important;
			  flex: 1 1 auto !important;
	}
  
	.flex-xl-grow-0 {
	  -webkit-box-flex: 0 !important;
		  -ms-flex-positive: 0 !important;
			  flex-grow: 0 !important;
	}
  
	.flex-xl-grow-1 {
	  -webkit-box-flex: 1 !important;
		  -ms-flex-positive: 1 !important;
			  flex-grow: 1 !important;
	}
  
	.flex-xl-shrink-0 {
	  -ms-flex-negative: 0 !important;
		  flex-shrink: 0 !important;
	}
  
	.flex-xl-shrink-1 {
	  -ms-flex-negative: 1 !important;
		  flex-shrink: 1 !important;
	}
  
	.justify-content-xl-start {
	  -webkit-box-pack: start !important;
		  -ms-flex-pack: start !important;
			  justify-content: flex-start !important;
	}
  
	.justify-content-xl-end {
	  -webkit-box-pack: end !important;
		  -ms-flex-pack: end !important;
			  justify-content: flex-end !important;
	}
  
	.justify-content-xl-center {
	  -webkit-box-pack: center !important;
		  -ms-flex-pack: center !important;
			  justify-content: center !important;
	}
  
	.justify-content-xl-between {
	  -webkit-box-pack: justify !important;
		  -ms-flex-pack: justify !important;
			  justify-content: space-between !important;
	}
  
	.justify-content-xl-around {
	  -ms-flex-pack: distribute !important;
		  justify-content: space-around !important;
	}
  
	.align-items-xl-start {
	  -webkit-box-align: start !important;
		  -ms-flex-align: start !important;
			  align-items: flex-start !important;
	}
  
	.align-items-xl-end {
	  -webkit-box-align: end !important;
		  -ms-flex-align: end !important;
			  align-items: flex-end !important;
	}
  
	.align-items-xl-center {
	  -webkit-box-align: center !important;
		  -ms-flex-align: center !important;
			  align-items: center !important;
	}
  
	.align-items-xl-baseline {
	  -webkit-box-align: baseline !important;
		  -ms-flex-align: baseline !important;
			  align-items: baseline !important;
	}
  
	.align-items-xl-stretch {
	  -webkit-box-align: stretch !important;
		  -ms-flex-align: stretch !important;
			  align-items: stretch !important;
	}
  
	.align-content-xl-start {
	  -ms-flex-line-pack: start !important;
		  align-content: flex-start !important;
	}
  
	.align-content-xl-end {
	  -ms-flex-line-pack: end !important;
		  align-content: flex-end !important;
	}
  
	.align-content-xl-center {
	  -ms-flex-line-pack: center !important;
		  align-content: center !important;
	}
  
	.align-content-xl-between {
	  -ms-flex-line-pack: justify !important;
		  align-content: space-between !important;
	}
  
	.align-content-xl-around {
	  -ms-flex-line-pack: distribute !important;
		  align-content: space-around !important;
	}
  
	.align-content-xl-stretch {
	  -ms-flex-line-pack: stretch !important;
		  align-content: stretch !important;
	}
  
	.align-self-xl-auto {
	  -ms-flex-item-align: auto !important;
		  align-self: auto !important;
	}
  
	.align-self-xl-start {
	  -ms-flex-item-align: start !important;
		  align-self: flex-start !important;
	}
  
	.align-self-xl-end {
	  -ms-flex-item-align: end !important;
		  align-self: flex-end !important;
	}
  
	.align-self-xl-center {
	  -ms-flex-item-align: center !important;
		  align-self: center !important;
	}
  
	.align-self-xl-baseline {
	  -ms-flex-item-align: baseline !important;
		  align-self: baseline !important;
	}
  
	.align-self-xl-stretch {
	  -ms-flex-item-align: stretch !important;
		  align-self: stretch !important;
	}
  }
  
  .float-left {
	float: left !important;
  }
  
  .float-right {
	float: right !important;
  }
  
  .float-none {
	float: none !important;
  }
  
  @media (min-width: 576px) {
	.float-sm-left {
	  float: left !important;
	}
  
	.float-sm-right {
	  float: right !important;
	}
  
	.float-sm-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 768px) {
	.float-md-left {
	  float: left !important;
	}
  
	.float-md-right {
	  float: right !important;
	}
  
	.float-md-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 992px) {
	.float-lg-left {
	  float: left !important;
	}
  
	.float-lg-right {
	  float: right !important;
	}
  
	.float-lg-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 1200px) {
	.float-xl-left {
	  float: left !important;
	}
  
	.float-xl-right {
	  float: right !important;
	}
  
	.float-xl-none {
	  float: none !important;
	}
  }
  
  .position-static {
	position: static !important;
  }
  
  .position-relative {
	position: relative !important;
  }
  
  .position-absolute {
	position: absolute !important;
  }
  
  .position-fixed {
	position: fixed !important;
  }
  
  .position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
  }
  
  .fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
  }
  
  .fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
  }
  
  @supports ((position: -webkit-sticky) or (position: sticky)) {
	.sticky-top {
	  position: -webkit-sticky;
	  position: sticky;
	  top: 0;
	  z-index: 1020;
	}
  }
  
  .sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
  }
  
  .sr-only-focusable:active,
  .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
  }
  
  .shadow-sm {
	-webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
			box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  
  .shadow {
	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  
  .shadow-lg {
	-webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
			box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  
  .shadow-none {
	-webkit-box-shadow: none !important;
			box-shadow: none !important;
  }
  
.w-25 {
	width: 25% !important;
  }
  
  .w-50 {
	width: 50% !important;
  }
  
  .w-75 {
	width: 75% !important;
  }
  
  .w-100 {
	width: 100% !important;
  }
  
  .w-auto {
	width: auto !important;
  }
  
  .h-25 {
	height: 25% !important;
  }
  
  .h-50 {
	height: 50% !important;
  }
  
  .h-75 {
	height: 75% !important;
  }
  
  .h-100 {
	height: 100% !important;
  }
  
  .h-auto {
	height: auto !important;
  }
  
  .mw-100 {
	max-width: 100% !important;
  }
  
  .mh-100 {
	max-height: 100% !important;
  }
  
  .m-0 {
	margin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
	margin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
	margin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
	margin-bottom: 0 !important;
  }
  
  .ml-0,
  .mx-0 {
	margin-left: 0 !important;
  }
  
  .m-1 {
	margin: 0.25rem !important;
  }
  
  .mt-1,
  .my-1 {
	margin-top: 0.25rem !important;
  }
  
  .mr-1,
  .mx-1 {
	margin-right: 0.25rem !important;
  }
  
  .mb-1,
  .my-1 {
	margin-bottom: 0.25rem !important;
  }
  
  .ml-1,
  .mx-1 {
	margin-left: 0.25rem !important;
  }
  
  .m-2 {
	margin: 0.5rem !important;
  }
  
  .mt-2,
  .my-2 {
	margin-top: 0.5rem !important;
  }
  
  .mr-2,
  .mx-2 {
	margin-right: 0.5rem !important;
  }
  
  .mb-2,
  .my-2 {
	margin-bottom: 0.5rem !important;
  }
  
  .ml-2,
  .mx-2 {
	margin-left: 0.5rem !important;
  }
  
  .m-3 {
	margin: 1rem !important;
  }
  
  .mt-3,
  .my-3 {
	margin-top: 1rem !important;
  }
  
  .mr-3,
  .mx-3 {
	margin-right: 1rem !important;
  }
  
  .mb-3,
  .my-3 {
	margin-bottom: 1rem !important;
  }
  
  .ml-3,
  .mx-3 {
	margin-left: 1rem !important;
  }
  
  .m-4 {
	margin: 1.5rem !important;
  }
  
  .mt-4,
  .my-4 {
	margin-top: 1.5rem !important;
  }
  
  .mr-4,
  .mx-4 {
	margin-right: 1.5rem !important;
  }
  
  .mb-4,
  .my-4 {
	margin-bottom: 1.5rem !important;
  }
  
  .ml-4,
  .mx-4 {
	margin-left: 1.5rem !important;
  }
  
  .m-5 {
	margin: 3rem !important;
  }
  
  .mt-5,
  .my-5 {
	margin-top: 3rem !important;
  }
  
  .mr-5,
  .mx-5 {
	margin-right: 3rem !important;
  }
  
  .mb-5,
  .my-5 {
	margin-bottom: 3rem !important;
  }
  
  .ml-5,
  .mx-5 {
	margin-left: 3rem !important;
  }
  
  .p-0 {
	padding: 0 !important;
  }
  
  .pt-0,
  .py-0 {
	padding-top: 0 !important;
  }
  
  .pr-0,
  .px-0 {
	padding-right: 0 !important;
  }
  
  .pb-0,
  .py-0 {
	padding-bottom: 0 !important;
  }
  
  .pl-0,
  .px-0 {
	padding-left: 0 !important;
  }
  
  .p-1 {
	padding: 0.25rem !important;
  }
  
  .pt-1,
  .py-1 {
	padding-top: 0.25rem !important;
  }
  
  .pr-1,
  .px-1 {
	padding-right: 0.25rem !important;
  }
  
  .pb-1,
  .py-1 {
	padding-bottom: 0.25rem !important;
  }
  
  .pl-1,
  .px-1 {
	padding-left: 0.25rem !important;
  }
  
  .p-2 {
	padding: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
	padding-top: 0.5rem !important;
  }
  
  .pr-2,
  .px-2 {
	padding-right: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
	padding-bottom: 0.5rem !important;
  }
  
  .pl-2,
  .px-2 {
	padding-left: 0.5rem !important;
  }
  
  .p-3 {
	padding: 1rem !important;
  }
  
  .pt-3,
  .py-3 {
	padding-top: 1rem !important;
  }
  
  .pr-3,
  .px-3 {
	padding-right: 1rem !important;
  }
  
  .pb-3,
  .py-3 {
	padding-bottom: 1rem !important;
  }
  
  .pl-3,
  .px-3 {
	padding-left: 1rem !important;
  }
  
  .p-4 {
	padding: 1.5rem !important;
  }
  
  .pt-4,
  .py-4 {
	padding-top: 1.5rem !important;
  }
  
  .pr-4,
  .px-4 {
	padding-right: 1.5rem !important;
  }
  
  .pb-4,
  .py-4 {
	padding-bottom: 1.5rem !important;
  }
  
  .pl-4,
  .px-4 {
	padding-left: 1.5rem !important;
  }
  
  .p-5 {
	padding: 3rem !important;
  }
  
  .pt-5,
  .py-5 {
	padding-top: 3rem !important;
  }
  
  .pr-5,
  .px-5 {
	padding-right: 3rem !important;
  }
  
  .pb-5,
  .py-5 {
	padding-bottom: 3rem !important;
  }
  
  .pl-5,
  .px-5 {
	padding-left: 3rem !important;
  }
  
  .m-auto {
	margin: auto !important;
  }
  
  .mt-auto,
  .my-auto {
	margin-top: auto !important;
  }
  
  .mr-auto,
  .mx-auto {
	margin-right: auto !important;
  }
  
  .mb-auto,
  .my-auto {
	margin-bottom: auto !important;
  }
  
  .ml-auto,
  .mx-auto {
	margin-left: auto !important;
  }
  
  @media (min-width: 576px) {
	.m-sm-0 {
	  margin: 0 !important;
	}
  
	.mt-sm-0,
	.my-sm-0 {
	  margin-top: 0 !important;
	}
  
	.mr-sm-0,
	.mx-sm-0 {
	  margin-right: 0 !important;
	}
  
	.mb-sm-0,
	.my-sm-0 {
	  margin-bottom: 0 !important;
	}
  
	.ml-sm-0,
	.mx-sm-0 {
	  margin-left: 0 !important;
	}
  
	.m-sm-1 {
	  margin: 0.25rem !important;
	}
  
	.mt-sm-1,
	.my-sm-1 {
	  margin-top: 0.25rem !important;
	}
  
	.mr-sm-1,
	.mx-sm-1 {
	  margin-right: 0.25rem !important;
	}
  
	.mb-sm-1,
	.my-sm-1 {
	  margin-bottom: 0.25rem !important;
	}
  
	.ml-sm-1,
	.mx-sm-1 {
	  margin-left: 0.25rem !important;
	}
  
	.m-sm-2 {
	  margin: 0.5rem !important;
	}
  
	.mt-sm-2,
	.my-sm-2 {
	  margin-top: 0.5rem !important;
	}
  
	.mr-sm-2,
	.mx-sm-2 {
	  margin-right: 0.5rem !important;
	}
  
	.mb-sm-2,
	.my-sm-2 {
	  margin-bottom: 0.5rem !important;
	}
  
	.ml-sm-2,
	.mx-sm-2 {
	  margin-left: 0.5rem !important;
	}
  
	.m-sm-3 {
	  margin: 1rem !important;
	}
  
	.mt-sm-3,
	.my-sm-3 {
	  margin-top: 1rem !important;
	}
  
	.mr-sm-3,
	.mx-sm-3 {
	  margin-right: 1rem !important;
	}
  
	.mb-sm-3,
	.my-sm-3 {
	  margin-bottom: 1rem !important;
	}
  
	.ml-sm-3,
	.mx-sm-3 {
	  margin-left: 1rem !important;
	}
  
	.m-sm-4 {
	  margin: 1.5rem !important;
	}
  
	.mt-sm-4,
	.my-sm-4 {
	  margin-top: 1.5rem !important;
	}
  
	.mr-sm-4,
	.mx-sm-4 {
	  margin-right: 1.5rem !important;
	}
  
	.mb-sm-4,
	.my-sm-4 {
	  margin-bottom: 1.5rem !important;
	}
  
	.ml-sm-4,
	.mx-sm-4 {
	  margin-left: 1.5rem !important;
	}
  
	.m-sm-5 {
	  margin: 3rem !important;
	}
  
	.mt-sm-5,
	.my-sm-5 {
	  margin-top: 3rem !important;
	}
  
	.mr-sm-5,
	.mx-sm-5 {
	  margin-right: 3rem !important;
	}
  
	.mb-sm-5,
	.my-sm-5 {
	  margin-bottom: 3rem !important;
	}
  
	.ml-sm-5,
	.mx-sm-5 {
	  margin-left: 3rem !important;
	}
  
	.p-sm-0 {
	  padding: 0 !important;
	}
  
	.pt-sm-0,
	.py-sm-0 {
	  padding-top: 0 !important;
	}
  
	.pr-sm-0,
	.px-sm-0 {
	  padding-right: 0 !important;
	}
  
	.pb-sm-0,
	.py-sm-0 {
	  padding-bottom: 0 !important;
	}
  
	.pl-sm-0,
	.px-sm-0 {
	  padding-left: 0 !important;
	}
  
	.p-sm-1 {
	  padding: 0.25rem !important;
	}
  
	.pt-sm-1,
	.py-sm-1 {
	  padding-top: 0.25rem !important;
	}
  
	.pr-sm-1,
	.px-sm-1 {
	  padding-right: 0.25rem !important;
	}
  
	.pb-sm-1,
	.py-sm-1 {
	  padding-bottom: 0.25rem !important;
	}
  
	.pl-sm-1,
	.px-sm-1 {
	  padding-left: 0.25rem !important;
	}
  
	.p-sm-2 {
	  padding: 0.5rem !important;
	}
  
	.pt-sm-2,
	.py-sm-2 {
	  padding-top: 0.5rem !important;
	}
  
	.pr-sm-2,
	.px-sm-2 {
	  padding-right: 0.5rem !important;
	}
  
	.pb-sm-2,
	.py-sm-2 {
	  padding-bottom: 0.5rem !important;
	}
  
	.pl-sm-2,
	.px-sm-2 {
	  padding-left: 0.5rem !important;
	}
  
	.p-sm-3 {
	  padding: 1rem !important;
	}
  
	.pt-sm-3,
	.py-sm-3 {
	  padding-top: 1rem !important;
	}
  
	.pr-sm-3,
	.px-sm-3 {
	  padding-right: 1rem !important;
	}
  
	.pb-sm-3,
	.py-sm-3 {
	  padding-bottom: 1rem !important;
	}
  
	.pl-sm-3,
	.px-sm-3 {
	  padding-left: 1rem !important;
	}
  
	.p-sm-4 {
	  padding: 1.5rem !important;
	}
  
	.pt-sm-4,
	.py-sm-4 {
	  padding-top: 1.5rem !important;
	}
  
	.pr-sm-4,
	.px-sm-4 {
	  padding-right: 1.5rem !important;
	}
  
	.pb-sm-4,
	.py-sm-4 {
	  padding-bottom: 1.5rem !important;
	}
  
	.pl-sm-4,
	.px-sm-4 {
	  padding-left: 1.5rem !important;
	}
  
	.p-sm-5 {
	  padding: 3rem !important;
	}
  
	.pt-sm-5,
	.py-sm-5 {
	  padding-top: 3rem !important;
	}
  
	.pr-sm-5,
	.px-sm-5 {
	  padding-right: 3rem !important;
	}
  
	.pb-sm-5,
	.py-sm-5 {
	  padding-bottom: 3rem !important;
	}
  
	.pl-sm-5,
	.px-sm-5 {
	  padding-left: 3rem !important;
	}
  
	.m-sm-auto {
	  margin: auto !important;
	}
  
	.mt-sm-auto,
	.my-sm-auto {
	  margin-top: auto !important;
	}
  
	.mr-sm-auto,
	.mx-sm-auto {
	  margin-right: auto !important;
	}
  
	.mb-sm-auto,
	.my-sm-auto {
	  margin-bottom: auto !important;
	}
  
	.ml-sm-auto,
	.mx-sm-auto {
	  margin-left: auto !important;
	}
  }
  
  @media (min-width: 768px) {
	.m-md-0 {
	  margin: 0 !important;
	}
  
	.mt-md-0,
	.my-md-0 {
	  margin-top: 0 !important;
	}
  
	.mr-md-0,
	.mx-md-0 {
	  margin-right: 0 !important;
	}
  
	.mb-md-0,
	.my-md-0 {
	  margin-bottom: 0 !important;
	}
  
	.ml-md-0,
	.mx-md-0 {
	  margin-left: 0 !important;
	}
  
	.m-md-1 {
	  margin: 0.25rem !important;
	}
  
	.mt-md-1,
	.my-md-1 {
	  margin-top: 0.25rem !important;
	}
  
	.mr-md-1,
	.mx-md-1 {
	  margin-right: 0.25rem !important;
	}
  
	.mb-md-1,
	.my-md-1 {
	  margin-bottom: 0.25rem !important;
	}
  
	.ml-md-1,
	.mx-md-1 {
	  margin-left: 0.25rem !important;
	}
  
	.m-md-2 {
	  margin: 0.5rem !important;
	}
  
	.mt-md-2,
	.my-md-2 {
	  margin-top: 0.5rem !important;
	}
  
	.mr-md-2,
	.mx-md-2 {
	  margin-right: 0.5rem !important;
	}
  
	.mb-md-2,
	.my-md-2 {
	  margin-bottom: 0.5rem !important;
	}
  
	.ml-md-2,
	.mx-md-2 {
	  margin-left: 0.5rem !important;
	}
  
	.m-md-3 {
	  margin: 1rem !important;
	}
  
	.mt-md-3,
	.my-md-3 {
	  margin-top: 1rem !important;
	}
  
	.mr-md-3,
	.mx-md-3 {
	  margin-right: 1rem !important;
	}
  
	.mb-md-3,
	.my-md-3 {
	  margin-bottom: 1rem !important;
	}
  
	.ml-md-3,
	.mx-md-3 {
	  margin-left: 1rem !important;
	}
  
	.m-md-4 {
	  margin: 1.5rem !important;
	}
  
	.mt-md-4,
	.my-md-4 {
	  margin-top: 1.5rem !important;
	}
  
	.mr-md-4,
	.mx-md-4 {
	  margin-right: 1.5rem !important;
	}
  
	.mb-md-4,
	.my-md-4 {
	  margin-bottom: 1.5rem !important;
	}
  
	.ml-md-4,
	.mx-md-4 {
	  margin-left: 1.5rem !important;
	}
  
	.m-md-5 {
	  margin: 3rem !important;
	}
  
	.mt-md-5,
	.my-md-5 {
	  margin-top: 3rem !important;
	}
  
	.mr-md-5,
	.mx-md-5 {
	  margin-right: 3rem !important;
	}
  
	.mb-md-5,
	.my-md-5 {
	  margin-bottom: 3rem !important;
	}
  
	.ml-md-5,
	.mx-md-5 {
	  margin-left: 3rem !important;
	}
  
	.p-md-0 {
	  padding: 0 !important;
	}
  
	.pt-md-0,
	.py-md-0 {
	  padding-top: 0 !important;
	}
  
	.pr-md-0,
	.px-md-0 {
	  padding-right: 0 !important;
	}
  
	.pb-md-0,
	.py-md-0 {
	  padding-bottom: 0 !important;
	}
  
	.pl-md-0,
	.px-md-0 {
	  padding-left: 0 !important;
	}
  
	.p-md-1 {
	  padding: 0.25rem !important;
	}
  
	.pt-md-1,
	.py-md-1 {
	  padding-top: 0.25rem !important;
	}
  
	.pr-md-1,
	.px-md-1 {
	  padding-right: 0.25rem !important;
	}
  
	.pb-md-1,
	.py-md-1 {
	  padding-bottom: 0.25rem !important;
	}
  
	.pl-md-1,
	.px-md-1 {
	  padding-left: 0.25rem !important;
	}
  
	.p-md-2 {
	  padding: 0.5rem !important;
	}
  
	.pt-md-2,
	.py-md-2 {
	  padding-top: 0.5rem !important;
	}
  
	.pr-md-2,
	.px-md-2 {
	  padding-right: 0.5rem !important;
	}
  
	.pb-md-2,
	.py-md-2 {
	  padding-bottom: 0.5rem !important;
	}
  
	.pl-md-2,
	.px-md-2 {
	  padding-left: 0.5rem !important;
	}
  
	.p-md-3 {
	  padding: 1rem !important;
	}
  
	.pt-md-3,
	.py-md-3 {
	  padding-top: 1rem !important;
	}
  
	.pr-md-3,
	.px-md-3 {
	  padding-right: 1rem !important;
	}
  
	.pb-md-3,
	.py-md-3 {
	  padding-bottom: 1rem !important;
	}
  
	.pl-md-3,
	.px-md-3 {
	  padding-left: 1rem !important;
	}
  
	.p-md-4 {
	  padding: 1.5rem !important;
	}
  
	.pt-md-4,
	.py-md-4 {
	  padding-top: 1.5rem !important;
	}
  
	.pr-md-4,
	.px-md-4 {
	  padding-right: 1.5rem !important;
	}
  
	.pb-md-4,
	.py-md-4 {
	  padding-bottom: 1.5rem !important;
	}
  
	.pl-md-4,
	.px-md-4 {
	  padding-left: 1.5rem !important;
	}
  
	.p-md-5 {
	  padding: 3rem !important;
	}
  
	.pt-md-5,
	.py-md-5 {
	  padding-top: 3rem !important;
	}
  
	.pr-md-5,
	.px-md-5 {
	  padding-right: 3rem !important;
	}
  
	.pb-md-5,
	.py-md-5 {
	  padding-bottom: 3rem !important;
	}
  
	.pl-md-5,
	.px-md-5 {
	  padding-left: 3rem !important;
	}
  
	.m-md-auto {
	  margin: auto !important;
	}
  
	.mt-md-auto,
	.my-md-auto {
	  margin-top: auto !important;
	}
  
	.mr-md-auto,
	.mx-md-auto {
	  margin-right: auto !important;
	}
  
	.mb-md-auto,
	.my-md-auto {
	  margin-bottom: auto !important;
	}
  
	.ml-md-auto,
	.mx-md-auto {
	  margin-left: auto !important;
	}
  }
  
  @media (min-width: 992px) {
	.m-lg-0 {
	  margin: 0 !important;
	}
  
	.mt-lg-0,
	.my-lg-0 {
	  margin-top: 0 !important;
	}
  
	.mr-lg-0,
	.mx-lg-0 {
	  margin-right: 0 !important;
	}
  
	.mb-lg-0,
	.my-lg-0 {
	  margin-bottom: 0 !important;
	}
  
	.ml-lg-0,
	.mx-lg-0 {
	  margin-left: 0 !important;
	}
  
	.m-lg-1 {
	  margin: 0.25rem !important;
	}
  
	.mt-lg-1,
	.my-lg-1 {
	  margin-top: 0.25rem !important;
	}
  
	.mr-lg-1,
	.mx-lg-1 {
	  margin-right: 0.25rem !important;
	}
  
	.mb-lg-1,
	.my-lg-1 {
	  margin-bottom: 0.25rem !important;
	}
  
	.ml-lg-1,
	.mx-lg-1 {
	  margin-left: 0.25rem !important;
	}
  
	.m-lg-2 {
	  margin: 0.5rem !important;
	}
  
	.mt-lg-2,
	.my-lg-2 {
	  margin-top: 0.5rem !important;
	}
  
	.mr-lg-2,
	.mx-lg-2 {
	  margin-right: 0.5rem !important;
	}
  
	.mb-lg-2,
	.my-lg-2 {
	  margin-bottom: 0.5rem !important;
	}
  
	.ml-lg-2,
	.mx-lg-2 {
	  margin-left: 0.5rem !important;
	}
  
	.m-lg-3 {
	  margin: 1rem !important;
	}
  
	.mt-lg-3,
	.my-lg-3 {
	  margin-top: 1rem !important;
	}
  
	.mr-lg-3,
	.mx-lg-3 {
	  margin-right: 1rem !important;
	}
  
	.mb-lg-3,
	.my-lg-3 {
	  margin-bottom: 1rem !important;
	}
  
	.ml-lg-3,
	.mx-lg-3 {
	  margin-left: 1rem !important;
	}
  
	.m-lg-4 {
	  margin: 1.5rem !important;
	}
  
	.mt-lg-4,
	.my-lg-4 {
	  margin-top: 1.5rem !important;
	}
  
	.mr-lg-4,
	.mx-lg-4 {
	  margin-right: 1.5rem !important;
	}
  
	.mb-lg-4,
	.my-lg-4 {
	  margin-bottom: 1.5rem !important;
	}
  
	.ml-lg-4,
	.mx-lg-4 {
	  margin-left: 1.5rem !important;
	}
  
	.m-lg-5 {
	  margin: 3rem !important;
	}
  
	.mt-lg-5,
	.my-lg-5 {
	  margin-top: 3rem !important;
	}
  
	.mr-lg-5,
	.mx-lg-5 {
	  margin-right: 3rem !important;
	}
  
	.mb-lg-5,
	.my-lg-5 {
	  margin-bottom: 3rem !important;
	}
  
	.ml-lg-5,
	.mx-lg-5 {
	  margin-left: 3rem !important;
	}
  
	.p-lg-0 {
	  padding: 0 !important;
	}
  
	.pt-lg-0,
	.py-lg-0 {
	  padding-top: 0 !important;
	}
  
	.pr-lg-0,
	.px-lg-0 {
	  padding-right: 0 !important;
	}
  
	.pb-lg-0,
	.py-lg-0 {
	  padding-bottom: 0 !important;
	}
  
	.pl-lg-0,
	.px-lg-0 {
	  padding-left: 0 !important;
	}
  
	.p-lg-1 {
	  padding: 0.25rem !important;
	}
  
	.pt-lg-1,
	.py-lg-1 {
	  padding-top: 0.25rem !important;
	}
  
	.pr-lg-1,
	.px-lg-1 {
	  padding-right: 0.25rem !important;
	}
  
	.pb-lg-1,
	.py-lg-1 {
	  padding-bottom: 0.25rem !important;
	}
  
	.pl-lg-1,
	.px-lg-1 {
	  padding-left: 0.25rem !important;
	}
  
	.p-lg-2 {
	  padding: 0.5rem !important;
	}
  
	.pt-lg-2,
	.py-lg-2 {
	  padding-top: 0.5rem !important;
	}
  
	.pr-lg-2,
	.px-lg-2 {
	  padding-right: 0.5rem !important;
	}
  
	.pb-lg-2,
	.py-lg-2 {
	  padding-bottom: 0.5rem !important;
	}
  
	.pl-lg-2,
	.px-lg-2 {
	  padding-left: 0.5rem !important;
	}
  
	.p-lg-3 {
	  padding: 1rem !important;
	}
  
	.pt-lg-3,
	.py-lg-3 {
	  padding-top: 1rem !important;
	}
  
	.pr-lg-3,
	.px-lg-3 {
	  padding-right: 1rem !important;
	}
  
	.pb-lg-3,
	.py-lg-3 {
	  padding-bottom: 1rem !important;
	}
  
	.pl-lg-3,
	.px-lg-3 {
	  padding-left: 1rem !important;
	}
  
	.p-lg-4 {
	  padding: 1.5rem !important;
	}
  
	.pt-lg-4,
	.py-lg-4 {
	  padding-top: 1.5rem !important;
	}
  
	.pr-lg-4,
	.px-lg-4 {
	  padding-right: 1.5rem !important;
	}
  
	.pb-lg-4,
	.py-lg-4 {
	  padding-bottom: 1.5rem !important;
	}
  
	.pl-lg-4,
	.px-lg-4 {
	  padding-left: 1.5rem !important;
	}
  
	.p-lg-5 {
	  padding: 3rem !important;
	}
  
	.pt-lg-5,
	.py-lg-5 {
	  padding-top: 3rem !important;
	}
  
	.pr-lg-5,
	.px-lg-5 {
	  padding-right: 3rem !important;
	}
  
	.pb-lg-5,
	.py-lg-5 {
	  padding-bottom: 3rem !important;
	}
  
	.pl-lg-5,
	.px-lg-5 {
	  padding-left: 3rem !important;
	}
  
	.m-lg-auto {
	  margin: auto !important;
	}
  
	.mt-lg-auto,
	.my-lg-auto {
	  margin-top: auto !important;
	}
  
	.mr-lg-auto,
	.mx-lg-auto {
	  margin-right: auto !important;
	}
  
	.mb-lg-auto,
	.my-lg-auto {
	  margin-bottom: auto !important;
	}
  
	.ml-lg-auto,
	.mx-lg-auto {
	  margin-left: auto !important;
	}
  }
  
  @media (min-width: 1200px) {
	.m-xl-0 {
	  margin: 0 !important;
	}
  
	.mt-xl-0,
	.my-xl-0 {
	  margin-top: 0 !important;
	}
  
	.mr-xl-0,
	.mx-xl-0 {
	  margin-right: 0 !important;
	}
  
	.mb-xl-0,
	.my-xl-0 {
	  margin-bottom: 0 !important;
	}
  
	.ml-xl-0,
	.mx-xl-0 {
	  margin-left: 0 !important;
	}
  
	.m-xl-1 {
	  margin: 0.25rem !important;
	}
  
	.mt-xl-1,
	.my-xl-1 {
	  margin-top: 0.25rem !important;
	}
  
	.mr-xl-1,
	.mx-xl-1 {
	  margin-right: 0.25rem !important;
	}
  
	.mb-xl-1,
	.my-xl-1 {
	  margin-bottom: 0.25rem !important;
	}
  
	.ml-xl-1,
	.mx-xl-1 {
	  margin-left: 0.25rem !important;
	}
  
	.m-xl-2 {
	  margin: 0.5rem !important;
	}
  
	.mt-xl-2,
	.my-xl-2 {
	  margin-top: 0.5rem !important;
	}
  
	.mr-xl-2,
	.mx-xl-2 {
	  margin-right: 0.5rem !important;
	}
  
	.mb-xl-2,
	.my-xl-2 {
	  margin-bottom: 0.5rem !important;
	}
  
	.ml-xl-2,
	.mx-xl-2 {
	  margin-left: 0.5rem !important;
	}
  
	.m-xl-3 {
	  margin: 1rem !important;
	}
  
	.mt-xl-3,
	.my-xl-3 {
	  margin-top: 1rem !important;
	}
  
	.mr-xl-3,
	.mx-xl-3 {
	  margin-right: 1rem !important;
	}
  
	.mb-xl-3,
	.my-xl-3 {
	  margin-bottom: 1rem !important;
	}
  
	.ml-xl-3,
	.mx-xl-3 {
	  margin-left: 1rem !important;
	}
  
	.m-xl-4 {
	  margin: 1.5rem !important;
	}
  
	.mt-xl-4,
	.my-xl-4 {
	  margin-top: 1.5rem !important;
	}
  
	.mr-xl-4,
	.mx-xl-4 {
	  margin-right: 1.5rem !important;
	}
  
	.mb-xl-4,
	.my-xl-4 {
	  margin-bottom: 1.5rem !important;
	}
  
	.ml-xl-4,
	.mx-xl-4 {
	  margin-left: 1.5rem !important;
	}
  
	.m-xl-5 {
	  margin: 3rem !important;
	}
  
	.mt-xl-5,
	.my-xl-5 {
	  margin-top: 3rem !important;
	}
  
	.mr-xl-5,
	.mx-xl-5 {
	  margin-right: 3rem !important;
	}
  
	.mb-xl-5,
	.my-xl-5 {
	  margin-bottom: 3rem !important;
	}
  
	.ml-xl-5,
	.mx-xl-5 {
	  margin-left: 3rem !important;
	}
  
	.p-xl-0 {
	  padding: 0 !important;
	}
  
	.pt-xl-0,
	.py-xl-0 {
	  padding-top: 0 !important;
	}
  
	.pr-xl-0,
	.px-xl-0 {
	  padding-right: 0 !important;
	}
  
	.pb-xl-0,
	.py-xl-0 {
	  padding-bottom: 0 !important;
	}
  
	.pl-xl-0,
	.px-xl-0 {
	  padding-left: 0 !important;
	}
  
	.p-xl-1 {
	  padding: 0.25rem !important;
	}
  
	.pt-xl-1,
	.py-xl-1 {
	  padding-top: 0.25rem !important;
	}
  
	.pr-xl-1,
	.px-xl-1 {
	  padding-right: 0.25rem !important;
	}
  
	.pb-xl-1,
	.py-xl-1 {
	  padding-bottom: 0.25rem !important;
	}
  
	.pl-xl-1,
	.px-xl-1 {
	  padding-left: 0.25rem !important;
	}
  
	.p-xl-2 {
	  padding: 0.5rem !important;
	}
  
	.pt-xl-2,
	.py-xl-2 {
	  padding-top: 0.5rem !important;
	}
  
	.pr-xl-2,
	.px-xl-2 {
	  padding-right: 0.5rem !important;
	}
  
	.pb-xl-2,
	.py-xl-2 {
	  padding-bottom: 0.5rem !important;
	}
  
	.pl-xl-2,
	.px-xl-2 {
	  padding-left: 0.5rem !important;
	}
  
	.p-xl-3 {
	  padding: 1rem !important;
	}
  
	.pt-xl-3,
	.py-xl-3 {
	  padding-top: 1rem !important;
	}
  
	.pr-xl-3,
	.px-xl-3 {
	  padding-right: 1rem !important;
	}
  
	.pb-xl-3,
	.py-xl-3 {
	  padding-bottom: 1rem !important;
	}
  
	.pl-xl-3,
	.px-xl-3 {
	  padding-left: 1rem !important;
	}
  
	.p-xl-4 {
	  padding: 1.5rem !important;
	}
  
	.pt-xl-4,
	.py-xl-4 {
	  padding-top: 1.5rem !important;
	}
  
	.pr-xl-4,
	.px-xl-4 {
	  padding-right: 1.5rem !important;
	}
  
	.pb-xl-4,
	.py-xl-4 {
	  padding-bottom: 1.5rem !important;
	}
  
	.pl-xl-4,
	.px-xl-4 {
	  padding-left: 1.5rem !important;
	}
  
	.p-xl-5 {
	  padding: 3rem !important;
	}
  
	.pt-xl-5,
	.py-xl-5 {
	  padding-top: 3rem !important;
	}
  
	.pr-xl-5,
	.px-xl-5 {
	  padding-right: 3rem !important;
	}
  
	.pb-xl-5,
	.py-xl-5 {
	  padding-bottom: 3rem !important;
	}
  
	.pl-xl-5,
	.px-xl-5 {
	  padding-left: 3rem !important;
	}
  
	.m-xl-auto {
	  margin: auto !important;
	}
  
	.mt-xl-auto,
	.my-xl-auto {
	  margin-top: auto !important;
	}
  
	.mr-xl-auto,
	.mx-xl-auto {
	  margin-right: auto !important;
	}
  
	.mb-xl-auto,
	.my-xl-auto {
	  margin-bottom: auto !important;
	}
  
	.ml-xl-auto,
	.mx-xl-auto {
	  margin-left: auto !important;
	}
  }

  
.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  .text-justify {
	text-align: justify !important;
  }
  
  .text-nowrap {
	white-space: nowrap !important;
  }
  
  .text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  }
  
  .text-left {
	text-align: left !important;
  }
  
  .text-right {
	text-align: right !important;
  }
  
  .text-center {
	text-align: center !important;
  }
  
  @media (min-width: 576px) {
	.text-sm-left {
	  text-align: left !important;
	}
  
	.text-sm-right {
	  text-align: right !important;
	}
  
	.text-sm-center {
	  text-align: center !important;
	}
  }
  
  @media (min-width: 768px) {
	.text-md-left {
	  text-align: left !important;
	}
  
	.text-md-right {
	  text-align: right !important;
	}
  
	.text-md-center {
	  text-align: center !important;
	}
  }
  
  @media (min-width: 992px) {
	.text-lg-left {
	  text-align: left !important;
	}
  
	.text-lg-right {
	  text-align: right !important;
	}
  
	.text-lg-center {
	  text-align: center !important;
	}
  }
  
  @media (min-width: 1200px) {
	.text-xl-left {
	  text-align: left !important;
	}
  
	.text-xl-right {
	  text-align: right !important;
	}
  
	.text-xl-center {
	  text-align: center !important;
	}
  }
  
  .text-lowercase {
	text-transform: lowercase !important;
  }
  
  .text-uppercase {
	text-transform: uppercase !important;
  }
  
  .text-capitalize {
	text-transform: capitalize !important;
  }
  
  .font-weight-light {
	font-weight: 300 !important;
  }
  
  .font-weight-normal {
	font-weight: 400 !important;
  }
  
  .font-weight-bold {
	font-weight: 700 !important;
  }
  
  .font-italic {
	font-style: italic !important;
  }
  
  .text-white {
	color: #fff !important;
  }
  
  .text-primary {
	color: #007bff !important;
  }
  
  a.text-primary:hover,
  a.text-primary:focus {
	color: #0062cc !important;
  }
  
  .text-secondary {
	color: #6c757d !important;
  }
  
  a.text-secondary:hover,
  a.text-secondary:focus {
	color: #545b62 !important;
  }
  
  .text-success {
	color: #28a745 !important;
  }
  
  a.text-success:hover,
  a.text-success:focus {
	color: #1e7e34 !important;
  }
  
  .text-info {
	color: #17a2b8 !important;
  }
  
  a.text-info:hover,
  a.text-info:focus {
	color: #117a8b !important;
  }
  
  .text-warning {
	color: #ffc107 !important;
  }
  
  a.text-warning:hover,
  a.text-warning:focus {
	color: #d39e00 !important;
  }
  
  .text-danger {
	color: #dc3545 !important;
  }
  
  a.text-danger:hover,
  a.text-danger:focus {
	color: #bd2130 !important;
  }
  
  .text-light {
	color: #f8f9fa !important;
  }
  
  a.text-light:hover,
  a.text-light:focus {
	color: #dae0e5 !important;
  }
  
  .text-dark {
	color: #343a40 !important;
  }
  
  a.text-dark:hover,
  a.text-dark:focus {
	color: #1d2124 !important;
  }
  
  .text-body {
	color: #212529 !important;
  }
  
  .text-muted {
	color: #555 !important;
  }
  
  .text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
  }
  
  .text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
  }
  
  .text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
  }
  
  .visible {
	visibility: visible !important;
  }
  
  .invisible {
	visibility: hidden !important;
  }
  
  @media print {
	*,
	*::before,
	*::after {
	  text-shadow: none !important;
	  -webkit-box-shadow: none !important;
			  box-shadow: none !important;
	}
  
	a:not(.btn) {
	  text-decoration: underline;
	}
  
	abbr[title]::after {
	  content: " (" attr(title) ")";
	}
  
	pre {
	  white-space: pre-wrap !important;
	}
  
	pre,
	blockquote {
	  border: 1px solid #adb5bd;
	  page-break-inside: avoid;
	}
  
	thead {
	  display: table-header-group;
	}
  
	tr,
	img {
	  page-break-inside: avoid;
	}
  
	p,
	h2,
	h3 {
	  orphans: 3;
	  widows: 3;
	}
  
	h2,
	h3 {
	  page-break-after: avoid;
	}
  
  @page {
	  size: a3;
  }
  
	body {
	  min-width: 992px !important;
	}
  
	.container {
	  min-width: 992px !important;
	}
  
	.navbar {
	  display: none;
	}
  
	.badge {
	  border: 1px solid #000;
	}
  
	.table {
	  border-collapse: collapse !important;
	}
  
	.table td,
	.table th {
	  background-color: #fff !important;
	}
  
	.table-bordered th,
	.table-bordered td {
	  border: 1px solid #dee2e6 !important;
	}
  
	.table-dark {
	  color: inherit;
	}
  
	.table-dark th,
	.table-dark td,
	.table-dark thead th,
	.table-dark tbody + tbody {
	  border-color: #dee2e6;
	}
  
	.table .thead-dark th {
	  color: inherit;
	  border-color: #dee2e6;
	}
  }



  .pcard {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
  }
  
  .pcard > hr {
	margin-right: 0;
	margin-left: 0;
  }
  
  .pcard > .list-group:first-child .list-group-item:first-child {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
  }
  
  .pcard > .list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
  }
  
  .pcard-body {
	-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
			flex: 1 1 auto;
	padding: 1.25rem;
  }
  
  .pcard-title {
	margin-bottom: 0.75rem;
	display: block;
  }

  .pcard-title-5 {
	@extend .pcard-title;
	line-height: 18px;
	font-size: 16px;
  }
  
  .pcard-subtitle {
	margin-top: -0.375rem;
	margin-bottom: 0;
  }
  
  .pcard-text:last-child {
	margin-bottom: 0;
  }
  
  .pcard-link:hover {
	text-decoration: none;
  }
  
  .pcard-link + .pcard-link {
	margin-left: 1.25rem;
  }
  
  .pcard-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .pcard-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  
  .pcard-header + .list-group .list-group-item:first-child {
	border-top: 0;
  }
  
  .pcard-footer {
	padding: 0.75rem 1.25rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .pcard-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }
  
  .pcard-header-tabs {
	margin-right: -0.625rem;
	margin-bottom: -0.75rem;
	margin-left: -0.625rem;
	border-bottom: 0;
  }
  
  .pcard-header-pills {
	margin-right: -0.625rem;
	margin-left: -0.625rem;
  }
  
  .pcard-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
  }
  
  .pcard-img {
	width: 100%;
	border-radius: calc(0.25rem - 1px);
  }
  
  .pcard-img-top {
	width: 100%;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .pcard-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
  }
  
  .pcard-deck {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
  }
  
  .pcard-deck .pcard {
	margin-bottom: 15px;
	overflow: hidden;
  }
  
  @media (min-width: 600px) {
	.pcard-deck {
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: normal;
		  -ms-flex-flow: row wrap;
			  flex-flow: row wrap;
	  margin-right: -15px;
	  margin-left: -15px;
	}
  
	.pcard-deck .pcard {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-flex: 1;
		  -ms-flex: 1 0 0%;
			  flex: 1 0 0%;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
		  -ms-flex-direction: column;
			  flex-direction: column;
	  margin-right: 15px;
	  margin-bottom: 0;
	  margin-left: 15px;
	}
  }
  
  .pcard-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
  }
  
  .pcard-group > .pcard {
	margin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
	.pcard-group {
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: normal;
		  -ms-flex-flow: row wrap;
			  flex-flow: row wrap;
	}
  
	.pcard-group > .pcard {
	  -webkit-box-flex: 1;
		  -ms-flex: 1 0 0%;
			  flex: 1 0 0%;
	  margin-bottom: 0;
	}
  
	.pcard-group > .pcard + .pcard {
	  margin-left: 0;
	  border-left: 0;
	}
  
	.pcard-group > .pcard:first-child {
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	}
  
	.pcard-group > .pcard:first-child .pcard-img-top,
	.pcard-group > .pcard:first-child .pcard-header {
	  border-top-right-radius: 0;
	}
  
	.pcard-group > .pcard:first-child .pcard-img-bottom,
	.pcard-group > .pcard:first-child .pcard-footer {
	  border-bottom-right-radius: 0;
	}
  
	.pcard-group > .pcard:last-child {
	  border-top-left-radius: 0;
	  border-bottom-left-radius: 0;
	}
  
	.pcard-group > .pcard:last-child .pcard-img-top,
	.pcard-group > .pcard:last-child .pcard-header {
	  border-top-left-radius: 0;
	}
  
	.pcard-group > .pcard:last-child .pcard-img-bottom,
	.pcard-group > .pcard:last-child .pcard-footer {
	  border-bottom-left-radius: 0;
	}
  
	.pcard-group > .pcard:only-child {
	  border-radius: 0.25rem;
	}
  
	.pcard-group > .pcard:only-child .pcard-img-top,
	.pcard-group > .pcard:only-child .pcard-header {
	  border-top-left-radius: 0.25rem;
	  border-top-right-radius: 0.25rem;
	}
  
	.pcard-group > .pcard:only-child .pcard-img-bottom,
	.pcard-group > .pcard:only-child .pcard-footer {
	  border-bottom-right-radius: 0.25rem;
	  border-bottom-left-radius: 0.25rem;
	}
  
	.pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) {
	  border-radius: 0;
	}
  
	.pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) .pcard-img-top,
	.pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) .pcard-img-bottom,
	.pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) .pcard-header,
	.pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) .pcard-footer {
	  border-radius: 0;
	}
  }
  
  .pcard-columns .pcard {
	margin-bottom: 0.75rem;
  }
  