.insight-card {
	height: 100%;

	.pcard-body {
		display: flex;
		flex-direction: column;

		.expert {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			.dih-avatar {
				margin-right: 5px;
			}

			.h5 {
				margin: 0;
			}
		}

		.description {
			flex-grow: 1;
			margin-bottom: 10px;
		}

		.dates {
			font-style: italic;
		}

		.tag {
			align-self: flex-start;
		}
	}
}