
.ld-course-card {
	height: 100%;
	margin: 0;

	.card-body {
		height: 100%;
		display: flex;
		flex-direction: column;

		.name {
			display: flex;
			align-items: center;
			color: $linkBackgroundColour;

			svg {
				margin-right: 5px;
			}
		}

		.body {
			flex-grow: 1;

			margin-bottom: 10px;
		}

		.stats {
			margin-bottom: 2px;

			.label {
				margin-right: 5px;
			}

			.attachments {
				background-color: #27a9e1;
			}

			.links {
				background-color: #1b75bb;
			}

			.videos {
				background-color: #bb1b75;
			}
		}
	}
} 

.ld-video-resource-card {
	min-height: 202px !important;

	.card-body {
		position: relative;

		.frame-wrapper {
			position: absolute;
			top: 50px;
			left: 0;
			width: 100%;

			iframe {
				width: 100%;
			}
		}

		.body { 
			display: none;
		}
	}

}

@media print {
	.ld-video-resource-card {
		.card-body {
			.frame-wrapper {
				display: none;
			}
	
			.body { 
				display: block;
			}
		}
	
	}
	
}