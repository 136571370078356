
.career-progression {
	.roleLabel {
		fill: white;
		font-size: 20px;
	}

	.arc {
		cursor: pointer;
	}
	.arcText {
		// font-size: 12px;
		fill: white;
		// letter-spacing: 5px;
		cursor: pointer;
	}

	// .progression {
	// 	cursor: pointer;
	// }
	// .progressionLabel {
	// 	fill: white;
	// 	font-size: 12px;
	// 	cursor: pointer;
	// }
	.progression {
		margin: 0;
		padding: 5px;

		.progression-role {
			background: #84c446;
			border: 1px solid #aaa;
			color: #fff;
			cursor: pointer;
			padding: 10px;
			clear: both;
			margin-bottom: 10px;
			position: relative;

			.progression-role-name {
				display: inline-block;
				width: 100%;
				font-weight: 500;

			}
			.progression-role-score {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}
	}

	
	.stats {
		display: flex;
		text-align: center;
		justify-content: center;

		.stat {
			width: 210px;
			max-width: 210px;
			display: flex;
			flex-direction: column;
			padding: 15px;

			.stat-label {
				font-size: 16px;
				line-height: 24px;
				color: rgb(28, 28, 28);
				margin-bottom: 10px;
			}

			.stat-wrapper {
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				flex-direction: column;
				-webkit-box-align: center;
				align-items: center;

				.stat-values {
					font-size: 25px;
					line-height: 25px;
					font-weight: 500;
					color: rgb(13, 56, 128);
					margin-bottom: 5px;
					display: flex;
					-webkit-box-align: center;
					align-items: center;

					.stat-trend {
						margin-right: 10px;
						border-bottom: 14px solid rgb(63, 168, 51);
						border-left: 8px solid transparent;
						border-right: 8px solid transparent;
					}
				}

				.stat-desc {
					width: 150px;
					font-size: 13px;
					line-height: 16px;
					color: rgb(28, 28, 28);
				}
			}
		}
	}
}
