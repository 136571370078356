
.pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
	margin-top: 20px
  }
  
  .page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #007bff;
	background-color: #fff;
	border: 1px solid #dee2e6;
  }
  
  .page-link:hover {
	z-index: 2;
	color: #0056b3;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #dee2e6;
  }
  
  .page-link:focus {
	z-index: 2;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .page-link:not(:disabled):not(.disabled) {
	cursor: pointer;
  }
  
  .page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
  }
  
  .page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
  }
  
  .page-item.active .page-link {
	z-index: 1;
	color: #fff;
  }
  
  .page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #dee2e6;
  }
  
  .pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.125rem;
	line-height: 1.5;
  }
  
  .pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
  }
  
  .pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
  }
  
  .pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.7875rem;
	line-height: 1.5;
  }
  
  .pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
  }
  
  .pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
  }
  
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination > li > a,
.pagination > li > span {
  color: #263238;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #f2f4f8;
}

.pagination-split li {
  margin-left: 5px;
  display: block;
  float: left;
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  border-radius: 4px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #009efb;
}