
.offer-box {
	-webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	min-width: 280px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	transition: all .5s ease-in-out;
	height: 100%;

	.pcard-img-top {
		height: 100%;
	}
}