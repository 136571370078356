
.dih-button-group {
	.option {
		border-radius: 0;
		box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 
			0 3px 1px -2px rgba(40, 190, 189, 0.2), 
			0 1px 5px 0 rgba(40, 190, 189, 0.12);

		&:first-of-type { border-radius: 4px 0 0 4px; }
		&:last-of-type { border-radius: 0 4px 4px 0; }

		&:hover {
			background-color: #007C9A;
			box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 
				0 4px 23px 0 rgba(0, 0, 0, 0.12), 
				0 8px 10px -5px rgba(40, 190, 189, 0.2);
			z-index: 1;
		}

		&.active {
			background-color: #1e7e34;
			border-color: #1e7e34;
			box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 
				0 4px 23px 0 rgba(0, 0, 0, 0.12), 
				0 8px 10px -5px rgba(40, 190, 189, 0.2);
		}
	}
}