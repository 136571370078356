
.display-field {
	margin: 5px 0;
	
	label {
		margin-right: 5px;
		font-size: 1rem;
		font-weight: 500;
	}


	span {
		font-size: 1rem;
	}
}