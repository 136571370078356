
.login-layout {
	@extend .main-layout;

	.login-container {
		display: flex;
		max-width: 1000px;
		height: 100%;
		align-items: center;
		justify-content: center;
		margin: 0 auto 10px;

		.login-box, .registration-box, .reset-box {
			padding: 1.25rem;
			max-width: 450px;

			h1 {
				margin: 40px 0 0;
			}
	
			.button.grey {
				background-color: #686868;
				
				&:hover {
					filter: brightness(110%);
				}
			}
		}

		.help-box {
			background-color: #f2f2f2;
			text-align: center;
			padding: 1.25rem;
			margin-left: 80px; // auto not supported in IE for flexbox

			p {
				text-align: left;
			}

			a {
				color: #0063cc;
			}

			svg {
			}
		}
	}
}

@media (max-width: 1000px) {
	.login-layout .login-container {
		display: block;

		.login-box {
			margin: 0 auto;
		}

		.help-box {
			margin: 0;
		}
	}
}

.signin__main {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 100%;
	width: 100%;
	position: fixed;
}

.signin__main .signin__content {
	background: #fff;
	padding: 7.25rem 1.25rem 1.25rem 1.25rem;
	overflow-y: auto;
	right: 0px;
	position: absolute;
	height: 100%;
	width: 400px;
	margin: auto;
}

.signin__main .signin__logo {
	width: 100%;
	position: absolute;
	top: 20px;
	left: 0px;
}

.signin__main .signin__background {
	width: calc(100% - 400px);
	height: 100%;
	position: fixed;
	top: 0;
	background-repeat: no-repeat;
	background-size: cover;
}

@media (max-width: 767px) {
	.signin__main {
		padding: 10% 0;
		position: relative;
		overflow: hidden;
	}

	.signin__main .signin__content {
			width: 90%;
			position: relative;
	}
	.signin__main .signin__background {
		width: 100%;
	}
}
