.passport {
	.card {
		margin: 0;
		height: "100%";
	}

	.button-container {
		display: flex;
		justify-content: space-around;
		align-items: flex-start;
	}

	.badges {
		margin-top: 30px;
	}

	.passport-field-text {
		display: block;
		
		span:empty:before {
			content: "\200b"; // Force span to take up line height
		}
	}
	
	.skill-button-prompt-text {
		text-align: center;
		font-weight: bold;
		color: rgb(208, 89, 87);
	}
}

.passport-stat {
	height: 100px;
	width: 180px;
	display: inline-block;
	margin-top: 20px;
	margin-right: 10px;
	
	.left-side {
		width: 60px;
		height: 100%;
		float: left;
		line-height: 100px;
		text-align: center;

		.stat-icon {
			font-size: 35px;
			vertical-align: middle;
		}
	}

	.right-side {
		width: 120px;
		height: 100%;
		float: right;
		position: relative;

		.stat-count {
			font-size: 40px;
			position: absolute;
			right: 10px;
			top: 0px;
		}

		.stat-label {
			font-size: 14px;
			position: absolute;
			right: 10px;
			top: 50px;
			margin-left: 5px;
			display: table;
			text-align: right;
			width: 105px;
			height: 48px;
			
			span {
				vertical-align: middle;
    			display: table-cell;
			}
		}
	}
}