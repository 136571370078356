
.form-buttons {
	margin-top: 20px;

	.accept-button {
		background: $successColour;
		color: white;

		&:hover {
			background: #1f6129;
		}
	}

	.delete-button {
		background: $errorColour;
		color: white;
		margin-left: 10px;

		&:hover {
			background: #791d1a;
		}
	}
}
