.my-community {
	.channel-card {
		margin-bottom: 0px;
	
		.card-body {
			button.unsubscribe {
				position: absolute;
				top: 15px;
				right: 10px;
			}
		
			.organisation > img {
				margin-right: 5px;
				height: 20px;
				width: 20px
			}

			.description {
				margin: 5px 0;
			}
		}
	}

	.feed {
		.card {
			margin-bottom: 20px;
			display: inline-block;
			width: 100%;

			.card-title .title a {
				color: inherit;
			}

			.card-body {
				display: flex;
				flex-direction: column;
			}
		}

		.h4 {
			margin: 0;
		}

		.description {
			margin: 5px 0 10px 0;
		}

		.add-button {
			align-self: flex-start;
		}

		.label {
			margin-right: 5px;
		}

		.event-row {
			display: flex;
			margin-top: 20px;
			
			&:nth-child(2) {
				margin-top: 0;
			}

			.date {
				margin-right: 20px;
				text-align: center;

				> svg {
					height: 60px;
					width: 60px;
				}
			}

			.body {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
	
				.time > svg {
					vertical-align: middle;
				}
			}
		}

		.resource-row {
			margin-top: 20px;
			
			&:nth-child(2) {
				margin-top: 0;
			}
		
			.name {
				display: flex;
				align-items: center;
				margin-bottom: 0;

				svg {
					margin-right: 5px;
				}
			}

			.academic {
				display: flex;
				align-items: center;

				svg {
					margin-right: 5px;
				}
			}
		}

		.discussion-row {
			display: flex;
			margin-top: 20px;
			
			&:nth-child(2) {
				margin-top: 0;
			}

			> .avatar {
				margin-right: 10px;
			}

			.stats > svg {
				vertical-align: middle;
			}
		}

		.problem-row {
			display: flex;
			margin-top: 20px;
			
			&:nth-child(2) {
				margin-top: 0;
			}

			.votes {
				display: flex;
				flex-direction: column;
				align-items: center;

				.count {
					font-size: 1.5em;
					margin-top: 0.5rem;
				}
			}

			.body {
				flex-grow: 1;
				margin-left: 10px;

				.views {
					@extend .label-rounded, .label-light-info;
					float: right;
				}
			}
		}

		.insight-row {
			margin-top: 20px;
			
			&:nth-child(2) {
				margin-top: 0;
			}

			.expert {
				display: flex;
				align-items: center;
				margin-top: 5px;

				> .dih-avatar {
					margin-right: 5px;
				}

				.h5 {
					margin: 0;
				}
			}

			.dates {
				font-style: italic;
			}
		}
	}
}

.subscribe-dialog {
	.channel-card {
		height: 100%;
	
		.card-body {
			height: 100%;
			display: flex;
			flex-direction: column;
	
			.description {
				flex-grow: 1;
			}
	
			.button {
				width: 100%;
			}
		}
	}
}

@media (min-width: 768px) {
	.my-community .feed {
		column-count: 2;
	}
}