
.profile-container {
	.card {
		margin-bottom: 0px;
	}

	.contact-info {
		padding: 0;
		width: 288px;

		> .card-body {
			padding: 0;

			.content > div {
				padding: 1.25rem;
			}

			.titles {
				padding: 10px 0;

				span {
					display: block;
				}
			}
			
			.static-stat {
				cursor: default;
				
				&:hover {
					background-color: initial;
				}
			}

			p.email {
				overflow: hidden;
				text-overflow: ellipsis;
			}

			iframe {
				border: 0;
				margin-top: 10px;
			}
		}
	}
}

@media (max-width: 1009px) {
	.profile-container {
		flex-direction: column;
		align-items: center;
	}
}