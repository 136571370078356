.course-details {
	.header {
		display: flex;
		align-items: flex-start;
		margin-bottom: 10px;

		> div {
			flex: 1;

			h2 {
				margin: 0;
			}
		}
	}

	.course-link > a {
		display: flex;
		align-items: center;

		svg {
			margin-right: 5px;
		}
	}
}