@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);

$primaryColour: #00224D;
$primaryTextColour: #fff;
$secondaryColour: #D6E8FF;
$secondaryTextColour: #00224D;

$linkTextColour: #5e7a87;
$linkBackgroundColour: #007C9A;

$headerBackgroundColour: #0E76BC;
$contentBackgroundColour: #eee;
$footerBackgroundColour: #8DC63F;

$selectionColour: #84c446;
$selectionAltColour: #fff;
$successColour: #35aa47;
$errorColour: #d1332e;
$cancelColour: #d84a38;
$cancelHoverBackgroundColour: #ddd;
$warningColour: #f0ad4e;
$infoColour: #5bc0de;
$debugColour: #aaa;

$topbarColour: #060607;
$navbarBackgroundColour: #156184;

/* Digital DNA branding Colours */
$Grey: #C9CCC4;
$Navy: #0e4d95;
$Green: #b8cf44;
$Blue: #007C9A;
$Yellow: #FBC400;

@import "./_mixins.scss";
@import "./_base.scss";
@import "./_utils.scss";
@import "./_layout.scss";
@import "./_navbar.scss";
@import "./_footer.scss";
@import "./_topbar.scss";
@import "./_side-menu.scss";
@import "./_sidepanel.scss";
@import "./_page-banner.scss";
@import "./_print.scss";

@import "./widgets/_widgets.scss";
@import "./pages/_pages.scss";
@import "./reports/_reports.scss";
