@media print {
	.top-bar {
		position: relative !important;
	}
}

.top-bar {
	position: fixed;
	width: 100%;
	max-width: 100vw; // on mobile the fixed position causes width to be original document size and cause this to be larger than viewport
	top: 0;
	// background: $topbarColour;
	z-index: 50;
	box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
	
	.top-appbar {
		display: flex;
		align-items: center;
		// max-width: 1280px;
		margin: 0 auto;
		padding: 0 15px;
		height: 60px;
		position: relative;
		text-align: center;

		.appbar-header {
			position: absolute; // Make pos abs rather than float left so hamburger menu icon will display on top if screen gets too narrow
			top: 0;
			width: 100%;
			
			.logo-primary {
				margin-top: 0;
				font-family: 'Orbitron', sans-serif;
				font-size: 36px;
				font-weight: bold;
			}
			  
			.appbar-brand {
				.logo, .brand {
					display: inline-block;
					color: rgba(255, 255, 255, 0.8);
				}
			}
		}

		.appbar-tenant-title {
			width: 100%;
			text-align: right;
			margin: 0;
			margin-right: 10px;
		}

		.subscribe-button {
			margin-left: auto;
		}

		.nav-toggle {
			margin-left: auto;
			display: none;
		}

		.header-tools {
			display: flex;
			flex-direction: row;
			float: right;
			font-size: 12px;
			font-weight: 500;
			margin-top: 10px;
			color: white;

			.header-links {
				margin: 0;
				padding: 0;
				line-height: 30px;
				
				li {
					display: inline;
					list-style-type: none;
					padding: 0 0.5em;

					a {
						color: white;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
				
			.search-form {
				padding-left: 0.5em;

				.search-input {
					height: 30px;
					background-color: #3f7da2;
					border: 0 solid #065687;
					float: left;
					font-size: 12px;
					padding: 8px 0px 8px 5px;
					width: 200px;
					color: white;

					&::placeholder {
						color: inherit;
					}
				}

				.search-button {
					background-color: #3f7da2;
					width: 30px;
					height: 30px;
				}
			}
		}
	}

    .right-nav {
		position: absolute;
		display: flex;
		right: 0;

        #nav-notifications {
        }
        
        .notifications-panel {
            width: 300px;

            > a {
                border-radius: 0 0 4px 4px;
            }
        }

        .profile-panel {
            width: 300px;
    
            .user-box {
                padding: 15px 15px 0;
                text-align: center;
    
                .user-details {
                    padding: 8px 0;
                }
            }

            svg {
                color: inherit;
            }
        }

    }

	.full-menu-toggle {
		display: none;
	}
}

@media (max-width: 767px) {
	.top-bar .top-appbar {
		.appbar-header .appbar-brand img.brand {
			display: none;
		}

		.nav-toggle {
			display: flex;
		}

		.header-tools {
			display: none;
		}

		.subscribe-button {
			display: none;
		}

		.right-nav { 
			display: none;
		}
		.side-menu-toggle {
			display: none;
		}
		.full-menu-toggle {
			display: block;
		}
	}
}