.side-panel {
	> div {
		width: 100%;
		margin-top: 60px;
		padding: 10px 0;
		height: calc(100% - 60px);
	}

	.search-form {
		display: flex;
		margin: 0.2em 0.5em;
		padding-left: 0.01em;

		&__input {
			height: 30px;
			font-size: 12px;
			padding: 8px 0px 8px 5px;
			width: 100%;
		}

		&__button {
			background-color: #3f7da2;
			width: 30px;
		}
	}

	.side-panel__menu {
		padding-top: 0;
	}
}

@media (min-width: 768px) {
	.side-panel {
		display: none;
	}
}