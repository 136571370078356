
.ip-container {
	.ip-details {
		margin-bottom: 30px;
	
		.contact-details {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
	
			.details-box {
				small {
					display: block;
				}
	
				.contact-person {
					color: #007C9A;
					font-size: 100%;
					font-weight: 300;
	
					svg {
						cursor: pointer;
						font-size: 20px;
						vertical-align: middle;
					}
				}
			}
		}

		svg {
			vertical-align: middle;
		}

		.paper {
			padding: 20px;

			span {
				display: block;
			}

			svg {
				margin-right: 5px;
			}
		}
	}
}