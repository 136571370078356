
.side-menu-toggle, .full-menu-toggle {
    color: $primaryTextColour;
    z-index: 9;
}

.side-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    // height: calc(100% - 60px);
    height: 100%;
	// width: 350px;
    background-color: #FFF;
    transition: width 0.3s ease;
    overflow-y: auto;
    flex-shrink: 0;
    padding-top: 10px;

    .menus {
        display: flex;
        height: calc(100% - 58px);

        .top-menu {
            padding: 0px;
            overflow-y: auto;
            border-right: 1px solid #d9d9d9;
        }

        .sub-menu {
            padding-top: 20px;
            padding-left: 5px;
            flex-grow: 1;
            overflow-y: auto;
            width: 190px;
        }
    }

    .side-menu-bottom {
        display: none; 

        position: absolute;
        bottom: 0;
        border-top: 1px solid #d9d9d9;
        width: 100%;
        // display: flex;
        justify-content: end;


        #nav-notifications {
        }
        
        .notifications-panel {
            width: 300px;

            > a {
                border-radius: 0 0 4px 4px;
            }
        }

        .profile-panel {
            width: 300px;
    
            .user-box {
                padding: 15px 15px 0;
                text-align: center;
    
                .user-details {
                    padding: 8px 0;
                }
            }

            svg {
                color: inherit;
            }
        }

    }

    &.side-menu-collapsed {
        .sub-menu {
            display: none;
        }

        .side-menu-bottom {
            display: none;
        }
    }
}


@media (max-width: 767px) { 
	.side-menu { 
		display: none;
	}
}