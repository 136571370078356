
.image-selector {

	.image-list {
		display: flex;
		margin-left: -10px;
		margin-right: -10px;
		flex-flow: row wrap;

		.image-item {
			margin-left: 7px;
			margin-right: 7px;
			opacity: 0.3;
			margin-bottom: 7px;
			align-self: flex-start;
			width: 367px;
			height: 238px;
			cursor: pointer;
			border: 3px solid transparent;

			&:hover {
				border: 3px solid green;
				opacity: 1.0;
			}

			&.selected {
				border: 3px solid green;
				opacity: 1.0;
			}
		}

		.upload-image {
			@extend .image-item;
			text-align: center;
			padding-top: 60px;

			.upload-text {
				font-size: 20px;
				
				.upload-icon {
					top: 5px;
					position: relative;
				}
			}
			.upload-help-text {
				font-size: 14px;
			}
		}
	}
}
