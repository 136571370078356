
.delete-modal {
	.ok-button {
		background: $errorColour;
		color: white;

		&:hover {
			background: #791d1a;
		}
	}
}
