.course-card {
	height: 100%;
	margin: 0;
	font-size: 15px;

	.card-body {
		height: 100%;
		display: flex;
		flex-direction: column;

		.header {
			display: flex;
			margin-bottom: 10px;

			> img {
				margin-right: 5px;
				align-self: center;
				max-height: 64px;
				max-width: 64px;
			}

			.h4 {
				font-weight: bold;
			}

			.h5 {
				margin: 0;
			}

			span {
			}
		}

		.description {
			flex-grow: 1;
			max-height: 300px;
			overflow: hidden;
			margin-bottom: auto;
		}

		.footer {
			margin-top: 10px;
		}
	}
} 