.chat {
	.card-body {
		padding: 0;
	}

	.message-editor {
		padding: 20px;
	}

	.message-section {
		padding: 20px;
        min-width: 500px;

		.message-list {
			margin-bottom: 5px;
			padding: 0px;
			
			li {
				display: flex;
		
				&.hide-author {
					.dih-avatar, .content span.h5 {
						display: none;
						margin-top: 0;
					}
		
					.content {
						margin-left: 45px;
					}
	
					.time {
						margin: 0;
					}
				}
	
				&.new-author:not(:first-child) {
					.dih-avatar {
						margin-top: 15px;
					}
	
					.content {
						padding-top: 15px;
					}
				}
		
				.dih-avatar {
					vertical-align: top;
					display: inline-block;
				}
		
				.content {
					width: calc(100% - 140px);
					display: inline-block;
					padding: 0 0 10px 10px;
		
					h5 {
						color: #555;
					}
					
					.box {
						background-color: #cfecfe;
						display: inline-block;
						border-radius: 4px;
						padding: 10px;
						width: 100%;
						white-space: pre-line;
					}
		
					&.odd {
						text-align: right;
		
						.box {
							background-color: #f6f6f6;
						}
					}
	
					.remove {
						display: none;
						cursor: pointer;
						float: right;
						position: relative;
	
						> svg {
							font-size: 17px;
							color: #ccc;
						}
					}
	
					&:hover {
						.remove {
							display: inline-block;
						}
	
						+ .message-time {
							position: relative;
							top: -12px;
						}
					} 
				}
		
				.time {
					display: inline-block;
					text-align: right;
					width: 80px;
					font-size: 13px;
					color: #555;
					margin-top: 26px;
					align-self: center;
					padding-bottom: 10px;
				}
			}
		}
	}
}