
.offer-container .offer-details .user-details {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.owner-avatar {
		margin: 0 1rem;
	}

	.details-box {
		small {
			display: block;
		}

		.owner-name {
			color: #007C9A;
			font-size: 100%;
			font-weight: 300;

			svg {
				cursor: pointer;
				font-size: 20px;
				vertical-align: middle;
			}
		}
	}

	.registration-box {
		margin-left: auto;
		text-align: right;
		white-space: pre;
	}
}