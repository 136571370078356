
.row {
	&.application {
		.user-avatar {
			padding: 0.5rem;
		}

		.comment-text {
			padding-top: 0.5rem;

			.details-box {
				margin-bottom: 15px;
	
				span {
					display: block;
				}
	
				.user-name {
					color: #007C9A;
					font-size: 100%;
					font-weight: 300;
				}
			}
		}
	}
}
