
.ld-resource-card {
	height: 100%;
	text-align: center;
	border: 1px solid rgba(120, 130, 140, 0.13);
	padding: 15px;
	position: relative;
}

.ld-resource-card__remove {
	position: absolute !important;
	top: 0;
	right: 0;	
}
