.feature-box {
	height: 100%;

	> a {
		flex-shrink: 0;
	}

	.pcard-body {
		display: flex;
		flex-direction: column;

		> p {
			flex-grow: 1;
		}
	}
}

.drag-feature {
	height: 100%;
	cursor: grab;
}