
.data-table {
	overflow-x: auto;
	margin: 10px 0;

	th, td {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}