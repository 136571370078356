@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
/* Digital DNA branding Colours */
html {
  position: relative;
  height: 100%;
  background: #fff;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #555;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1.6;
  text-align: left;
  height: 100%;
}

#root {
  height: 100%;
}

.content-wrapper {
  display: flex;
  flex-grow: 1;
  margin-top: 60px;
  flex: 1 0 auto;
  width: 100%;
  background: #FBFBFB;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  min-height: 1px;
}

img {
  vertical-align: middle;
  border-style: none;
}

a {
  color: #006fe6;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

strong {
  font-weight: bold;
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  color: #455a64;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

h1, .h1 {
  line-height: 40px;
  font-size: 36px;
}

h2, .h2 {
  line-height: 36px;
  font-size: 24px;
}

h3, .h3 {
  line-height: 30px;
  font-size: 21px;
}

h4, .h4 {
  line-height: 22px;
  font-size: 18px;
}

h5, .h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
}

h6, .h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p {
  margin-top: 0;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

* {
  outline: none;
}

.dih-input textarea {
  min-height: 21px;
}

table {
  border-collapse: collapse;
}

.quill .ql-container {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}
.quill .ql-container:not(.ql-disabled) > .ql-editor {
  min-height: 135px;
  max-height: 300px;
}
.quill .ql-container:not(.ql-disabled) > .ql-editor::before {
  font-style: normal;
  padding-right: 12px;
  max-height: 110px;
  overflow: hidden;
}

section {
  margin-bottom: 20px;
}

/* Truncate text solution found: http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/ */
p.truncate-text {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.6em;
  /* max-height = line-height (1.6) * lines max number (2) */
  max-height: 3.2em !important;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
p.truncate-text:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
p.truncate-text:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

.pull-right {
  float: right;
}

/*******************
Paddings
*******************/
html body .p-0 {
  padding: 0px;
}

html body .p-10 {
  padding: 10px;
}

html body .p-20 {
  padding: 20px;
}

html body .p-30 {
  padding: 30px;
}

html body .p-l-0 {
  padding-left: 0px;
}

html body .p-l-10 {
  padding-left: 10px;
}

html body .p-l-20 {
  padding-left: 20px;
}

html body .p-r-0 {
  padding-right: 0px;
}

html body .p-r-10 {
  padding-right: 10px;
}

html body .p-r-20 {
  padding-right: 20px;
}

html body .p-r-30 {
  padding-right: 30px;
}

html body .p-r-40 {
  padding-right: 40px;
}

html body .p-t-0 {
  padding-top: 0px;
}

html body .p-t-10 {
  padding-top: 10px;
}

html body .p-t-20 {
  padding-top: 20px;
}

html body .p-t-30 {
  padding-top: 30px;
}

html body .p-b-0 {
  padding-bottom: 0px;
}

html body .p-b-5 {
  padding-bottom: 5px;
}

html body .p-b-10 {
  padding-bottom: 10px;
}

html body .p-b-20 {
  padding-bottom: 20px;
}

html body .p-b-30 {
  padding-bottom: 30px;
}

html body .p-b-40 {
  padding-bottom: 40px;
}

/*******************
Margin
*******************/
html body .m-0 {
  margin: 0px;
}

html body .m-l-5 {
  margin-left: 5px;
}

html body .m-l-10 {
  margin-left: 10px;
}

html body .m-l-15 {
  margin-left: 15px;
}

html body .m-l-20 {
  margin-left: 20px;
}

html body .m-l-30 {
  margin-left: 30px;
}

html body .m-l-40 {
  margin-left: 40px;
}

html body .m-r-5 {
  margin-right: 5px;
}

html body .m-r-10 {
  margin-right: 10px;
}

html body .m-r-15 {
  margin-right: 15px;
}

html body .m-r-20 {
  margin-right: 20px;
}

html body .m-r-30 {
  margin-right: 30px;
}

html body .m-r-40 {
  margin-right: 40px;
}

html body .m-t-0 {
  margin-top: 0px;
}

html body .m-t-5 {
  margin-top: 5px;
}

html body .m-t-10 {
  margin-top: 10px;
}

html body .m-t-15 {
  margin-top: 15px;
}

html body .m-t-20 {
  margin-top: 20px;
}

html body .m-t-30 {
  margin-top: 30px;
}

html body .m-t-40 {
  margin-top: 40px;
}

html body .m-b-0 {
  margin-bottom: 0px;
}

html body .m-b-5 {
  margin-bottom: 5px;
}

html body .m-b-10 {
  margin-bottom: 10px;
}

html body .m-b-15 {
  margin-bottom: 15px;
}

html body .m-b-20 {
  margin-bottom: 20px;
}

html body .m-b-30 {
  margin-bottom: 30px;
}

html body .m-b-40 {
  margin-bottom: 40px;
}

/*******************
vertical alignment
*******************/
html body .vt {
  vertical-align: top;
}

html body .vm {
  vertical-align: middle;
}

html body .vb {
  vertical-align: bottom;
}

/*******************
Opacity
*******************/
.op-5 {
  opacity: 0.5;
}

/*******************
font weight
*******************/
html body .font-bold {
  font-weight: 700;
}

html body .font-normal {
  font-weight: normal;
}

html body .font-light {
  font-weight: 300;
}

html body .font-medium {
  font-weight: 500;
}

html body .font-16 {
  font-size: 16px;
}

html body .font-14 {
  font-size: 14px;
}

html body .font-10 {
  font-size: 10px;
}

html body .font-18 {
  font-size: 18px;
}

html body .font-20 {
  font-size: 20px;
}

/*******************
Border
*******************/
html body .b-0 {
  border: none;
}

html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

/*******************
Text Colors
*******************/
.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #fc4b6c !important;
}

.text-muted {
  color: hsl(200deg, 15%, 65%) !important;
}

.text-warning {
  color: #ffb22b !important;
}

.text-success {
  color: #26c6da !important;
}

.text-info {
  color: #0091b4 !important;
}

.text-inverse {
  color: #2f3d4a !important;
}

html body .text-blue {
  color: #02bec9;
}

html body .text-purple {
  color: #7460ee;
}

html body .text-primary {
  color: #7460ee;
}

html body .text-megna {
  color: #00897b;
}

html body .text-dark {
  color: #67757c;
}

html body .text-themecolor {
  color: #009efb;
}

.text-themecolor {
  color: #007C9A !important;
}

.text-muted {
  color: #555 !important;
}

.text-link {
  text-decoration: underline;
}

/*******************
Rounds
*******************/
.round, .main-layout .page-layout .page-header .widgets .stat .stat-icon, .login-layout .page-layout .page-header .widgets .stat .stat-icon, .anonymous-layout .page-layout .page-header .widgets .stat .stat-icon {
  line-height: 48px;
  color: #ffffff;
  width: 45px;
  height: 45px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #0091b4;
}

.round img, .main-layout .page-layout .page-header .widgets .stat .stat-icon img, .login-layout .page-layout .page-header .widgets .stat .stat-icon img, .anonymous-layout .page-layout .page-header .widgets .stat .stat-icon img {
  border-radius: 100%;
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.round.round-info, .main-layout .page-layout .page-header .widgets .stat .round-info.stat-icon, .login-layout .page-layout .page-header .widgets .stat .round-info.stat-icon, .anonymous-layout .page-layout .page-header .widgets .stat .round-info.stat-icon {
  background: #0091b4;
}

.round.round-warning, .main-layout .page-layout .page-header .widgets .stat .round-warning.stat-icon, .login-layout .page-layout .page-header .widgets .stat .round-warning.stat-icon, .anonymous-layout .page-layout .page-header .widgets .stat .round-warning.stat-icon {
  background: #ffb22b;
}

.round.round-danger, .main-layout .page-layout .page-header .widgets .stat .round-danger.stat-icon, .login-layout .page-layout .page-header .widgets .stat .round-danger.stat-icon, .anonymous-layout .page-layout .page-header .widgets .stat .round-danger.stat-icon {
  background: #fc4b6c;
}

.round.round-success, .main-layout .page-layout .page-header .widgets .stat .round-success.stat-icon, .login-layout .page-layout .page-header .widgets .stat .round-success.stat-icon, .anonymous-layout .page-layout .page-header .widgets .stat .round-success.stat-icon {
  background: #26c6da;
}

.round.round-primary, .main-layout .page-layout .page-header .widgets .stat .round-primary.stat-icon, .login-layout .page-layout .page-header .widgets .stat .round-primary.stat-icon, .anonymous-layout .page-layout .page-header .widgets .stat .round-primary.stat-icon {
  background: #7460ee;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

ul.list-inline {
  padding-left: 0;
  list-style: none;
}
ul.list-inline li {
  display: inline-block;
  padding: 0 8px;
}

/*******************
Labels
*******************/
.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.label-rounded, .my-community .feed .problem-row .body .views {
  border-radius: 60px;
}

.label-custom {
  background-color: #00897b;
}

.label-success {
  background-color: #26c6da;
}

.label-info {
  background-color: #0091b4;
}

.label-warning {
  background-color: #ffb22b;
}

.label-danger {
  background-color: #fc4b6c;
}

.label-megna {
  background-color: #00897b;
}

.label-primary {
  background-color: #7460ee;
}

.label-purple {
  background-color: #7460ee;
}

.label-red {
  background-color: #fb3a3a;
}

.label-inverse {
  background-color: #2f3d4a;
}

.label-default {
  background-color: #f2f4f8;
}

.label-white {
  background-color: #ffffff;
}

.label-light-success {
  background-color: #e8fdeb;
  color: #26c6da;
}

.label-light-info, .my-community .feed .problem-row .body .views {
  background-color: #cfecfe;
  color: #006680;
}

.label-light-warning {
  background-color: #fff8ec;
  color: #ffb22b;
}

.label-light-danger {
  background-color: #f9e7eb;
  color: #fc4b6c;
}

.label-light-megna {
  background-color: #e0f2f4;
  color: #00897b;
}

.label-light-primary {
  background-color: #f1effd;
  color: #7460ee;
}

.label-light-inverse {
  background-color: #f6f6f6;
  color: #2f3d4a;
}

.label {
  display: inline-block;
}

/******************
Ribbons
******************/
.ribbon-wrapper,
.ribbon-wrapper-reverse,
.ribbon-wrapper-bottom,
.ribbon-wrapper-right-bottom {
  position: relative;
  padding: 50px 15px 15px 15px;
}

.ribbon-vwrapper {
  padding: 15px 15px 15px 50px;
  position: relative;
}

.ribbon-overflow {
  overflow: hidden;
}

.ribbon-vwrapper-reverse {
  padding: 15px 50px 15px 15px;
}

.ribbon-wrapper-bottom {
  padding: 15px 15px 50px 50px;
}

.ribbon-wrapper-right-bottom {
  padding: 15px 50px 50px 15px;
}

.ribbon-content {
  margin-bottom: 0px;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: -2px;
  color: #ffffff;
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 15px solid #263238;
  border-right: 10px solid transparent;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #263238;
  border-left: 10px solid transparent;
}

.ribbon-vertical-l,
.ribbon-vertical-r {
  clear: none;
  padding: 0 5px;
  height: 60px;
  width: 30px;
  line-height: 60px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-r {
  left: auto;
  right: 12px;
}

.ribbon-bookmark.ribbon-vertical-l:before,
.ribbon-bookmark.ribbon-vertical-r:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #263238;
  border-bottom: 10px solid transparent;
}

.ribbon-badge {
  top: 15px;
  overflow: hidden;
  left: -90px;
  width: 100%;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-badge.ribbon-right {
  left: auto;
  right: -90px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: 15px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-right.ribbon-bottom {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-corner {
  top: 0;
  left: 0;
  background-color: transparent !important;
  padding: 6px 0 0 10px;
}

.ribbon-corner i {
  position: relative;
}

.ribbon-corner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 30px solid transparent;
  border-top-color: #009efb;
  border-left-color: #009efb;
}

.ribbon-corner.ribbon-right:before {
  right: 0;
  left: auto;
  border-right-color: #526069;
  border-left-color: transparent;
}

.ribbon-corner.ribbon-right {
  right: 0;
  left: auto;
  padding: 6px 10px 0 0;
}

.ribbon-corner.ribbon-bottom:before {
  top: auto;
  bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #526069;
}

.ribbon-corner.ribbon-bottom {
  bottom: 0;
  top: auto;
  padding: 0 10px 6px 10px;
}

.ribbon-custom {
  background: #009efb;
}

.ribbon-bookmark.ribbon-right.ribbon-custom:before {
  border-right-color: #009efb;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-custom:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-custom:before {
  border-right-color: #009efb;
  border-bottom-color: transparent;
}

.ribbon-primary {
  background: #7460ee;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #7460ee;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #7460ee;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-primary:before {
  border-right-color: #7460ee;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #7460ee;
  border-left-color: #7460ee;
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #7460ee;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #7460ee;
}

.ribbon-success {
  background: #74bf44;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #74bf44;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #74bf44;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-success:before {
  border-right-color: #74bf44;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #74bf44;
  border-left-color: #74bf44;
}

.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #74bf44;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #74bf44;
}

.ribbon-info {
  background: #0091b4;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #0091b4;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #0091b4;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-info:before {
  border-right-color: #0091b4;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #0091b4;
  border-left-color: #0091b4;
}

.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #0091b4;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #0091b4;
}

.ribbon-warning {
  background: #ffb22b;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #ffb22b;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #ffb22b;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-warning:before {
  border-right-color: #ffb22b;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #ffb22b;
  border-left-color: #ffb22b;
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #ffb22b;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ffb22b;
}

.ribbon-danger {
  background: #fc4b6c;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #fc4b6c;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #fc4b6c;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-danger:before {
  border-right-color: #fc4b6c;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #fc4b6c;
  border-left-color: #fc4b6c;
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #fc4b6c;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #fc4b6c;
}

.ribbon-default {
  background: #263238;
}

.ribbon-bookmark.ribbon-default:before {
  border-color: #263238;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-default:before {
  border-right-color: #263238;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-default:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-default:before {
  border-right-color: #263238;
  border-bottom-color: transparent;
}

.ribbon-default.ribbon-corner:before {
  border-top-color: #263238;
  border-left-color: #263238;
}

.ribbon-default.ribbon-corner.ribbon-right:before {
  border-right-color: #263238;
  border-left-color: transparent;
}

.ribbon-default.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #263238;
}

/******************
Badges
******************/
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #27a9e1;
}

.badge-info {
  background-color: #0091b4;
}

.badge-primary {
  background-color: #7460ee;
}

.badge-warning {
  background-color: #ffb22b;
  color: #ffffff;
}

.badge-danger {
  background-color: #fc4b6c;
}

.badge-purple {
  background-color: #7460ee;
}

.badge-red {
  background-color: #fb3a3a;
}

.badge-inverse {
  background-color: #2f3d4a;
}

/******************
	Comment
******************/
.comment-widgets {
  position: relative;
  margin-bottom: 10px;
}

.comment-widgets .comment-row {
  border-left: 3px solid #ffffff;
  padding: 15px;
}

.comment-widgets .comment-row:hover,
.comment-widgets .comment-row.active {
  border-color: #009efb;
}

.comment-text {
  overflow: hidden;
  padding: 15px 15px 15px 20px;
}

.comment-text:hover .comment-footer .action-icons,
.comment-text.active .comment-footer .action-icons {
  visibility: visible;
}

.comment-text p {
  max-height: 50px;
  overflow: hidden;
}

.comment-footer .action-icons {
  visibility: hidden;
}

.action-icons a {
  margin: 0 7px;
  vertical-align: middle;
  color: #555;
  cursor: pointer;
}

.action-icons svg {
  vertical-align: text-top;
  font-size: 17px;
}

.action-icons a:hover,
.action-icons a.active {
  color: #0091b4;
}

/*******************
	Table Cell
*******************/
.table, .report-container .report-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th, .report-container .report-table th,
.table td,
.report-container .report-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th, .report-container .report-table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody, .report-container .report-table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table, .report-container .report-table .table, .table .report-container .report-table, .report-container .table .report-table, .report-container .report-table .report-table {
  background-color: #f5f8fa;
}

.table-sm th, .report-container .report-table th,
.table-sm td,
.report-container .report-table td {
  padding: 0.3rem;
}

.table-bordered, .report-container .report-table {
  border: 1px solid #dee2e6;
}

.table-bordered th, .report-container .report-table th,
.table-bordered td,
.report-container .report-table td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th, .report-container .report-table thead th,
.table-bordered thead td,
.report-container .report-table thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th, .report-container .report-table .thead-dark th {
  color: #f5f8fa;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th, .report-container .report-table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #f5f8fa;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered, .report-container .table-dark.report-table {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered, .report-container .table-responsive-sm > .report-table {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered, .report-container .table-responsive-md > .report-table {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered, .report-container .table-responsive-lg > .report-table {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered, .report-container .table-responsive-xl > .report-table {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered, .report-container .table-responsive > .report-table {
  border: 0;
}

.table-box {
  display: table;
  width: 100%;
}

.table.no-border tbody td, .report-container .no-border.report-table tbody td {
  border: 0px;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.table td, .report-container .report-table td,
.table th,
.report-container .report-table th {
  border-color: #f3f1f1;
}

.table thead th,
.table th,
.report-container .report-table th {
  font-weight: 500;
}

.table-hover tbody tr:hover {
  background: #f2f4f8;
}

.v-middle td,
.v-middle th {
  vertical-align: middle;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.stylish-table thead th,
.stylish-table tr.header-row td {
  font-weight: 400;
  color: #555;
  border: 0px;
  border-bottom: 1px;
}

.stylish-table tbody tr {
  border-left: 4px solid #ffffff;
}

.stylish-table tbody tr:hover,
.stylish-table tbody tr.active {
  border-left: 4px solid #009efb;
}

.stylish-table tbody td {
  vertical-align: middle;
}

.stylish-table tbody td h6,
.stylish-table tbody td .h6 {
  font-weight: 500;
  margin-bottom: 0px;
  white-space: nowrap;
}

.stylish-table tbody td small {
  line-height: 12px;
  white-space: nowrap;
}

.vote-actions {
  padding: 0.5rem 0.5rem 0;
}
.vote-actions i {
  color: #0091b4 !important;
}
.vote-actions div.vote-count {
  font-size: 1.5em;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid rgba(120, 130, 140, 0.13);
  margin-bottom: 10px;
  padding: 15px;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

/*******************
List-style-none
******************/
ul.list-style-none {
  margin: 0px;
  padding: 0px;
}

ul.list-style-none li {
  list-style: none;
}

ul.list-style-none li a {
  color: #67757c;
  padding: 8px 0px;
  display: block;
  text-decoration: none;
}

ul.list-style-none li a:hover {
  color: #009efb;
}

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

@media (min-width: 1600px) {
  .col-xlg-1,
  .col-xlg-10,
  .col-xlg-11,
  .col-xlg-12,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9 {
    float: left;
  }
  .col-xlg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xlg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xlg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xlg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xlg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xlg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xlg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xlg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xlg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xlg-pull-12 {
    right: 100%;
  }
  .col-xlg-pull-11 {
    right: 91.66666667%;
  }
  .col-xlg-pull-10 {
    right: 83.33333333%;
  }
  .col-xlg-pull-9 {
    right: 75%;
  }
  .col-xlg-pull-8 {
    right: 66.66666667%;
  }
  .col-xlg-pull-7 {
    right: 58.33333333%;
  }
  .col-xlg-pull-6 {
    right: 50%;
  }
  .col-xlg-pull-5 {
    right: 41.66666667%;
  }
  .col-xlg-pull-4 {
    right: 33.33333333%;
  }
  .col-xlg-pull-3 {
    right: 25%;
  }
  .col-xlg-pull-2 {
    right: 16.66666667%;
  }
  .col-xlg-pull-1 {
    right: 8.33333333%;
  }
  .col-xlg-pull-0 {
    right: auto;
  }
  .col-xlg-push-12 {
    left: 100%;
  }
  .col-xlg-push-11 {
    left: 91.66666667%;
  }
  .col-xlg-push-10 {
    left: 83.33333333%;
  }
  .col-xlg-push-9 {
    left: 75%;
  }
  .col-xlg-push-8 {
    left: 66.66666667%;
  }
  .col-xlg-push-7 {
    left: 58.33333333%;
  }
  .col-xlg-push-6 {
    left: 50%;
  }
  .col-xlg-push-5 {
    left: 41.66666667%;
  }
  .col-xlg-push-4 {
    left: 33.33333333%;
  }
  .col-xlg-push-3 {
    left: 25%;
  }
  .col-xlg-push-2 {
    left: 16.66666667%;
  }
  .col-xlg-push-1 {
    left: 8.33333333%;
  }
  .col-xlg-push-0 {
    left: auto;
  }
  .offset-xlg-12 {
    margin-left: 100%;
  }
  .offset-xlg-11 {
    margin-left: 91.66666667%;
  }
  .offset-xlg-10 {
    margin-left: 83.33333333%;
  }
  .offset-xlg-9 {
    margin-left: 75%;
  }
  .offset-xlg-8 {
    margin-left: 66.66666667%;
  }
  .offset-xlg-7 {
    margin-left: 58.33333333%;
  }
  .offset-xlg-6 {
    margin-left: 50%;
  }
  .offset-xlg-5 {
    margin-left: 41.66666667%;
  }
  .offset-xlg-4 {
    margin-left: 33.33333333%;
  }
  .offset-xlg-3 {
    margin-left: 25%;
  }
  .offset-xlg-2 {
    margin-left: 16.66666667%;
  }
  .offset-xlg-1 {
    margin-left: 8.33333333%;
  }
  .offset-xlg-0 {
    margin-left: 0;
  }
}
.col-xlg-1,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333333%;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66666667%;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333333%;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66666667%;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333333%;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66666667%;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .card .card-header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.row-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center, .main-layout .page-layout .page-header .widgets .stat .stat-text, .login-layout .page-layout .page-header .widgets .stat .stat-text, .anonymous-layout .page-layout .page-header .widgets .stat .stat-text {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #555 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table, .report-container .report-table {
    border-collapse: collapse !important;
  }
  .table td, .report-container .report-table td,
  .table th,
  .report-container .report-table th {
    background-color: #fff !important;
  }
  .table-bordered th, .report-container .report-table th,
  .table-bordered td,
  .report-container .report-table td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th, .report-container .report-table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.pcard {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.pcard > hr {
  margin-right: 0;
  margin-left: 0;
}

.pcard > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.pcard > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pcard-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.pcard-title, .pcard-title-5 {
  margin-bottom: 0.75rem;
  display: block;
}

.pcard-title-5 {
  line-height: 18px;
  font-size: 16px;
}

.pcard-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.pcard-text:last-child {
  margin-bottom: 0;
}

.pcard-link:hover {
  text-decoration: none;
}

.pcard-link + .pcard-link {
  margin-left: 1.25rem;
}

.pcard-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.pcard-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.pcard-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.pcard-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.pcard-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.pcard-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.pcard-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.pcard-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.pcard-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.pcard-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.pcard-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.pcard-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pcard-deck .pcard {
  margin-bottom: 15px;
  overflow: hidden;
}

@media (min-width: 600px) {
  .pcard-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .pcard-deck .pcard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.pcard-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pcard-group > .pcard {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .pcard-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .pcard-group > .pcard {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .pcard-group > .pcard + .pcard {
    margin-left: 0;
    border-left: 0;
  }
  .pcard-group > .pcard:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .pcard-group > .pcard:first-child .pcard-img-top,
  .pcard-group > .pcard:first-child .pcard-header {
    border-top-right-radius: 0;
  }
  .pcard-group > .pcard:first-child .pcard-img-bottom,
  .pcard-group > .pcard:first-child .pcard-footer {
    border-bottom-right-radius: 0;
  }
  .pcard-group > .pcard:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .pcard-group > .pcard:last-child .pcard-img-top,
  .pcard-group > .pcard:last-child .pcard-header {
    border-top-left-radius: 0;
  }
  .pcard-group > .pcard:last-child .pcard-img-bottom,
  .pcard-group > .pcard:last-child .pcard-footer {
    border-bottom-left-radius: 0;
  }
  .pcard-group > .pcard:only-child {
    border-radius: 0.25rem;
  }
  .pcard-group > .pcard:only-child .pcard-img-top,
  .pcard-group > .pcard:only-child .pcard-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .pcard-group > .pcard:only-child .pcard-img-bottom,
  .pcard-group > .pcard:only-child .pcard-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) .pcard-img-top,
  .pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) .pcard-img-bottom,
  .pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) .pcard-header,
  .pcard-group > .pcard:not(:first-child):not(:last-child):not(:only-child) .pcard-footer {
    border-radius: 0;
  }
}
.pcard-columns .pcard {
  margin-bottom: 0.75rem;
}

.main-layout, .login-layout, .anonymous-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.main-layout .page-layout, .login-layout .page-layout, .anonymous-layout .page-layout {
  width: 100%;
  margin: 0 auto 0 270px;
  padding: 0 15px;
}
.main-layout .page-layout .page-header, .login-layout .page-layout .page-header, .anonymous-layout .page-layout .page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -15px;
  padding: 15px 0 20px;
}
.main-layout .page-layout .page-header .page-title, .login-layout .page-layout .page-header .page-title, .anonymous-layout .page-layout .page-header .page-title {
  padding: 0 15px;
  flex-shrink: 0;
}
.main-layout .page-layout .page-header .page-title h1, .login-layout .page-layout .page-header .page-title h1, .anonymous-layout .page-layout .page-header .page-title h1 {
  margin-bottom: 0px;
  margin-top: 0px;
}
.main-layout .page-layout .page-header .breadcrumb, .login-layout .page-layout .page-header .breadcrumb, .anonymous-layout .page-layout .page-header .breadcrumb {
  padding: 0px;
  background: transparent;
  font-size: 14px;
  margin-bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
}
.main-layout .page-layout .page-header .breadcrumb li, .login-layout .page-layout .page-header .breadcrumb li, .anonymous-layout .page-layout .page-header .breadcrumb li {
  margin-top: 0px;
  margin-bottom: 0px;
}
.main-layout .page-layout .page-header .breadcrumb .breadcrumb-item a, .login-layout .page-layout .page-header .breadcrumb .breadcrumb-item a, .anonymous-layout .page-layout .page-header .breadcrumb .breadcrumb-item a {
  color: #007C9A;
}
.main-layout .page-layout .page-header .breadcrumb .breadcrumb-item.active, .login-layout .page-layout .page-header .breadcrumb .breadcrumb-item.active, .anonymous-layout .page-layout .page-header .breadcrumb .breadcrumb-item.active {
  color: #555;
}
.main-layout .page-layout .page-header .breadcrumb .breadcrumb-item .breadcrumb-divider, .login-layout .page-layout .page-header .breadcrumb .breadcrumb-item .breadcrumb-divider, .anonymous-layout .page-layout .page-header .breadcrumb .breadcrumb-item .breadcrumb-divider {
  padding: 0 0.5rem;
}
.main-layout .page-layout .page-header .widgets, .login-layout .page-layout .page-header .widgets, .anonymous-layout .page-layout .page-header .widgets {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .main-layout .page-layout .page-header .widgets, .login-layout .page-layout .page-header .widgets, .anonymous-layout .page-layout .page-header .widgets {
    display: none;
  }
}
.main-layout .page-layout .page-header .widgets .stat, .login-layout .page-layout .page-header .widgets .stat, .anonymous-layout .page-layout .page-header .widgets .stat {
  display: flex;
  margin: 0 10px 10px;
}
.main-layout .page-layout .page-header .widgets .stat .stat-icon, .login-layout .page-layout .page-header .widgets .stat .stat-icon, .anonymous-layout .page-layout .page-header .widgets .stat .stat-icon {
  padding-top: 6px;
  min-width: 45px;
  cursor: pointer;
}
.main-layout .page-layout .page-header .widgets .stat .stat-text, .login-layout .page-layout .page-header .widgets .stat .stat-text, .anonymous-layout .page-layout .page-header .widgets .stat .stat-text {
  margin-left: 10px;
}
.main-layout .page-layout .page-header .widgets .stat .stat-text span, .login-layout .page-layout .page-header .widgets .stat .stat-text span, .anonymous-layout .page-layout .page-header .widgets .stat .stat-text span {
  margin: 0;
}
.main-layout .page-layout .page-content, .login-layout .page-layout .page-content, .anonymous-layout .page-layout .page-content {
  margin-bottom: 20px;
  font-size: medium;
}
.main-layout .page-layout.side-menu-collapsed, .login-layout .page-layout.side-menu-collapsed, .anonymous-layout .page-layout.side-menu-collapsed {
  margin: 0 auto 0 90px;
}

@media (min-width: 768px) {
  .main-layout .page-layout:not(.login-view), .login-layout .page-layout:not(.login-view), .anonymous-layout .page-layout:not(.login-view) {
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .main-layout .page-layout, .login-layout .page-layout, .anonymous-layout .page-layout {
    margin: 0;
  }
}
.main-layout.is-public-link .page-layout, .is-public-link.login-layout .page-layout, .is-public-link.anonymous-layout .page-layout {
  padding-top: 0px;
}

.navbar {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 20;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  padding-top: 60px;
}
.navbar .nav-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}
.navbar .nav-wrapper .navbar-scroll .track-horizontal {
  background-color: white;
  border-radius: inherit;
  opacity: 0.9;
  cursor: pointer;
}
.navbar .nav-wrapper svg {
  color: #fff;
}
.navbar .nav-wrapper .navbar-nav {
  padding: 6px 0;
  height: 62px;
  overflow: hidden;
}
.navbar .nav-wrapper .nav-link {
  text-transform: none;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  box-shadow: none;
  padding: 13px;
  display: inline-flex;
}
.navbar .nav-wrapper .nav-link:last-child {
  margin-right: 0;
}
.navbar .nav-wrapper .nav-link svg {
  margin-right: 10px;
}
.navbar .nav-wrapper .appbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-box;
  margin: 0;
  padding: 6px 10px;
  list-style: none;
}
.navbar .nav-wrapper .appbar-nav .nav-link {
  height: 50px;
}
.navbar .nav-wrapper .appbar-nav .nav-link svg {
  margin: 0;
}
.navbar .nav-wrapper .appbar-nav #nav-notifications {
  position: relative;
}
.navbar .nav-wrapper .appbar-nav #nav-notifications .notifications-panel {
  position: absolute;
  right: 0;
  width: 300px;
}
.navbar .nav-wrapper .appbar-nav #nav-notifications .notifications-panel > a {
  border-radius: 0 0 4px 4px;
}
.navbar .nav-wrapper .appbar-nav #nav-profile {
  position: relative;
}
.navbar .nav-wrapper .appbar-nav #nav-profile button {
  padding-top: 10px;
}
.navbar .nav-wrapper .appbar-nav #nav-profile .profile-panel {
  position: absolute;
  right: 0;
  width: 300px;
}
.navbar .nav-wrapper .appbar-nav #nav-profile .profile-panel .user-box {
  padding: 15px 15px 0;
  text-align: center;
}
.navbar .nav-wrapper .appbar-nav #nav-profile .profile-panel .user-box .user-details {
  padding: 8px 0;
}
.navbar .nav-wrapper .appbar-nav #nav-profile .profile-panel svg {
  color: inherit;
}

#nav-dropdown {
  z-index: 20;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
}
#nav-dropdown .menu-item {
  font-weight: 300;
  color: #5e7a87;
}
#nav-dropdown .sub-header {
  color: #156184;
  line-height: initial;
  position: initial;
  padding-top: 13px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .navbar {
    display: none;
  }
}
#footer {
  padding: 1em;
  background: #2C3E50;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  flex-shrink: 0;
}
#footer h2 {
  left: -9999px;
  position: absolute;
}
#footer .tagline {
  max-width: 100%;
  min-width: 140px;
}
#footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}
#footer .max-width {
  font-size: 75%;
}
#footer li {
  display: inline-block;
  padding: 0.2em 1em;
}
#footer a, #footer a:link, #footer a:visited, #footer p {
  color: #ffffff;
}

@media print {
  .top-bar {
    position: relative !important;
  }
}
.top-bar {
  position: fixed;
  width: 100%;
  max-width: 100vw;
  top: 0;
  z-index: 50;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
}
.top-bar .top-appbar {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 15px;
  height: 60px;
  position: relative;
  text-align: center;
}
.top-bar .top-appbar .appbar-header {
  position: absolute;
  top: 0;
  width: 100%;
}
.top-bar .top-appbar .appbar-header .logo-primary {
  margin-top: 0;
  font-family: "Orbitron", sans-serif;
  font-size: 36px;
  font-weight: bold;
}
.top-bar .top-appbar .appbar-header .appbar-brand .logo, .top-bar .top-appbar .appbar-header .appbar-brand .brand {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
}
.top-bar .top-appbar .appbar-tenant-title {
  width: 100%;
  text-align: right;
  margin: 0;
  margin-right: 10px;
}
.top-bar .top-appbar .subscribe-button {
  margin-left: auto;
}
.top-bar .top-appbar .nav-toggle {
  margin-left: auto;
  display: none;
}
.top-bar .top-appbar .header-tools {
  display: flex;
  flex-direction: row;
  float: right;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  color: white;
}
.top-bar .top-appbar .header-tools .header-links {
  margin: 0;
  padding: 0;
  line-height: 30px;
}
.top-bar .top-appbar .header-tools .header-links li {
  display: inline;
  list-style-type: none;
  padding: 0 0.5em;
}
.top-bar .top-appbar .header-tools .header-links li a {
  color: white;
}
.top-bar .top-appbar .header-tools .header-links li a:hover {
  text-decoration: underline;
}
.top-bar .top-appbar .header-tools .search-form {
  padding-left: 0.5em;
}
.top-bar .top-appbar .header-tools .search-form .search-input {
  height: 30px;
  background-color: #3f7da2;
  border: 0 solid #065687;
  float: left;
  font-size: 12px;
  padding: 8px 0px 8px 5px;
  width: 200px;
  color: white;
}
.top-bar .top-appbar .header-tools .search-form .search-input::placeholder {
  color: inherit;
}
.top-bar .top-appbar .header-tools .search-form .search-button {
  background-color: #3f7da2;
  width: 30px;
  height: 30px;
}
.top-bar .right-nav {
  position: absolute;
  display: flex;
  right: 0;
}
.top-bar .right-nav .notifications-panel {
  width: 300px;
}
.top-bar .right-nav .notifications-panel > a {
  border-radius: 0 0 4px 4px;
}
.top-bar .right-nav .profile-panel {
  width: 300px;
}
.top-bar .right-nav .profile-panel .user-box {
  padding: 15px 15px 0;
  text-align: center;
}
.top-bar .right-nav .profile-panel .user-box .user-details {
  padding: 8px 0;
}
.top-bar .right-nav .profile-panel svg {
  color: inherit;
}
.top-bar .full-menu-toggle {
  display: none;
}

@media (max-width: 767px) {
  .top-bar .top-appbar .appbar-header .appbar-brand img.brand {
    display: none;
  }
  .top-bar .top-appbar .nav-toggle {
    display: flex;
  }
  .top-bar .top-appbar .header-tools {
    display: none;
  }
  .top-bar .top-appbar .subscribe-button {
    display: none;
  }
  .top-bar .top-appbar .right-nav {
    display: none;
  }
  .top-bar .top-appbar .side-menu-toggle {
    display: none;
  }
  .top-bar .top-appbar .full-menu-toggle {
    display: block;
  }
}
.side-menu-toggle, .full-menu-toggle {
  color: #fff;
  z-index: 9;
}

.side-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  background-color: #FFF;
  transition: width 0.3s ease;
  overflow-y: auto;
  flex-shrink: 0;
  padding-top: 10px;
}
.side-menu .menus {
  display: flex;
  height: calc(100% - 58px);
}
.side-menu .menus .top-menu {
  padding: 0px;
  overflow-y: auto;
  border-right: 1px solid #d9d9d9;
}
.side-menu .menus .sub-menu {
  padding-top: 20px;
  padding-left: 5px;
  flex-grow: 1;
  overflow-y: auto;
  width: 190px;
}
.side-menu .side-menu-bottom {
  display: none;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #d9d9d9;
  width: 100%;
  justify-content: end;
}
.side-menu .side-menu-bottom .notifications-panel {
  width: 300px;
}
.side-menu .side-menu-bottom .notifications-panel > a {
  border-radius: 0 0 4px 4px;
}
.side-menu .side-menu-bottom .profile-panel {
  width: 300px;
}
.side-menu .side-menu-bottom .profile-panel .user-box {
  padding: 15px 15px 0;
  text-align: center;
}
.side-menu .side-menu-bottom .profile-panel .user-box .user-details {
  padding: 8px 0;
}
.side-menu .side-menu-bottom .profile-panel svg {
  color: inherit;
}
.side-menu.side-menu-collapsed .sub-menu {
  display: none;
}
.side-menu.side-menu-collapsed .side-menu-bottom {
  display: none;
}

@media (max-width: 767px) {
  .side-menu {
    display: none;
  }
}
.side-panel > div {
  width: 100%;
  margin-top: 60px;
  padding: 10px 0;
  height: calc(100% - 60px);
}
.side-panel .search-form {
  display: flex;
  margin: 0.2em 0.5em;
  padding-left: 0.01em;
}
.side-panel .search-form__input {
  height: 30px;
  font-size: 12px;
  padding: 8px 0px 8px 5px;
  width: 100%;
}
.side-panel .search-form__button {
  background-color: #3f7da2;
  width: 30px;
}
.side-panel .side-panel__menu {
  padding-top: 0;
}

@media (min-width: 768px) {
  .side-panel {
    display: none;
  }
}
.page-banner {
  height: 60px;
  background: #FCF8E3;
  position: fixed;
  width: 100%;
  top: 122px;
  z-index: 10;
  color: #998661;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  line-height: 16px;
}
.page-banner p {
  text-align: center;
  margin: 0;
  padding: 0;
}

.main-layout.show-banner .page-layout, .show-banner.login-layout .page-layout, .show-banner.anonymous-layout .page-layout {
  padding-top: 125px;
}

.login-layout.show-banner .page-banner {
  top: 60px;
}
.login-layout.show-banner .login-container {
  padding-top: 60px;
}

@media (max-width: 767px) {
  .page-banner {
    position: absolute;
    top: 60px;
  }
  .main-layout.show-banner .page-layout, .show-banner.login-layout .page-layout, .show-banner.anonymous-layout .page-layout {
    padding-top: 60px;
  }
}
@media print {
  .navbar {
    display: none;
  }
  .page-banner {
    display: none;
  }
  .content-wrapper {
    background: #fff;
    margin-top: 0;
  }
  .page-layout {
    padding-top: 0px !important;
  }
  a[role=button] {
    display: none;
  }
  button {
    display: none !important;
  }
  .page-header {
    display: none !important;
  }
  .tag {
    color: black !important;
    border: solid 1px black !important;
  }
  .print-show {
    display: initial !important;
  }
  .print-hide {
    display: none !important;
  }
  .print-table-colours {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
.print-show {
  display: none;
}

.academic-card {
  height: 100%;
}
.academic-card > img {
  height: 200px;
}
.academic-card .pcard-body {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.academic-card .pcard-body .organisation > img {
  height: 32px;
  margin-right: 5px;
}
.academic-card .pcard-body .body {
  flex-grow: 1;
}
.academic-card .pcard-body .action-icons {
  margin-top: 10px;
}
.academic-card .pcard-body .action-icons a {
  margin: 0 10px;
}

.activity-card {
  height: 100%;
  margin: 0;
  max-height: 300px;
}
.activity-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.activity-card .card-body .name {
  display: flex;
  align-items: center;
  color: #007C9A;
}
.activity-card .card-body .name svg {
  margin-right: 5px;
}
.activity-card .card-body .summary {
  flex-grow: 1;
  text-overflow: hidden;
}
.activity-card .card-body .tags {
  margin-top: 10px;
}

div.dih-avatar {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
div.dih-avatar img {
  display: block;
}

.dih-button-group .option {
  border-radius: 0;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
}
.dih-button-group .option:first-of-type {
  border-radius: 4px 0 0 4px;
}
.dih-button-group .option:last-of-type {
  border-radius: 0 4px 4px 0;
}
.dih-button-group .option:hover {
  background-color: #007C9A;
  box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
  z-index: 1;
}
.dih-button-group .option.active {
  background-color: #1e7e34;
  border-color: #1e7e34;
  box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
}

.card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0;
  margin-bottom: 30px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
}
.card .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card .card-title {
  display: flex;
  align-items: flex-start;
}
.card .card-title .title {
  flex-grow: 1;
}
.card .card-title .title h2 {
  margin-bottom: 0.45rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card .card-title .title .subtitle {
  font-weight: 300;
  margin-bottom: 20px;
}
.card .card-body {
  padding: 20px;
  flex-shrink: 0;
}

@media (min-width: 576px) {
  .card-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-container .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.accordion-panel {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
}

@media print {
  .card-hide-borders-on-print {
    box-shadow: none !important;
    border: none !important;
  }
}
.card-picker {
  min-width: 250px;
}
.card-picker svg {
  color: rgb(204, 204, 204);
  margin: 0 8px;
}
.card-picker:not(.disabled) input {
  color: initial;
}
.card-picker:not(.disabled) svg {
  cursor: pointer;
}
.card-picker:not(.disabled) svg:hover {
  color: rgb(153, 153, 153);
}

.picker-card {
  cursor: pointer;
}
.picker-card.selected, .picker-card:hover {
  border-color: #84c446;
}
.picker-card img {
  object-fit: contain;
  height: 170px;
}

.centre-card, .business-profile-card {
  height: 100%;
}
.centre-card .pcard-img-top, .business-profile-card .pcard-img-top {
  max-height: 200px;
  padding: 30px 30px 0;
  height: auto;
}
.centre-card .pcard-body, .business-profile-card .pcard-body {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.centre-card .pcard-body .body, .business-profile-card .pcard-body .body {
  flex-grow: 1;
  padding-bottom: 10px;
}

.centre-container .contact-info .card-body, .business-profile-container .contact-info .card-body {
  padding: 0;
}
.centre-container .contact-info .card-body img, .business-profile-container .contact-info .card-body img {
  max-width: 200px;
  max-height: 200px;
}
.centre-container .contact-info .card-body section:not(:first-child), .business-profile-container .contact-info .card-body section:not(:first-child) {
  margin-top: 10px;
}

.chat .card-body {
  padding: 0;
}
.chat .message-editor {
  padding: 20px;
}
.chat .message-section {
  padding: 20px;
  min-width: 500px;
}
.chat .message-section .message-list {
  margin-bottom: 5px;
  padding: 0px;
}
.chat .message-section .message-list li {
  display: flex;
}
.chat .message-section .message-list li.hide-author .dih-avatar, .chat .message-section .message-list li.hide-author .content span.h5 {
  display: none;
  margin-top: 0;
}
.chat .message-section .message-list li.hide-author .content {
  margin-left: 45px;
}
.chat .message-section .message-list li.hide-author .time {
  margin: 0;
}
.chat .message-section .message-list li.new-author:not(:first-child) .dih-avatar {
  margin-top: 15px;
}
.chat .message-section .message-list li.new-author:not(:first-child) .content {
  padding-top: 15px;
}
.chat .message-section .message-list li .dih-avatar {
  vertical-align: top;
  display: inline-block;
}
.chat .message-section .message-list li .content {
  width: calc(100% - 140px);
  display: inline-block;
  padding: 0 0 10px 10px;
}
.chat .message-section .message-list li .content h5 {
  color: #555;
}
.chat .message-section .message-list li .content .box {
  background-color: #cfecfe;
  display: inline-block;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  white-space: pre-line;
}
.chat .message-section .message-list li .content.odd {
  text-align: right;
}
.chat .message-section .message-list li .content.odd .box {
  background-color: #f6f6f6;
}
.chat .message-section .message-list li .content .remove {
  display: none;
  cursor: pointer;
  float: right;
  position: relative;
}
.chat .message-section .message-list li .content .remove > svg {
  font-size: 17px;
  color: #ccc;
}
.chat .message-section .message-list li .content:hover .remove {
  display: inline-block;
}
.chat .message-section .message-list li .content:hover + .message-time {
  position: relative;
  top: -12px;
}
.chat .message-section .message-list li .time {
  display: inline-block;
  text-align: right;
  width: 80px;
  font-size: 13px;
  color: #555;
  margin-top: 26px;
  align-self: center;
  padding-bottom: 10px;
}

.classification-picker {
  user-select: none;
  display: inline-block;
  cursor: pointer;
}
.classification-picker:hover {
  background: #84c446;
  color: #fff;
}
.classification-picker__name {
  font-weight: bold;
  font-size: 20px;
}

.collaboration-row {
  padding: 20px 30px;
  width: 100%;
  overflow: hidden;
  border-left: 3px solid #ffffff;
}
.collaboration-row:hover .row-footer .action-icons, .collaboration-row.active .row-footer .action-icons {
  margin-left: 5px;
  visibility: visible;
}
.collaboration-row .dates {
  font-style: italic;
}
.collaboration-row .tags {
  margin: 5px 0;
}
.collaboration-row .tags .tag {
  margin-right: 2px;
}
.collaboration-row .row-footer svg {
  vertical-align: text-top;
}
.collaboration-row .row-footer .action-icons {
  visibility: hidden;
}

.community-card {
  height: 100%;
}
.community-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.community-card .card-body .description {
  flex-grow: 1;
}
.community-card .card-body .counters a, .community-card .card-body button {
  width: 100%;
}

.content-card {
  height: 100%;
  margin: 0;
}
.content-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content-card .card-body .header {
  display: flex;
  margin-bottom: 10px;
}
.content-card .card-body .header > img {
  margin-right: 5px;
  align-self: center;
  max-height: 64px;
  max-width: 64px;
}
.content-card .card-body .header .h4 {
  font-weight: bold;
}
.content-card .card-body .header .h5 {
  margin: 0;
}
.content-card .card-body .description {
  flex-grow: 1;
  max-height: 300px;
  overflow: hidden;
  margin-bottom: auto;
}
.content-card .card-body .footer {
  margin-top: 10px;
}

.course-card {
  height: 100%;
  margin: 0;
  font-size: 15px;
}
.course-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.course-card .card-body .header {
  display: flex;
  margin-bottom: 10px;
}
.course-card .card-body .header > img {
  margin-right: 5px;
  align-self: center;
  max-height: 64px;
  max-width: 64px;
}
.course-card .card-body .header .h4 {
  font-weight: bold;
}
.course-card .card-body .header .h5 {
  margin: 0;
}
.course-card .card-body .description {
  flex-grow: 1;
  max-height: 300px;
  overflow: hidden;
  margin-bottom: auto;
}
.course-card .card-body .footer {
  margin-top: 10px;
}

.data-table {
  overflow-x: auto;
  margin: 10px 0;
}
.data-table th, .data-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-field .time-input {
  vertical-align: top;
  height: 56px;
  margin-left: 5px;
  font-weight: 400;
  font-family: arial;
  font-size: 19px;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #dbdbdb;
}
.date-field .time-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
.date-field .time-input::-webkit-clear-button {
  display: none; /* Hide the button */
  -webkit-appearance: none; /* turn off default browser styling */
}

.SingleDatePicker_picker__portal {
  z-index: 1300 !important;
}

.drag-list > table {
  width: 100%;
}
.drag-list > table .auto-width {
  width: 40px;
  white-space: nowrap;
  padding: 5px;
}
.drag-list > table .drag-handle {
  vertical-align: middle;
}
.drag-list > table .drag-wrapper > svg {
  margin-right: 5px;
}

@media (max-width: 600px) {
  .drag-list .auto-width {
    white-space: normal;
    text-align: center;
  }
}
.display-field {
  margin: 5px 0;
}
.display-field label {
  margin-right: 5px;
  font-size: 1rem;
  font-weight: 500;
}
.display-field span {
  font-size: 1rem;
}

.event-card {
  height: 100%;
  margin: 0;
}
.event-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.event-card .card-body > .h1 {
  display: flex;
  align-items: center;
}
.event-card .card-body > .h1 svg {
  fill: #74bf44;
  height: 40px;
  width: 40px;
}
.event-card .card-body svg {
  margin-right: 5px;
  vertical-align: middle;
}
.event-card .card-body .content {
  flex-grow: 1;
  margin-bottom: 10px;
}
.event-card .card-body > button {
  align-self: flex-start;
}

.feature-box {
  height: 100%;
}
.feature-box > a {
  flex-shrink: 0;
}
.feature-box .pcard-body {
  display: flex;
  flex-direction: column;
}
.feature-box .pcard-body > p {
  flex-grow: 1;
}

.drag-feature {
  height: 100%;
  cursor: grab;
}

.form-buttons {
  margin-top: 20px;
}
.form-buttons .accept-button {
  background: #35aa47;
  color: white;
}
.form-buttons .accept-button:hover {
  background: #1f6129;
}
.form-buttons .delete-button {
  background: #d1332e;
  color: white;
  margin-left: 10px;
}
.form-buttons .delete-button:hover {
  background: #791d1a;
}

.html-display-field {
  margin: 5px 0;
}
.html-display-field label {
  margin-right: 5px;
  font-size: 1rem;
  font-weight: 500;
}
.html-display-field div {
  font-size: 1rem;
}

.dih-image-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.dih-image-gallery .thumbnail {
  height: 200px;
  margin: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  position: relative;
  cursor: pointer;
}
.dih-image-gallery .thumbnail > img {
  height: 100%;
}

.image-selector .image-list {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-flow: row wrap;
}
.image-selector .image-list .image-item, .image-selector .image-list .upload-image {
  margin-left: 7px;
  margin-right: 7px;
  opacity: 0.3;
  margin-bottom: 7px;
  align-self: flex-start;
  width: 367px;
  height: 238px;
  cursor: pointer;
  border: 3px solid transparent;
}
.image-selector .image-list .image-item:hover, .image-selector .image-list .upload-image:hover {
  border: 3px solid green;
  opacity: 1;
}
.image-selector .image-list .image-item.selected, .image-selector .image-list .selected.upload-image {
  border: 3px solid green;
  opacity: 1;
}
.image-selector .image-list .upload-image {
  text-align: center;
  padding-top: 60px;
}
.image-selector .image-list .upload-image .upload-text {
  font-size: 20px;
}
.image-selector .image-list .upload-image .upload-text .upload-icon {
  top: 5px;
  position: relative;
}
.image-selector .image-list .upload-image .upload-help-text {
  font-size: 14px;
}

.insight-card {
  height: 100%;
}
.insight-card .pcard-body {
  display: flex;
  flex-direction: column;
}
.insight-card .pcard-body .expert {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.insight-card .pcard-body .expert .dih-avatar {
  margin-right: 5px;
}
.insight-card .pcard-body .expert .h5 {
  margin: 0;
}
.insight-card .pcard-body .description {
  flex-grow: 1;
  margin-bottom: 10px;
}
.insight-card .pcard-body .dates {
  font-style: italic;
}
.insight-card .pcard-body .tag {
  align-self: flex-start;
}

.ip-row {
  padding: 20px 30px;
  width: 100%;
  overflow: hidden;
  border-left: 3px solid #ffffff;
}
.ip-row:hover .row-footer .action-icons, .ip-row.active .row-footer .action-icons {
  margin-left: 5px;
  visibility: visible;
}
.ip-row .row-footer svg {
  vertical-align: text-top;
}
.ip-row .row-footer .action-icons {
  visibility: hidden;
}

.delete-modal .ok-button {
  background: #d1332e;
  color: white;
}
.delete-modal .ok-button:hover {
  background: #791d1a;
}

.notifications .notification-row {
  display: flex;
  width: 100%;
  padding: 10px 15px;
}
.notifications .notification-row:not(:first-child) {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
.notifications .notification-row:not(.extended) {
  cursor: pointer;
}
.notifications .notification-row:not(.extended):hover {
  background-color: #f2f4f8;
}
.notifications .notification-row button {
  flex-shrink: 0;
}
.notifications .notification-row button.cyan {
  background: #26c6da;
  border: 1px solid #26c6da;
}
.notifications .notification-row button.green {
  background: #00b499;
  border: 1px solid #00b499;
}
.notifications .notification-row button.blue {
  background: #007C9A;
  border: 1px solid #007C9A;
}
.notifications .notification-row button.red {
  background: #fc4b6c;
  border: 1px solid #fc4b6c;
}
.notifications .notification-row button.purple {
  background: #7460ee;
  border: 1px solid #7460ee;
}
.notifications .notification-row button.lime {
  background: #74bf44;
  border: 1px solid #74bf44;
}
.notifications .notification-row button svg {
  color: white;
}
.notifications .notification-row .content {
  padding-left: 10px;
  flex-grow: 1;
}
.notifications .notification-row .content .description, .notifications .notification-row .content .time {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #67757c;
  white-space: nowrap;
}

.organisation-card {
  margin: 0;
}
.organisation-card .header {
  text-align: center;
  margin-bottom: 10px;
}
.organisation-card .header > img {
  max-height: 150px;
  max-width: 100%;
  margin-bottom: 10px;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin-top: 20px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination > li > a,
.pagination > li > span {
  color: #263238;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #f2f4f8;
}

.pagination-split li {
  margin-left: 5px;
  display: block;
  float: left;
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  border-radius: 4px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #009efb;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=""]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=""]::before {
  content: "Default";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
  content: "Arial";
  font-family: "Arial";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Courier]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Courier]::before {
  content: "Courier";
  font-family: "Courier";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Garamond]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Garamond]::before {
  content: "Garamond";
  font-family: "Garamond";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Tahoma]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Tahoma]::before {
  content: "Tahoma";
  font-family: "Tahoma";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Verdana]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Verdana]::before {
  content: "Verdana";
  font-family: "Verdana";
}

/****************************************************
Set the font-family content used for the HTML content.
*****************************************************/
.ql-font-Arial {
  font-family: "Arial";
}

.ql-font-Courier {
  font-family: "Courier";
}

.ql-font-Garamond {
  font-family: "Garamond";
}

.ql-font-Verdana {
  font-family: "Verdana";
}

.ql-font-Tahoma {
  font-family: "Tahoma";
}

.quill-display-block > * {
  cursor: text;
}

.quill-display-block p,
.quill-display-block ol,
.quill-display-block ul,
.quill-display-block pre,
.quill-display-block blockquote,
.quill-display-block h1,
.quill-display-block h2,
.quill-display-block h3,
.quill-display-block h4,
.quill-display-block h5,
.quill-display-block h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.quill-display-block ol,
.quill-display-block ul {
  padding-left: 1.5em;
}

.quill-display-block ol > li,
.quill-display-block ul > li {
  list-style-type: none;
}

.quill-display-block ul > li::before {
  content: "•";
}

.quill-display-block ul[data-checked=true],
.quill-display-block ul[data-checked=false] {
  pointer-events: none;
}

.quill-display-block ul[data-checked=true] > li *,
.quill-display-block ul[data-checked=false] > li * {
  pointer-events: all;
}

.quill-display-block ul[data-checked=true] > li::before,
.quill-display-block ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.quill-display-block ul[data-checked=true] > li::before {
  content: "☑";
}

.quill-display-block ul[data-checked=false] > li::before {
  content: "☐";
}

.quill-display-block li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}

.quill-display-block li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}

.quill-display-block li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}

.quill-display-block ol li:not(.ql-direction-rtl),
.quill-display-block ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.quill-display-block ol li.ql-direction-rtl,
.quill-display-block ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.quill-display-block ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.quill-display-block ol li:before {
  content: counter(list-0, decimal) ". ";
}

.quill-display-block ol li.ql-indent-1 {
  counter-increment: list-1;
}

.quill-display-block ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.quill-display-block ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.quill-display-block ol li.ql-indent-2 {
  counter-increment: list-2;
}

.quill-display-block ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.quill-display-block ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.quill-display-block ol li.ql-indent-3 {
  counter-increment: list-3;
}

.quill-display-block ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.quill-display-block ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.quill-display-block ol li.ql-indent-4 {
  counter-increment: list-4;
}

.quill-display-block ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.quill-display-block ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.quill-display-block ol li.ql-indent-5 {
  counter-increment: list-5;
}

.quill-display-block ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.quill-display-block ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.quill-display-block ol li.ql-indent-6 {
  counter-increment: list-6;
}

.quill-display-block ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.quill-display-block ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.quill-display-block ol li.ql-indent-7 {
  counter-increment: list-7;
}

.quill-display-block ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.quill-display-block ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.quill-display-block ol li.ql-indent-8 {
  counter-increment: list-8;
}

.quill-display-block ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.quill-display-block ol li.ql-indent-8 {
  counter-reset: list-9;
}

.quill-display-block ol li.ql-indent-9 {
  counter-increment: list-9;
}

.quill-display-block ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.quill-display-block .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.quill-display-block li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.quill-display-block .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.quill-display-block li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.quill-display-block .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.quill-display-block li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.quill-display-block .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.quill-display-block li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.quill-display-block .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.quill-display-block li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.quill-display-block .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.quill-display-block li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.quill-display-block .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.quill-display-block li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.quill-display-block .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.quill-display-block li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.quill-display-block .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.quill-display-block li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.quill-display-block .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.quill-display-block li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.quill-display-block .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.quill-display-block li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.quill-display-block .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.quill-display-block li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.quill-display-block .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.quill-display-block li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.quill-display-block .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.quill-display-block li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.quill-display-block .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.quill-display-block li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.quill-display-block .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.quill-display-block li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.quill-display-block .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.quill-display-block li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.quill-display-block .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.quill-display-block li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.quill-display-block .ql-video {
  display: block;
  max-width: 100%;
}

.quill-display-block .ql-video.ql-align-center {
  margin: 0 auto;
}

.quill-display-block .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.quill-display-block .ql-bg-black {
  background-color: #000;
}

.quill-display-block .ql-bg-red {
  background-color: #e60000;
}

.quill-display-block .ql-bg-orange {
  background-color: #f90;
}

.quill-display-block .ql-bg-yellow {
  background-color: #ff0;
}

.quill-display-block .ql-bg-green {
  background-color: #008a00;
}

.quill-display-block .ql-bg-blue {
  background-color: #06c;
}

.quill-display-block .ql-bg-purple {
  background-color: #93f;
}

.quill-display-block .ql-color-white {
  color: #fff;
}

.quill-display-block .ql-color-red {
  color: #e60000;
}

.quill-display-block .ql-color-orange {
  color: #f90;
}

.quill-display-block .ql-color-yellow {
  color: #ff0;
}

.quill-display-block .ql-color-green {
  color: #008a00;
}

.quill-display-block .ql-color-blue {
  color: #06c;
}

.quill-display-block .ql-color-purple {
  color: #93f;
}

.quill-display-block .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.quill-display-block .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.quill-display-block .ql-size-small {
  font-size: 0.75em;
}

.quill-display-block .ql-size-large {
  font-size: 1.5em;
  line-height: initial;
}

.quill-display-block .ql-size-huge {
  font-size: 2.5em;
  line-height: initial;
}

.quill-display-block .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.quill-display-block .ql-align-center {
  text-align: center;
}

.quill-display-block .ql-align-justify {
  text-align: justify;
}

.quill-display-block .ql-align-right {
  text-align: right;
}

.quill-display-block.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}

.resource-card {
  height: 100%;
  margin: 0;
}
.resource-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.resource-card .card-body .name {
  display: flex;
  align-items: center;
}
.resource-card .card-body .name svg {
  margin-right: 5px;
}
.resource-card .card-body .academic {
  display: flex;
  align-items: center;
}
.resource-card .card-body .academic svg {
  margin-right: 5px;
}
.resource-card .card-body .label {
  align-self: flex-start;
  margin-bottom: 5px;
}
.resource-card .card-body .body {
  flex-grow: 1;
  margin-bottom: 10px;
}
.resource-card .card-body .stats {
  margin-bottom: 2px;
}
.resource-card .card-body .stats .label {
  margin-right: 5px;
}
.resource-card .card-body .stats .attachments {
  background-color: #27a9e1;
}
.resource-card .card-body .stats .links {
  background-color: #1b75bb;
}
.resource-card .card-body .stats .videos {
  background-color: #bb1b75;
}

.row.application .user-avatar {
  padding: 0.5rem;
}
.row.application .comment-text {
  padding-top: 0.5rem;
}
.row.application .comment-text .details-box {
  margin-bottom: 15px;
}
.row.application .comment-text .details-box span {
  display: block;
}
.row.application .comment-text .details-box .user-name {
  color: #007C9A;
  font-size: 100%;
  font-weight: 300;
}

.search-field h3 {
  overflow: hidden;
  color: #007C9A;
}
.search-field form {
  display: flex;
}

.student-card {
  height: 100%;
}
.student-card > img {
  height: 200px;
}
.student-card .pcard-body {
  display: flex;
  flex-direction: column;
}
.student-card .pcard-body .h3 {
  text-align: center;
}
.student-card .pcard-body .institution > img {
  height: 32px;
  margin-right: 5px;
}
.student-card .pcard-body .body {
  margin: 10px 0;
  flex-grow: 1;
}
.student-card .pcard-body .registration-date {
  font-style: italic;
}
.student-card .pcard-body .tag {
  align-self: flex-start;
}

.student-project-card {
  height: 100%;
}
.student-project-card .pcard-body {
  display: flex;
  flex-direction: column;
}
.student-project-card .pcard-body .body {
  margin-bottom: 10px;
  flex-grow: 1;
}
.student-project-card .pcard-body .dates {
  font-style: italic;
  margin-bottom: 5px;
}
.student-project-card .pcard-body .tag {
  align-self: flex-start;
}

.student-project-row {
  padding: 20px 30px;
  width: 100%;
  overflow: hidden;
  border-left: 3px solid #ffffff;
  display: flex;
}
.student-project-row:hover .row-footer .action-icons, .student-project-row.active .row-footer .action-icons {
  margin-left: 5px;
  visibility: visible;
}
.student-project-row .content {
  flex: 1;
}
.student-project-row .content .dates {
  font-style: italic;
}
.student-project-row .content .tags {
  margin: 5px 0;
}
.student-project-row .content .tags .tag {
  margin-right: 2px;
}
.student-project-row .content .row-footer svg {
  vertical-align: text-top;
}
.student-project-row .content .row-footer .action-icons {
  visibility: hidden;
}
.student-project-row > img {
  max-width: 200px;
  margin: 5px 0 5px 30px;
  object-fit: contain;
}

.quill.terms-dialog .ql-container {
  height: 100%;
  border: none;
}
.quill.terms-dialog .ql-container .ql-editor {
  padding: 0;
}
.quill.terms-dialog .ql-container .ql-editor li:not([class^=ql-indent]) {
  font-weight: 400;
  line-height: 30px;
  font-size: 21px;
  padding-top: 1rem;
}
.quill.terms-dialog .ql-container .ql-editor p[class^=ql-indent] {
  padding-bottom: 1rem;
}
.quill.terms-dialog .ql-container .ql-editor li > strong:after {
  content: "\a";
  white-space: pre;
}

.tool-card {
  height: 100%;
}
.tool-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tool-card .card-body .header {
  display: flex;
  align-items: center;
}
.tool-card .card-body .academic {
  display: flex;
  align-items: center;
}
.tool-card .card-body .academic svg {
  margin-right: 5px;
}
.tool-card .card-body .label {
  align-self: flex-start;
  margin-bottom: 5px;
}
.tool-card .card-body .description {
  flex-grow: 1;
}
.tool-card .card-body .tags {
  margin-top: 10px;
}

.tool-row {
  margin-top: 10px;
}
.tool-row:nth-child(2) {
  margin-top: 0;
}
.tool-row .header {
  display: flex;
  align-items: center;
}
.tool-row .header .h3 {
  margin-bottom: 0;
}
.tool-row .label {
  margin-bottom: 5px;
}
.tool-row .academic {
  display: flex;
  align-items: center;
}
.tool-row .academic svg {
  margin-right: 5px;
}

.video-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.video-gallery .thumbnail {
  margin: 5px;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px;
  position: relative;
  cursor: pointer;
}
.video-gallery .thumbnail > img {
  height: 100%;
}

.video-selector .video-list {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-flow: row wrap;
}
.video-selector .video-list .video-item, .video-selector .video-list .upload-video {
  margin-left: 7px;
  margin-right: 7px;
  opacity: 0.3;
  margin-bottom: 7px;
  align-self: flex-start;
  width: 367px;
  height: 238px;
  cursor: pointer;
  border: 3px solid transparent;
}
.video-selector .video-list .video-item:hover, .video-selector .video-list .upload-video:hover {
  border: 3px solid green;
  opacity: 1;
}
.video-selector .video-list .video-item.selected, .video-selector .video-list .selected.upload-video {
  border: 3px solid green;
  opacity: 1;
}
.video-selector .video-list .upload-video {
  text-align: center;
  padding-top: 60px;
}
.video-selector .video-list .upload-video .upload-text {
  font-size: 20px;
}
.video-selector .video-list .upload-video .upload-text .upload-icon {
  top: 5px;
  position: relative;
}
.video-selector .video-list .upload-video .upload-help-text {
  font-size: 14px;
}

.view-page-container .view-page-title-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}
.view-page-container .view-page-title-container .view-page-title {
  flex-grow: 1;
}
.view-page-container .view-page-title-container .view-page-title h2 {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wizard .wizard-steps {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
}
.wizard .wizard-steps .divider {
  width: 100%;
  border-bottom: 2px solid #aaa;
  min-width: 10px;
}
.wizard .wizard-steps .step {
  display: table-cell;
  width: auto;
  vertical-align: top;
  text-align: center;
  position: relative;
}
.wizard .wizard-steps .step.disabled a, .wizard .wizard-steps .step.disabled a:focus, .wizard .wizard-steps .step.disabled a:hover {
  color: #999;
  cursor: default;
}
.wizard .wizard-steps .step a {
  position: relative;
  padding-top: 52px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  color: #999;
}
.wizard .wizard-steps .step span {
  background-color: #fff;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -24px;
  z-index: 10;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 3px solid #F3F3F3;
  font-size: 1.3rem;
  border-radius: 50%;
}
.wizard .wizard-steps .step:after, .wizard .wizard-steps .step:before {
  content: "";
  z-index: 9;
  display: block;
  position: absolute;
  top: 45px;
  width: 50%;
  height: 3px;
  background-color: #F3F3F3;
}
.wizard .wizard-steps .step:after {
  right: 0;
}
.wizard .wizard-steps .step:first-child:before {
  content: "";
}
.wizard .wizard-steps .step:first-child:before, .wizard .wizard-steps .step:last-child:after {
  content: none;
}
.wizard .wizard-steps .step.complete:before, .wizard .wizard-steps .step.complete:after {
  background-color: #009efb;
}
.wizard .wizard-steps .step.complete span {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}
.wizard .wizard-steps .step.current:before, .wizard .wizard-steps .step.current:after {
  background-color: #009efb;
}
.wizard .wizard-steps .step.current span {
  background-color: #fff;
  border-color: #009efb;
  color: #009efb;
}
.wizard .wizard-steps .step.is-last-step-done:before {
  background-color: #009efb;
}
.wizard .wizard-steps .step.is-last-step-done:after {
  background-color: #F3F3F3;
}
.wizard .wizard-steps .step:disabled:before, .wizard .wizard-steps .step:disabled:after {
  background-color: #F3F3F3;
}
.wizard .wizard-steps .step.error span {
  background-color: #fff;
  border-color: #f62d51;
  color: #f62d51;
}
.wizard .wizard-buttons {
  margin-top: 10px;
  float: right;
}

.youtube-embed {
  max-width: 800px;
  margin: auto;
  padding: 10px;
}
.youtube-embed .frame-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.youtube-embed .frame-wrapper a {
  display: block;
}
.youtube-embed .frame-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.academic-container .contact-info {
  width: 288px;
}
.academic-container .contact-info .card-body {
  padding: 0;
}
.academic-container .contact-info .card-body img.logo {
  display: block;
  margin: auto;
  height: 32px;
  width: 32px;
}

@media (max-width: 1009px) {
  .academic-container {
    flex-direction: column;
    align-items: center;
  }
}
.anonymous-layout .anonymous-container {
  display: flex;
  max-width: 1000px;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.anonymous-layout .anonymous-container .anonymous-box, .anonymous-layout .anonymous-container .registration-box, .anonymous-layout .anonymous-container .reset-box {
  padding: 1.25rem;
  max-width: 450px;
}
.anonymous-layout .anonymous-container .anonymous-box h1, .anonymous-layout .anonymous-container .registration-box h1, .anonymous-layout .anonymous-container .reset-box h1 {
  margin: 40px 0 0;
}
.anonymous-layout .anonymous-container .anonymous-box .button.grey, .anonymous-layout .anonymous-container .registration-box .button.grey, .anonymous-layout .anonymous-container .reset-box .button.grey {
  background-color: #686868;
}
.anonymous-layout .anonymous-container .anonymous-box .button.grey:hover, .anonymous-layout .anonymous-container .registration-box .button.grey:hover, .anonymous-layout .anonymous-container .reset-box .button.grey:hover {
  filter: brightness(110%);
}
.anonymous-layout .anonymous-container .help-box {
  background-color: #f2f2f2;
  text-align: center;
  padding: 1.25rem;
  margin-left: 80px;
}
.anonymous-layout .anonymous-container .help-box p {
  text-align: left;
}
.anonymous-layout .anonymous-container .help-box a {
  color: #0063cc;
}
@media (max-width: 1000px) {
  .anonymous-layout .anonymous-container {
    display: block;
  }
  .anonymous-layout .anonymous-container .anonymous-box {
    margin: 0 auto;
  }
  .anonymous-layout .anonymous-container .help-box {
    margin: 0;
  }
}
.anonymous__main {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  position: fixed;
}

.anonymous__main .anonymous__content {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border: 1px solid #333;
  margin-top: 100px;
  margin-bottom: auto;
  vertical-align: middle;
  border-radius: 10px;
  background: #fff;
  padding: 7.25rem 1.25rem 1.25rem 1.25rem;
  overflow-y: auto;
  width: 400px;
}

.anonymous__main .anonymous__logo {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
}

.anonymous__main .anonymous__background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .anonymous__main {
    padding: 10% 0;
    position: relative;
    overflow: hidden;
  }
  .anonymous__main .anonymous__content {
    width: 90%;
    position: relative;
  }
  .anonymous__main .anonymous__background {
    width: 100%;
  }
}
.article .card-body > img {
  max-width: 100%;
  display: block;
  margin: 20px auto;
}
.article .card-body .h4 {
  margin-top: 20px;
}

.career-progression .roleLabel {
  fill: white;
  font-size: 20px;
}
.career-progression .arc {
  cursor: pointer;
}
.career-progression .arcText {
  fill: white;
  cursor: pointer;
}
.career-progression .progression {
  margin: 0;
  padding: 5px;
}
.career-progression .progression .progression-role {
  background: #84c446;
  border: 1px solid #aaa;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  clear: both;
  margin-bottom: 10px;
  position: relative;
}
.career-progression .progression .progression-role .progression-role-name {
  display: inline-block;
  width: 100%;
  font-weight: 500;
}
.career-progression .progression .progression-role .progression-role-score {
  position: absolute;
  right: 10px;
  top: 10px;
}
.career-progression .stats {
  display: flex;
  text-align: center;
  justify-content: center;
}
.career-progression .stats .stat {
  width: 210px;
  max-width: 210px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.career-progression .stats .stat .stat-label {
  font-size: 16px;
  line-height: 24px;
  color: rgb(28, 28, 28);
  margin-bottom: 10px;
}
.career-progression .stats .stat .stat-wrapper {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.career-progression .stats .stat .stat-wrapper .stat-values {
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
  color: rgb(13, 56, 128);
  margin-bottom: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.career-progression .stats .stat .stat-wrapper .stat-values .stat-trend {
  margin-right: 10px;
  border-bottom: 14px solid rgb(63, 168, 51);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.career-progression .stats .stat .stat-wrapper .stat-desc {
  width: 150px;
  font-size: 13px;
  line-height: 16px;
  color: rgb(28, 28, 28);
}

.competencies .competency-progress {
  background-color: #B7F779;
  height: 15px;
}
.competencies .competency-progress.warning {
  background-color: #FFE081;
}
.competencies .competency-progress.error {
  background-color: #FFB2AD;
}
.competencies .marker {
  position: relative;
  top: -15px;
}
.competencies .marker > div {
  position: absolute;
  color: white;
  width: 115px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  transition: left 0.4s linear;
  padding-right: 5px;
}
.competencies .data-table a {
  cursor: pointer;
}

@media print {
  .competencies .competencies-options {
    display: none !important;
  }
  .competencies .competency-filter {
    display: none !important;
  }
  .competencies .scrollbar-container {
    overflow: visible !important;
    max-height: none !important;
  }
  .competencies .scrollbar-container .scrollbar-view {
    overflow: visible !important;
    max-height: none !important;
    margin-bottom: 5px !important;
    margin-right: 0px !important;
  }
}
.ld-resource-card {
  height: 100%;
  text-align: center;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px;
  position: relative;
}

.ld-resource-card__remove {
  position: absolute !important;
  top: 0;
  right: 0;
}

.discussion .discussion-row {
  display: flex;
  padding: 10px 0;
}
.discussion .discussion-row > .avatar {
  margin-right: 10px;
}
.discussion .discussion-row .h3 {
  margin-bottom: 0;
}
.discussion .discussion-row .label {
  margin-bottom: 10px;
  margin-right: 5px;
}
.discussion .discussion-row .stats > svg {
  color: #555;
  vertical-align: middle;
}

.event .event-details-overlay {
  text-align: center;
  background: rgba(7, 10, 43, 0.8);
}
.event .event-details-overlay > div {
  margin-left: auto;
  margin-right: auto;
}

.ld-course-card {
  height: 100%;
  margin: 0;
}
.ld-course-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ld-course-card .card-body .name {
  display: flex;
  align-items: center;
  color: #007C9A;
}
.ld-course-card .card-body .name svg {
  margin-right: 5px;
}
.ld-course-card .card-body .body {
  flex-grow: 1;
  margin-bottom: 10px;
}
.ld-course-card .card-body .stats {
  margin-bottom: 2px;
}
.ld-course-card .card-body .stats .label {
  margin-right: 5px;
}
.ld-course-card .card-body .stats .attachments {
  background-color: #27a9e1;
}
.ld-course-card .card-body .stats .links {
  background-color: #1b75bb;
}
.ld-course-card .card-body .stats .videos {
  background-color: #bb1b75;
}

.ld-video-resource-card {
  min-height: 202px !important;
}
.ld-video-resource-card .card-body {
  position: relative;
}
.ld-video-resource-card .card-body .frame-wrapper {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
}
.ld-video-resource-card .card-body .frame-wrapper iframe {
  width: 100%;
}
.ld-video-resource-card .card-body .body {
  display: none;
}

@media print {
  .ld-video-resource-card .card-body .frame-wrapper {
    display: none;
  }
  .ld-video-resource-card .card-body .body {
    display: block;
  }
}
.login-layout .login-container {
  display: flex;
  max-width: 1000px;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.login-layout .login-container .login-box, .login-layout .login-container .registration-box, .login-layout .login-container .reset-box {
  padding: 1.25rem;
  max-width: 450px;
}
.login-layout .login-container .login-box h1, .login-layout .login-container .registration-box h1, .login-layout .login-container .reset-box h1 {
  margin: 40px 0 0;
}
.login-layout .login-container .login-box .button.grey, .login-layout .login-container .registration-box .button.grey, .login-layout .login-container .reset-box .button.grey {
  background-color: #686868;
}
.login-layout .login-container .login-box .button.grey:hover, .login-layout .login-container .registration-box .button.grey:hover, .login-layout .login-container .reset-box .button.grey:hover {
  filter: brightness(110%);
}
.login-layout .login-container .help-box {
  background-color: #f2f2f2;
  text-align: center;
  padding: 1.25rem;
  margin-left: 80px;
}
.login-layout .login-container .help-box p {
  text-align: left;
}
.login-layout .login-container .help-box a {
  color: #0063cc;
}
@media (max-width: 1000px) {
  .login-layout .login-container {
    display: block;
  }
  .login-layout .login-container .login-box {
    margin: 0 auto;
  }
  .login-layout .login-container .help-box {
    margin: 0;
  }
}
.signin__main {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  position: fixed;
}

.signin__main .signin__content {
  background: #fff;
  padding: 7.25rem 1.25rem 1.25rem 1.25rem;
  overflow-y: auto;
  right: 0px;
  position: absolute;
  height: 100%;
  width: 400px;
  margin: auto;
}

.signin__main .signin__logo {
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0px;
}

.signin__main .signin__background {
  width: calc(100% - 400px);
  height: 100%;
  position: fixed;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .signin__main {
    padding: 10% 0;
    position: relative;
    overflow: hidden;
  }
  .signin__main .signin__content {
    width: 90%;
    position: relative;
  }
  .signin__main .signin__background {
    width: 100%;
  }
}
.my-community .channel-card {
  margin-bottom: 0px;
}
.my-community .channel-card .card-body button.unsubscribe {
  position: absolute;
  top: 15px;
  right: 10px;
}
.my-community .channel-card .card-body .organisation > img {
  margin-right: 5px;
  height: 20px;
  width: 20px;
}
.my-community .channel-card .card-body .description {
  margin: 5px 0;
}
.my-community .feed .card {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}
.my-community .feed .card .card-title .title a {
  color: inherit;
}
.my-community .feed .card .card-body {
  display: flex;
  flex-direction: column;
}
.my-community .feed .h4 {
  margin: 0;
}
.my-community .feed .description {
  margin: 5px 0 10px 0;
}
.my-community .feed .add-button {
  align-self: flex-start;
}
.my-community .feed .label {
  margin-right: 5px;
}
.my-community .feed .event-row {
  display: flex;
  margin-top: 20px;
}
.my-community .feed .event-row:nth-child(2) {
  margin-top: 0;
}
.my-community .feed .event-row .date {
  margin-right: 20px;
  text-align: center;
}
.my-community .feed .event-row .date > svg {
  height: 60px;
  width: 60px;
}
.my-community .feed .event-row .body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.my-community .feed .event-row .body .time > svg {
  vertical-align: middle;
}
.my-community .feed .resource-row {
  margin-top: 20px;
}
.my-community .feed .resource-row:nth-child(2) {
  margin-top: 0;
}
.my-community .feed .resource-row .name {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.my-community .feed .resource-row .name svg {
  margin-right: 5px;
}
.my-community .feed .resource-row .academic {
  display: flex;
  align-items: center;
}
.my-community .feed .resource-row .academic svg {
  margin-right: 5px;
}
.my-community .feed .discussion-row {
  display: flex;
  margin-top: 20px;
}
.my-community .feed .discussion-row:nth-child(2) {
  margin-top: 0;
}
.my-community .feed .discussion-row > .avatar {
  margin-right: 10px;
}
.my-community .feed .discussion-row .stats > svg {
  vertical-align: middle;
}
.my-community .feed .problem-row {
  display: flex;
  margin-top: 20px;
}
.my-community .feed .problem-row:nth-child(2) {
  margin-top: 0;
}
.my-community .feed .problem-row .votes {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my-community .feed .problem-row .votes .count {
  font-size: 1.5em;
  margin-top: 0.5rem;
}
.my-community .feed .problem-row .body {
  flex-grow: 1;
  margin-left: 10px;
}
.my-community .feed .problem-row .body .views {
  float: right;
}
.my-community .feed .insight-row {
  margin-top: 20px;
}
.my-community .feed .insight-row:nth-child(2) {
  margin-top: 0;
}
.my-community .feed .insight-row .expert {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.my-community .feed .insight-row .expert > .dih-avatar {
  margin-right: 5px;
}
.my-community .feed .insight-row .expert .h5 {
  margin: 0;
}
.my-community .feed .insight-row .dates {
  font-style: italic;
}

.subscribe-dialog .channel-card {
  height: 100%;
}
.subscribe-dialog .channel-card .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.subscribe-dialog .channel-card .card-body .description {
  flex-grow: 1;
}
.subscribe-dialog .channel-card .card-body .button {
  width: 100%;
}

@media (min-width: 768px) {
  .my-community .feed {
    column-count: 2;
  }
}
.offer-box {
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  min-width: 280px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  transition: all 0.5s ease-in-out;
  height: 100%;
}
.offer-box .pcard-img-top {
  height: 100%;
}

.passport .card {
  margin: 0;
  height: "100%";
}
.passport .button-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.passport .badges {
  margin-top: 30px;
}
.passport .passport-field-text {
  display: block;
}
.passport .passport-field-text span:empty:before {
  content: "​";
}
.passport .skill-button-prompt-text {
  text-align: center;
  font-weight: bold;
  color: rgb(208, 89, 87);
}

.passport-stat {
  height: 100px;
  width: 180px;
  display: inline-block;
  margin-top: 20px;
  margin-right: 10px;
}
.passport-stat .left-side {
  width: 60px;
  height: 100%;
  float: left;
  line-height: 100px;
  text-align: center;
}
.passport-stat .left-side .stat-icon {
  font-size: 35px;
  vertical-align: middle;
}
.passport-stat .right-side {
  width: 120px;
  height: 100%;
  float: right;
  position: relative;
}
.passport-stat .right-side .stat-count {
  font-size: 40px;
  position: absolute;
  right: 10px;
  top: 0px;
}
.passport-stat .right-side .stat-label {
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 50px;
  margin-left: 5px;
  display: table;
  text-align: right;
  width: 105px;
  height: 48px;
}
.passport-stat .right-side .stat-label span {
  vertical-align: middle;
  display: table-cell;
}

.products-page .product {
  margin: 20px 0;
  z-index: 10;
  min-height: 400px;
  text-align: center;
}
.products-page .product__name {
  font-weight: bold;
  font-size: 30px;
}
.products-page .product__price {
  font-weight: bold;
  font-size: 60px;
}
.products-page .product__price__dollar-sign {
  font-size: 40px;
}
.products-page .product__duration {
  color: #aaa;
  font-size: 18px;
}
.products-page .product__description {
  color: #aaa;
  margin: 0;
  text-align: left;
  line-height: 20px;
}

.card.problem-stats .toggle-panel {
  padding: 20px 10px 0;
  display: flex;
}
.card.problem-stats .toggle-panel button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  align-self: center;
}
.card.problem-stats .toggle-panel button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  align-self: center;
}
.card.problem-stats .toggle-panel .checkbox-control {
  width: initial;
  margin-left: 20px;
}

.profile-container .card {
  margin-bottom: 0px;
}
.profile-container .contact-info {
  padding: 0;
  width: 288px;
}
.profile-container .contact-info > .card-body {
  padding: 0;
}
.profile-container .contact-info > .card-body .content > div {
  padding: 1.25rem;
}
.profile-container .contact-info > .card-body .titles {
  padding: 10px 0;
}
.profile-container .contact-info > .card-body .titles span {
  display: block;
}
.profile-container .contact-info > .card-body .static-stat {
  cursor: default;
}
.profile-container .contact-info > .card-body .static-stat:hover {
  background-color: initial;
}
.profile-container .contact-info > .card-body p.email {
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-container .contact-info > .card-body iframe {
  border: 0;
  margin-top: 10px;
}

@media (max-width: 1009px) {
  .profile-container {
    flex-direction: column;
    align-items: center;
  }
}
.subscription-page__header {
  text-align: center;
}
.subscription-page .subscription-level {
  margin: 20px 0;
  z-index: 10;
  min-height: 400px;
  text-align: center;
  color: #fff;
  background: #70AD47;
  height: calc(100% - 24px);
}
.subscription-page .subscription-level .card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}
.subscription-page .subscription-level__name {
  font-weight: bold;
  font-size: 25px;
}
.subscription-page .subscription-level__price {
  font-weight: bold;
  font-size: 24px;
}
.subscription-page .subscription-level__price__dollar-sign {
  font-size: 24px;
}
.subscription-page .subscription-level__duration {
  font-size: 18px;
}
.subscription-page .subscription-level__description {
  margin: 0;
  text-align: left;
  line-height: 20px;
  flex-grow: 1;
}
.subscription-page .subscription-level__description p {
  margin: 0;
}
.subscription-page .subscription-level__select-button {
  text-align: center;
}
.subscription-page__thank-you-text {
  text-align: center;
}
.subscription-page__disclaimer {
  text-align: center;
  color: #aaa;
}

.collaboration-details .card-body .dates {
  font-style: italic;
}
.collaboration-details .card-body > img {
  max-width: 100%;
  display: block;
  margin: 20px auto;
}

#user-competencies svg {
  margin: 0px auto;
  display: block;
}
#user-competencies path.arc {
  opacity: 0.9;
  transition: opacity 0.5s;
}
#user-competencies path.arc:hover {
  opacity: 0.7;
}
#user-competencies .axis line, #user-competencies .axis circle {
  stroke: #cccccc;
  stroke-width: 1px;
}
#user-competencies .axis circle {
  fill: none;
}
#user-competencies .r.axis text {
  text-anchor: end;
}
#user-competencies .tooltip {
  position: absolute;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  box-shadow: -3px 3px 15px #888;
  color: white;
  padding: 6px;
}

.view-content .tags {
  margin-bottom: 5px;
}
.view-content .content-link {
  border: 1px solid #5e7a87;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  padding: 5px;
  margin: 0;
}
.view-content .content-link svg {
  margin-right: 5px;
}
.view-content .content-attachment {
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.view-content .content-attachment svg {
  color: #5e7a87;
  width: 50px;
  height: 50px;
}

.course-details .header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.course-details .header > div {
  flex: 1;
}
.course-details .header > div h2 {
  margin: 0;
}
.course-details .course-link > a {
  display: flex;
  align-items: center;
}
.course-details .course-link > a svg {
  margin-right: 5px;
}

.view-discussion {
  margin-bottom: 20px;
}
.view-discussion .header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.view-discussion .header .dih-avatar {
  margin-right: 10px;
}
.view-discussion .header .subject {
  flex-grow: 1;
}
.view-discussion .header .subject h2 {
  margin-bottom: 0;
}
.view-discussion .channels {
  margin: 10px 0 5px;
}
.view-discussion .dates {
  font-style: italic;
}
.view-discussion .chat {
  margin-top: 20px;
}

.insight-details .card-body .expert {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.insight-details .card-body .expert .dih-avatar {
  margin-right: 5px;
}
.insight-details .card-body .expert .h5 {
  margin: 0;
}
.insight-details .card-body .dates {
  font-style: italic;
  margin-bottom: 10px;
}
.insight-details .card-body > img {
  max-width: 100%;
  max-height: 500px;
  display: block;
  margin: 20px auto;
}
.insight-details .card-body .channels-tags .tag {
  margin-right: 5px;
}

.ip-container .ip-details {
  margin-bottom: 30px;
}
.ip-container .ip-details .contact-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ip-container .ip-details .contact-details .details-box small {
  display: block;
}
.ip-container .ip-details .contact-details .details-box .contact-person {
  color: #007C9A;
  font-size: 100%;
  font-weight: 300;
}
.ip-container .ip-details .contact-details .details-box .contact-person svg {
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
}
.ip-container .ip-details svg {
  vertical-align: middle;
}
.ip-container .ip-details .paper {
  padding: 20px;
}
.ip-container .ip-details .paper span {
  display: block;
}
.ip-container .ip-details .paper svg {
  margin-right: 5px;
}

.offer-container .offer-details .user-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.offer-container .offer-details .user-details .owner-avatar {
  margin: 0 1rem;
}
.offer-container .offer-details .user-details .details-box small {
  display: block;
}
.offer-container .offer-details .user-details .details-box .owner-name {
  color: #007C9A;
  font-size: 100%;
  font-weight: 300;
}
.offer-container .offer-details .user-details .details-box .owner-name svg {
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
}
.offer-container .offer-details .user-details .registration-box {
  margin-left: auto;
  text-align: right;
  white-space: pre;
}

.problem-container .user-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.problem-container .user-details .author-avatar {
  padding-right: 1rem;
}
.problem-container .user-details .details-box span {
  display: block;
  color: #555;
  font-size: 80%;
  font-weight: 400;
}
.problem-container .user-details .details-box .author-name {
  color: #007C9A;
  font-size: 100%;
  font-weight: 300;
}
.problem-container .user-details .details-box .author-name svg {
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
}
.problem-container .user-details .votes {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.problem-container .user-details .votes .vote-count {
  margin-left: 10px;
  float: right;
}
.problem-container .user-details .votes .vote-count h3, .problem-container .user-details .votes .vote-count h5 {
  margin: 0;
}
.problem-container .user-details .votes .vote-count h5 {
  color: #555;
}

.resource-details .academic {
  display: flex;
  align-items: center;
}
.resource-details .academic svg {
  margin-right: 5px;
}

.student-project-details .card-body .dates {
  font-style: italic;
}
.student-project-details .card-body > img {
  max-width: 100%;
  display: block;
  margin: 20px auto;
}

.student-details .contact-info {
  display: flex;
  margin-bottom: 10px;
}
.student-details .contact-info > .dih-avatar {
  margin-right: 5px;
}
.student-details .contact-info .h3 {
  margin: 0;
}

.tool-details .card-body h2 {
  margin: 0;
  display: flex;
  align-items: center;
}
.tool-details .card-body .academic {
  display: flex;
  align-items: center;
}
.tool-details .card-body .academic svg {
  margin-right: 5px;
}

html.report-layout {
  background-color: #fff;
}

.report-container {
  -webkit-print-color-adjust: exact;
  position: relative;
}
.report-container .report-table.centered {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.report-container .report-table th, .report-container .report-table td {
  height: 25px;
}
.report-container .report-print-button {
  position: absolute;
  right: 10px;
}

