
html {
	&.report-layout {
		background-color: #fff;
	}
}


.report-container {
	-webkit-print-color-adjust: exact;
	position: relative;

	.report-table {
		@extend .table;
		@extend .table-bordered;
		@extend .table-sm;

		&.centered {
			width: auto;
			margin-left: auto;
			margin-right: auto;
		}

		th, td {
			height: 25px;
		}
	}

	.report-print-button {
		position: absolute;
		right: 10px;
	}
}
