.view-discussion {
	margin-bottom: 20px;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		.dih-avatar {
			margin-right: 10px;
		}

		.subject {
			flex-grow: 1;

			h2 {
				margin-bottom: 0;
			}
		}
	}

	.channels {
		margin: 10px 0 5px;
	}

	.dates {
		font-style: italic;
	}

	.chat {
		margin-top: 20px;
	}
}